import { useState, useRef } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
// import Logo from "../assets/images/logo_circle.png";
import { Form as FormData, Formik } from "formik";
import { SignUpTextSelect, TextField, TextSelect } from "../utils/Fromcontrol";
import * as Yup from "yup";
import { RegistrationType } from "../types/RegistrationType";
import Logo_Mobile from "../assets/images/Nydra_Well_logo_FINAL_2 26.png";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import Nydra_well from "../assets/images/nidrawell-Logo.png";
import { toast, ToastContainer } from "react-toastify";

const Registration = () => {
  const Navigate = useNavigate();
  const toastId = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isStatus, setIsStatus] = useState();

  const Genderdropdown = [
    { key: "", value: "" },
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Others", value: "others" },
    { key: "Prefer not to say", value: "prefernottosay" },
  ];

  const availableTimezones = [
    {
      id: 88,
      name: "America/Chicago",
      offset: "-05:00",
      diff_from_gtm: "UTC/GMT -05:00",
    },
    {
      id: 97,
      name: "America/Denver",
      offset: "-06:00",
      diff_from_gtm: "UTC/GMT -06:00",
    },
    {
      id: 133,
      name: "America/Los_Angeles",
      offset: "-07:00",
      diff_from_gtm: "UTC/GMT -07:00",
    },
    {
      id: 152,
      name: "America/New_York",
      offset: "-04:00",
      diff_from_gtm: "UTC/GMT -04:00",
    },
    {
      id: 251,
      name: "Asia/Kolkata",
      offset: "+05:30",
      diff_from_gtm: "UTC/GMT +05:30",
    },
    {
      id: 425,
      name: "UTC",
      offset: "+00:00",
      diff_from_gtm: "UTC/GMT +00:00",
    },
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    addressLn1: "",
    addressLn2: "",
    city: "",
    zipcode: "",
    state: "",
    mobile: "",
    timezone: "",
    isSubscribeSms: false,
    isBillingAddressSame: true,
    billingAddressLn1: "",
    billingAddressLn2: "",
    billingZipcode: "",
    billingCity: "",
    billingState: "",
    gender: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
    addressLn1: Yup.string().required("Address Line #1 is required"),
    addressLn2: Yup.string(),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    state: Yup.string().required("State is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Minimum of 10 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .required("Mobile Number is required"),
    timezone: Yup.string().required("Timezone is required"),
    isSubscribeSms: Yup.bool(),
    isBillingAddressSame: Yup.bool().required(
      "Billing address is same as mail address? is required"
    ),
    billingAddressLn1: Yup.string()
      .when("isBillingAddressSame", {
        is: (val: boolean) => val === false,
        then: Yup.string()
          .required("Billing Address Line #1 is required")
          .nullable(),
      })
      .nullable(),
    billingAddressLn2: Yup.string().nullable(),
    billingCity: Yup.string()
      .when("isBillingAddressSame", {
        is: (val: boolean) => val === false,
        then: Yup.string().required("City is required").nullable(),
      })
      .nullable(),
    billingZipcode: Yup.string()
      .when("isBillingAddressSame", {
        is: (val: boolean) => val === false,
        then: Yup.string()
          .min(5, "Minimum of 5 characters of length")
          .max(5, "Maximum of 5 characters of length")
          .required("ZipCode is required")
          .nullable(),
      })
      .nullable(),
    billingState: Yup.string()
      .when("isBillingAddressSame", {
        is: (val: boolean) => val === false,
        then: Yup.string().required("State is required").nullable(),
      })
      .nullable(),
    gender: Yup.string().required("Gender is required"),
  });

  const onSubmit = (values: RegistrationType) => {
    setIsLoading(true);
    toastId.current = toast.loading("Loading...");
    const formattedMobileNo = `+1${values.mobile}`;
    axios
      .post("/sign_up", {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        password: values.password,
        addressline1: values.addressLn1,
        addressline2: values.addressLn2,
        zipcode: values.zipcode,
        city: values.city,
        state: values.city,
        mobileno: formattedMobileNo,
        timezone: values.timezone,
        isbilling_address: values.isBillingAddressSame,
        issubscribe_sms: values.isSubscribeSms,
        baddressline1: values.billingAddressLn1,
        baddressline2: values.billingAddressLn2,
        bcity: values.billingCity,
        bstate: values.billingState,
        bzipcode: values.billingZipcode,
        gender: values.gender,
      })
      .then((response) => {
        const { status, message } = response.data;
        setIsStatus(status);
        if (status) {
          toast.update(toastId.current, {
            render: `${message}`,
            type: "success",
            isLoading: false,
          });

          setTimeout(() => {
            toast.dismiss(toastId.current);
            Navigate("/login");
          }, 1000);
        } else {
          toast.update(toastId.current, {
            render: `${message}`,
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  const togglepasswordvisibility = () => {
    setShowPassword(!showPassword);
  };

  const SignUpComponent = ({ scroll }: any) => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps: any) => {
          if (formikProps.values.isBillingAddressSame) {
            formikProps.values.billingAddressLn1 = null;
            formikProps.values.billingAddressLn2 = null;
            formikProps.values.billingCity = null;
            formikProps.values.billingState = null;
            formikProps.values.billingZipcode = null;
          }
          return (
            <div className={`${scroll}`}>
              <FormData className={` px-md-5`}>
                <div className="row py-md-2">
                  <div className="col-md-6 col-12 my-3 my-md-0">
                    <TextField
                      type="text"
                      name="firstName"
                      label="First Name"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField type="text" name="lastName" label="Last Name" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 my-3 my-md-0">
                    <TextField
                      type="email"
                      name="email"
                      label="Email Address"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextSelect
                      label="Gender"
                      id="gender"
                      name="gender"
                      type="select"
                      options={Genderdropdown}
                    />
                  </div>
                </div>
                <div className="row py-md-2">
                  <div className="col-md-6 col-12 my-3 my-md-0">
                    <TextField
                      type="password"
                      name="password"
                      label="Password"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      label="Confirm Password"
                      showPassword={showPassword}
                      togglePasswordVisibility={togglepasswordvisibility}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12 my-3 my-md-0">
                    <TextField
                      type="text"
                      name="addressLn1"
                      label="Address ln #1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <TextField
                      type="text"
                      name="addressLn2"
                      label="Address ln #2"
                    />
                  </div>
                </div>
                <div className="row py-md-2">
                  <div className="col-md-6 col-12 my-3 my-md-0">
                    <TextField type="text" name="city" label="City" />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField type="text" name="zipcode" label="Zipcode" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 my-3 my-md-0">
                    <TextField type="text" name="state" label="State" />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField type="text" name="mobile" label="Mobile no" />
                  </div>
                </div>
                <div className="row py-md-2">
                  <div className="col-md-6 col-12 my-3 my-md-0">
                    <SignUpTextSelect
                      type="select"
                      label="Time Zone"
                      name="timezone"
                      options={availableTimezones}
                    />
                  </div>
                </div>
                <div className="row my-3 my-md-2">
                  <div className="col-12 bg-white">
                    <div className="form-check">
                      <input
                        className="form-check-input border-purple"
                        type="checkbox"
                        name="isSubscribeSms"
                        value={formikProps.values.isSubscribeSms}
                        onChange={(event: any) => {
                          formikProps.setFieldValue(
                            "isSubscribeSms",
                            !formikProps.values.isSubscribeSms
                          );
                        }}
                      />
                      <label className="form-check-label ms-md-2">
                        Yes! Sign me up to recieve SMS messages from Nydra well
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <Form.Group className="d-flex ms-md-4 my-md-3">
                    <Form.Label className="me-3 ms-2 ps-1">
                      Is the billing address the same?
                    </Form.Label>

                    <Form.Check
                      type="radio"
                      name="isBillingAddressSame"
                      id="billingAddressSameYes"
                      value="true"
                      label="Yes"
                      checked={formikProps.values.isBillingAddressSame === true}
                      onChange={() =>
                        formikProps.setFieldValue("isBillingAddressSame", true)
                      }
                    />

                    <Form.Check
                      type="radio"
                      className="ms-md-3"
                      name="isBillingAddressSame"
                      id="billingAddressSameNo"
                      value="false"
                      label="No"
                      checked={
                        formikProps.values.isBillingAddressSame === false
                      }
                      onChange={() =>
                        formikProps.setFieldValue("isBillingAddressSame", false)
                      }
                    />
                  </Form.Group>
                </div>

                {!formikProps.values.isBillingAddressSame && (
                  <div>
                    <h6 className="my-1 fw-bold mt-md-2">Billing Address :</h6>
                    <div className="row">
                      <div className="col">
                        <TextField
                          type="text"
                          name="billingAddressLn1"
                          label="Address ln #1"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <TextField
                          type="text"
                          name="billingAddressLn2"
                          label="Address ln #2"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <TextField
                          type="text"
                          name="billingCity"
                          label="City"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <TextField
                          type="text"
                          name="billingZipcode"
                          label="Zipcode"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <TextField
                          type="text"
                          name="billingState"
                          label="State"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row mt-3">
                  <div className="col d-flex justify-content-center">
                    <button
                      className="btn btn-purple py-2 px-4 rounded-1  w-50 fw-normal fw-bold "
                      type="submit"
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="purple"
                            size="sm"
                            className="me-3"
                          />
                          Submitting...
                        </>
                      ) : (
                        "Sign up"
                      )}
                    </button>
                  </div>
                </div>
                <div className="mt-md-3 d-flex justify-content-center">
                  <div
                    className=" py-2 rounded-0 fw-normal text-purple fw-bold text-decoration-none d-flex align-items-center"
                    onClick={() => Navigate("/login")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi-arrow-left-short fs-4"></i>Back to Login
                  </div>
                </div>
              </FormData>
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <ToastContainer />
      <Container
        fluid={true}
        className="d-none d-md-block position-relative vh-100 px-3 py-4 px-xl-5"
      >
        <div className="border-0 position-absolute signup_card">
          <Row className="rounded">
            <Col md={6} className="d-none d-md-block bg_dark position-relative">
              <div className="position-absolute image_signup">
                <img src={Nydra_well} alt="company_logo" />
              </div>
            </Col>
            <Col md={6} className="py-md-3 py-xxl-5 px-md-3 ">
              <h4 className="d-flex justify-content-center my-md-3 my-xxl-4 mt-3 text-uppercase">
                Sign Up
              </h4>
              <SignUpComponent scroll="scrollbar" />
            </Col>
          </Row>
        </div>
      </Container>

      <Container className="d-block d-md-none py-3">
        <Row>
          <Col className="col-12 col-md-0 d-flex justify-content-center align-items-center">
            <img src={Logo_Mobile} alt="comapny_logo" />
          </Col>
          <Col className="col-12 col-md-0 my-4">
            <h2 className="d-flex justify-content-center mt-md-2">Sign Up</h2>
            <SignUpComponent />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Registration;
