import React, { useState, useEffect, useCallback } from "react";

type Props = {
  children?: React.ReactNode;
};

type ContextType = {
  token: string | null;
  refreshToken: string | null;
  userId: number | null;
  userRole: string | null;
  isAuthenticated: boolean;
  isFirsttimeLogIn: boolean;
  setIsFirsttimeLogIn: React.Dispatch<React.SetStateAction<boolean>>;
  activeSubscriptionFlowStep: string | null;
  selectedTimezoneId: number | any;
  setSelectedTimezoneId: React.Dispatch<React.SetStateAction<number | null>>;
  isLogin: string;
  login: (
    token: string,
    userId: string,
    userRole: string,
    timezoneId: string,
    refreshToken: string,
    isLogin: string
  ) => void;
  subscriptionFlowStepHandler: (
    isFirsttimeLogIn: boolean,
    subscriptionFlowStep: string
  ) => void;
  refreshTokenSetHandler: (token: string, resfreshToken: string) => void;
  subscriptionFlowNotRequiredHandler: (isFirsttimeLogIn: boolean) => void;
  updateSubscriptionFlowStep: (step: string) => void;
  subscriptionFlowStepCompletedHandler: () => void;
  timezoneUpdateHandler: (changedTimezoneId: number) => void;
  logout: () => void;
};

const AuthContext = React.createContext<ContextType>({
  token: "",
  refreshToken: "",
  userId: null,
  userRole: "",
  isLogin: "",
  isAuthenticated: false,
  isFirsttimeLogIn: false,
  setIsFirsttimeLogIn: () => {},
  activeSubscriptionFlowStep: "",
  selectedTimezoneId: null,
  setSelectedTimezoneId: () => {},
  login: (
    token: string,
    userId: string,
    userRole: string,
    timezoneId: string,
    refreshToken: string,
    isLogin: string
  ) => {},
  subscriptionFlowStepHandler: (
    isFirsttimeLogIn: boolean,
    subscriptionFlowStep: string
  ) => {},
  refreshTokenSetHandler: (token: string, resfreshToken: string) => {},
  subscriptionFlowNotRequiredHandler: (isFirsttimeLogIn: boolean) => {},
  updateSubscriptionFlowStep: (step: string) => {},
  subscriptionFlowStepCompletedHandler: () => {},
  timezoneUpdateHandler: (changedTimezoneId: number) => {},
  logout: () => {},
});

export const AuthContextProvider: React.FC<Props> = (props) => {
  const [token, setToken] = useState<string | null>("");
  const [refreshToken, setRefreshToken] = useState<string | null>("");
  const [userId, setUserId] = useState<any>();
  const [userRole, setUserRole] = useState<any>("");
  const [isLogin, setIsLogin] = useState<any>();
  const [selectedTimezoneId, setSelectedTimezoneId] = useState<any>(null);
  const [isFirsttimeLogIn, setIsFirsttimeLogIn] = useState<boolean>(false);
  const [activeSubscriptionFlowStep, setActiveSubscriptionFlowStep] = useState<
    string | null
  >("");

  const userIsLoggedIn = token === "" ? false : true;

  useEffect(() => {
    const initStorage = () => {
      const storedToken = localStorage.getItem("token") || "";
      const storedUserId = localStorage.getItem("userId") || "";
      const storedUserRole = localStorage.getItem("userRole") || null;
      const storedTimezoneId = localStorage.getItem("timezoneId") || null;
      const storedRefreshToken = localStorage.getItem("refreshToken") || "";
      const storedisLogin = localStorage.getItem("isLogin") || null;
  

      setRefreshToken(storedRefreshToken);
      setToken(storedToken);
      setUserId(storedUserId);
      setUserRole(storedUserRole);
      setSelectedTimezoneId(storedTimezoneId);
      setIsLogin(storedisLogin);

      if (storedUserRole === "patient") {
        const storedIsFirsttimeLogin: any =
          localStorage.getItem("isFirsttimeLogIn") || null;

        setIsFirsttimeLogIn(
          JSON.parse(storedIsFirsttimeLogin) === true ? true : false
        );

        if (storedIsFirsttimeLogin) {
          const storedActiveSubscriptionFlowStep =
            localStorage.getItem("activeSubscriptionFlowStep") || null;

          setActiveSubscriptionFlowStep(storedActiveSubscriptionFlowStep);
        }
      }
    };
    initStorage();
  }, [userIsLoggedIn, token, userId]);

  const loginHandler = (
    token: string,
    userId: string,
    userRole: string,
    timezoneId: string,
    refreshToken: string,
    isLogin: string
  ) => {
    setToken(token);
    setUserId(parseInt(userId));
    setUserRole(userRole);
    setSelectedTimezoneId(parseInt(timezoneId));
    setRefreshToken(refreshToken);
    setIsLogin(isLogin);

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userRole", userRole);
    localStorage.setItem("timezoneId", timezoneId);
    localStorage.setItem("isLogin", isLogin);
  };

  const refreshTokenSetHandler = (
    accessToken: string,
    refreshToken: string
  ) => {
    setRefreshToken(refreshToken);
    setToken(accessToken);
    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  };

  const timezoneUpdateHandler = (changedTimezoneId: number) => {
    localStorage.setItem("timezoneId", JSON.stringify(changedTimezoneId));
    setSelectedTimezoneId(changedTimezoneId);
  };

  const logoutHandler = () => {
    setToken("");
    setUserId(null);
    setUserRole("");
    setIsFirsttimeLogIn(false);
    setActiveSubscriptionFlowStep("");
    setSelectedTimezoneId(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("timezoneId");
    localStorage.removeItem("refreshToken");
  };

  const subscriptionFlowStepHandler = (
    isFirsttimeLogIn: boolean,
    subscriptionFlowStep: string
  ) => {
    setIsFirsttimeLogIn(isFirsttimeLogIn);
    localStorage.setItem("isFirsttimeLogIn", JSON.stringify(isFirsttimeLogIn));

    setActiveSubscriptionFlowStep(subscriptionFlowStep);
    localStorage.setItem("activeSubscriptionFlowStep", subscriptionFlowStep);
  };

  const subscriptionFlowNotRequiredHandler = (isFirsttimeLogIn: boolean) => {
    setIsFirsttimeLogIn(isFirsttimeLogIn);
    localStorage.setItem("isFirsttimeLogIn", JSON.stringify(isFirsttimeLogIn));
  };

  const updateSubscriptionFlowStep = (step: string) => {
    setActiveSubscriptionFlowStep(step);
    localStorage.setItem("activeSubscriptionFlowStep", step);
  };

  const subscriptionFlowStepCompletedHandler = () => {
    setIsFirsttimeLogIn(false);
    localStorage.setItem("isFirsttimeLogIn", JSON.stringify(false));
    setActiveSubscriptionFlowStep(null);
    localStorage.removeItem("activeSubscriptionFlowStep");
  };
  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    userId: userId,
    userRole: userRole,
    isAuthenticated: userIsLoggedIn,
    isFirsttimeLogIn: isFirsttimeLogIn,
    setIsFirsttimeLogIn: setIsFirsttimeLogIn,
    refreshTokenSetHandler: refreshTokenSetHandler,
    activeSubscriptionFlowStep: activeSubscriptionFlowStep,
    selectedTimezoneId: selectedTimezoneId,
    isLogin: isLogin,
    setIsLogin: setIsLogin,
    setSelectedTimezoneId: setSelectedTimezoneId,
    timezoneUpdateHandler: timezoneUpdateHandler,
    login: loginHandler,
    logout: logoutHandler,
    subscriptionFlowStepHandler: subscriptionFlowStepHandler,
    subscriptionFlowNotRequiredHandler: subscriptionFlowNotRequiredHandler,
    updateSubscriptionFlowStep: updateSubscriptionFlowStep,
    subscriptionFlowStepCompletedHandler: subscriptionFlowStepCompletedHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
