import Logo from "../assets/images/logo-deletion.svg";
import { Container, Row } from "react-bootstrap";

const DeleteAccount = () => {
  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
      <Container>
        <Row className="mb-4">
          <img src={Logo} height={100} width={180} />
        </Row>
        <Row className="my-3">
          <div>
            <h4 className="fw-bold text-center text-primary">
              Data Deletion Right
            </h4>
            <p>
              You have the rights to access, rectify, or delete the data
              maintained by us.You may request a change/delete your personal
              data by contacting us by reffering the issue, email at{" "}
              <span className="text-decoration-underline">
                info@nydrawell.com
              </span>{" "}
              . Don't hesitate to contact us if you find any issue.
            </p>
          </div>
        </Row>
        <Row className="my-3">
          <div>
            <h4 className="fw-bold text-center text-primary">
              Instructions for Requesting Data Deletion
            </h4>
            <p>
              Instructions for Requesting Data Deletion To request data
              deletion, users may send an email to{" "}
              <span className="text-decoration-underline">
                info@nydrawell.com
              </span>{" "}
              from the email address that is valid and registered with app data.
              The subject line of the email should read{" "}
              <span className="fw-bold text-dark">
                "App data deletion request"
              </span>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default DeleteAccount;
