import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Desktop from "../../assets/images/desktopLogo.svg";
import WhiteCheck from "../../assets/images/WhiteCheck.png";
import Table from "../../assets/images/tableIMage.svg";
import { useNavigate } from "react-router-dom";

const InitialProgram = () => {
  const Navigate = useNavigate();
  return (
    <div>
      <div className="d-none d-md-block">
        <div className="how_it_works py-md-5">
          <Container className="py-md-5">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <img
                  src={Table}
                  alt="descktop_image"
                  style={{ width: "100%" }}
                  className="mt-4"
                />
              </Col>
              <Col>
                <div className="py-md-4">
                  <h2 className="initial_heading text-white">
                    Initial 12-week program to reinvigorate your sleep & <br />
                    Get 75% OFF When You Sign Up Now
                  </h2>
                </div>
                <div>
                  {/* <h5 className="initial_text text-white">
                    <img src={WhiteCheck} className="me-3" />
                    Nydra Pricing model
                    <span className="text-purple fw-bold">$300 </span>one-time
                    fee
                  </h5> */}
                  <h5 className="initial_text text-white mt-2">
                    <img src={WhiteCheck} className="me-3" /> There's no
                    complicated math here. Be one of our very first customers
                    and get 75% off Nydra Well when you join today.
                  </h5>
                </div>
                <div className="py-md-4">
                  <button
                    className="btn bg-white rounded-pill py-md-3 px-md-3 d-flex align-items-center fw-bold"
                    style={{ color: " #2F80ED" }}
                    onClick={() => Navigate("/signup")}
                  >
                    Get Started Now <i className="bi bi-arrow-right ms-2"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="d-md-none d-block">
        <div className="how_it_works py-md-5">
          <Container className="py-md-5">
            <Row>
              <Col className="col-12 d-flex justify-content-center align-items-center">
                <img
                  src={Table}
                  alt="descktop_image"
                  style={{ width: "100%" }}
                  className="mt-4"
                />
              </Col>
              <Col className="col-12">
                <div className="py-md-4">
                  <h2 className="initial_heading text-white">
                    Initial 12-week program to reinvigorate your sleep & <br />
                    Get 75% OFF When You Sign Up Now
                  </h2>
                </div>
                <div>
                  {/* <h5 className="initial_text text-white">
                    <img src={WhiteCheck} className="me-3" />
                    Nydra Pricing model
                    <span className="text-purple fw-bold">$300 </span>one-time
                    fee
                  </h5> */}
                  <h5 className="initial_text text-white mt-2">
                    <img src={WhiteCheck} className="me-3" /> There's no
                    complicated math here. Be one of our very first customers
                    and get 75% off Nydra Well when you join today.
                  </h5>
                </div>
              </Col>
              <Col className="col-12 d-flex justify-content-center pb-5">
                <div className="py-md-4">
                  <button
                    className="btn bg-white rounded-pill py-md-3 px-md-3 d-flex align-items-center fw-bold"
                    style={{ color: " #2F80ED" }}
                    onClick={() => Navigate("/signup")}
                  >
                    Get Started Now <i className="bi bi-arrow-right ms-2"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default InitialProgram;
