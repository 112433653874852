import React from "react";
import { Container } from "react-bootstrap";
import imageClick from "../../assets/images/buttonClick.png";
import Star from "../../assets/images/Star.png";
import chart from "../../assets/images/chat.png";

const RealPeopleComponent = () => {
  const realPeopleData = [
    {
      id: 1,
      image: imageClick,
      heading: "A Real Sleep Coach",
      content:
        "Who wants to talk with a bot about their sleep issues? Not us. You’ll work with your live coach one-on-one to create a plan unique to your sleep issues.",
    },
    {
      id: 2,
      image: Star,
      heading: "Set Goals",
      content:
        "When you meet with your coach, they will review your sleep data with you and together you’ll come up with an actionable plan to get you sleeping better.",
    },
    {
      id: 3,
      image: chart,
      heading: "Regular Check-ins",
      content:
        "Each month, you’ll meet with your sleep coach via video visits to talk about your plan and make modifications. You can also text them in between visits to ensure long-term success with your sleep.",
    },
  ];
  const RealpeopleCard = ({ image, text, heading, content }: any) => {
    return (
      <div className="mx-md-3 pb-3">
        <div className="realpeoplecard p-md-4 p-2">
          <div className="text-center">
            <img src={image} alt={text} className="realpeopleimage"/>
          </div>
          <div className="pt-md-3 pt-2">
            <h5 className="realpeoplecard_heading text-center">{heading}</h5>
            <p className="realpeoplecard_p text-center"> {content}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Container className="pb-md-5 d-none d-md-block">
        <div className="py-md-5">
          <h4 className="how_it_works_heading text-black">
            Real People, Not Robots
          </h4>
        </div>
        <div className="text-center px-md-5 mx-md-5">
          <p className="our_solution_p px-md-5 mx-md-5">
            Our comprehensive program combines state-of-the-art science,
            wearable technology and real humans to create a plan customized to
            your unique needs when it comes to sleep.
          </p>
        </div>
        <div className="d-flex justify-content-around align-items-center">
          {realPeopleData.map((data: any) => {
            return (
              <div key={data.id}>
                <RealpeopleCard
                  image={data.image}
                  heading={data.heading}
                  content={data.content}
                />
              </div>
            );
          })}
        </div>
      </Container>
      <Container fluid className="d-block d-md-none pb-5">
        <div className="pt-5 pb-3">
          <h4 className="how_it_works_heading text-black">
            Real People, Not Robots
          </h4>
        </div>
        <div>
          <p className="our_solution_p text-center">
            Our comprehensive program combines state-of-the-art science,
            wearable technology and real humans to create a plan customized to
            your unique needs when it comes to sleep.
          </p>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {realPeopleData.map((data: any) => {
            return (
              <div key={data.id}>
                <RealpeopleCard
                  image={data.image}
                  heading={data.heading}
                  content={data.content}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default RealPeopleComponent;
