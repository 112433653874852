import { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { slots } from "./slots";
import NotificationCategoryEditModal from "./NotificationCategoryEditModal";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const NotificationOptionsMobile = ({
  index,
  notification,
  patientId,
  apiSuccessHandler,
  updateNotification,
}: any) => {
  const axiosPrivate = useAxiosPrivate();
  const { status } = notification;

  const { MO, TU, WE, TH, FR, SA, SU } = notification?.wearabledata?.day;

  const { time } = notification?.wearabledata;

  const [
    isOpenNotificationCategoryEditModal,
    setIsOpenNotificationCategoryEditModal,
  ] = useState<boolean>(false);
  const closeNotificationCategoryEditModalHandler = () => {
    setIsOpenNotificationCategoryEditModal(false);
  };
  const updateField = (
    index: number,
    notificationId: number,
    field: string,
    value: string | boolean
  ) => {
    updateNotification(index, notificationId, field, value);
  };

  const deleteNotificationHandler = (id: number, patientId: number) => {
    axiosPrivate({
      method: "POST",
      url: "/deleteNotification",
      data: {
        id: id,
        patientId: patientId,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          apiSuccessHandler();
        }
      })
      .catch((error) => {
        return;
      });
  };
  return (
    <>
      <NotificationCategoryEditModal
        isOpenNotificationCategoryEditModal={
          isOpenNotificationCategoryEditModal
        }
        closeNotificationCategoryEditModalHandler={
          closeNotificationCategoryEditModalHandler
        }
        apiSuccessHandler={apiSuccessHandler}
        notificationId={notification.id}
        notificationCategory={notification.notification_category}
        smsnotificationCategory={notification.sms_notification_category}
        patientId={patientId}
      />
      <Card className="my-3">
        <Card.Body className="p-2">
          <Container>
            <div>
              <div className="text-center">
                <p className="text-muted fs-14">
                  {notification.notification_category}
                </p>
              </div>
              <div className="text-center">
                <p className="text-muted fs-14">
                  {notification.sms_notification_category}
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center">
                <div>
                  <span className="fs-14 m-0 d-block">
                    Turn on to activate notification
                  </span>
                </div>

                <div className=" form-switch ms-3">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    checked={status}
                    onChange={() =>
                      updateField(index, notification.id, "status", !status)
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className={`justify-content-center my-2 ${
                status ? "d-flex" : "d-none"
              }`}
            >
              <select
                style={{ width: "120px" }}
                className="custom-select px-1 py-1 cursor-pointer"
                onChange={(event) =>
                  updateField(
                    index,
                    notification.id,
                    "time",
                    event?.target?.value
                  )
                }
                disabled={!status}
              >
                {slots.map((slot) => (
                  <option selected={slot === time} key={slot} value={slot}>
                    {slot}
                  </option>
                ))}
              </select>
            </div>

            <Row className={`my-3 ${status ? "d-flex" : "d-none"}`}>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Mon</span>
                <Form.Check
                  type="checkbox"
                  checked={MO}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "MO", !MO)
                  }
                />
              </Col>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Tue</span>
                <Form.Check
                  type="checkbox"
                  checked={TU}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "TU", !TU)
                  }
                />
              </Col>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Wed</span>
                <Form.Check
                  type="checkbox"
                  checked={WE}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "WE", !WE)
                  }
                />
              </Col>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Thu</span>
                <Form.Check
                  type="checkbox"
                  checked={TH}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "TH", !TH)
                  }
                />
              </Col>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Fri</span>
                <Form.Check
                  type="checkbox"
                  checked={FR}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "FR", !FR)
                  }
                />
              </Col>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Sat</span>
                <Form.Check
                  type="checkbox"
                  checked={SA}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "SA", !SA)
                  }
                />
              </Col>
              <Col className="px-1 d-flex flex-column align-items-center justify-content-center">
                <span className="d-block">Sun</span>
                <Form.Check
                  type="checkbox"
                  checked={SU}
                  disabled={!status}
                  onChange={() =>
                    updateField(index, notification.id, "SU", !SU)
                  }
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              {notification.patient_id !== null && (
                <div className="d-flex justify-content-center align-items-center ion-icon-notification mx-1">
                  <button
                    onClick={() => setIsOpenNotificationCategoryEditModal(true)}
                    className=" btn p-2 rounded-circle ion-activatable ripple-parent mx-1 cursor-pointer"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button
                    onClick={() =>
                      deleteNotificationHandler(
                        notification.id,
                        notification.patient_id
                      )
                    }
                    className="btn p-2 rounded-circle ion-activatable ripple-parent mx-1 cursor-pointer"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              )}
            </div>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default NotificationOptionsMobile;
