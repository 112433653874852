import React, { useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import SleepHabitsVerticalBarChart from "../../components/patient/SleepHabitsVerticalBarChart";
import ChronotypeBarChart from "../../components/patient/ChronotypeBarChart";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Spinner from "../../utils/Spinner";

interface SleepProfileData {
  sleepHabit: any[];
  chronography: any[];
}

const ViewPatientData = () => {
  const axiosPrivate = useAxiosPrivate();
  const { token } = useContext(AuthContext);
  const [showChart, setShowChart] = useState<boolean>(false);
  const [ismessage, setIsMessage] = useState();
  const [chartsData, setChartsData] = useState({} as SleepProfileData);
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  let { patientId } = useParams();

  async function fetchSleepProfileData() {
    setIsLoading(true);
    await axiosPrivate({
      method: "POST",
      url: "/viewSleepChart",
      data: {
        userId: patientId,
      },
    })
      // await axios
      //   .post(
      //     "/viewSleepChart",
      //     { userId: patientId },
      //     {
      //       headers: {
      //         Accept: "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      .then((response: any) => {
        const { data } = response;

        if (data.success) {
          setShowChart(true);
          setChartsData(data.data);
        } else {
          setShowChart(false);
          setIsMessage(response.data.message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchSleepProfileData();
  }, []);
  return (
    <>
      {isLoading && <Spinner />}
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height">
          <div style={{ marginTop: "85px" }} className="py-md-3">
            <div className="d-flex justify-content-between">
              <div>
                <h4>Graphs</h4>
              </div>
              <div className="d-flex">
                <div
                  className="btn btn-purple me-2"
                  onClick={() => Navigate(-1)}
                >
                  <i className="bi bi-arrow-left"></i>
                </div>
                <div
                  onClick={() => Navigate(`/view_patient_form/${patientId}`)}
                  className="btn btn-outline-purple"
                >
                  <i className="bi bi-list-check me-2"></i>Self Assesment Data
                </div>
              </div>
            </div>
          </div>
          <div className="Custom_card px-md-5 py-md-4">
            {showChart ? (
              <>
                <h6 className="text-primary fw-bold px-3 mt-3">Sleep Habits</h6>
                <div className="d-flex justify-content-center py-4">
                  <SleepHabitsVerticalBarChart
                    sleepHabitsData={chartsData?.sleepHabit}
                  />
                </div>
                <h6 className="text-primary fw-bold px-3">Chronography</h6>
                <div className="d-flex justify-content-center py-4">
                  <ChronotypeBarChart
                    chronographyData={chartsData?.chronography}
                  />
                </div>
              </>
            ) : (
              <h5 className="text-center text-purple py-md-4 py-4">
                {ismessage}
              </h5>
            )}
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none py-5 mt-5">
        <div>
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col">
              <h6>Graphs</h6>
            </div>

            <div className="col d-flex">
              <button
                className="btn btn-purple me-2 px-2 py-0 m-0 "
                onClick={() => Navigate(-1)}
              >
                <i className="bi bi-arrow-left"></i>
              </button>
              <button
                onClick={() => Navigate(`/view_patient_form/${patientId}`)}
                className="btn btn-outline-purple px-2 py-0 m-0 fs-12 d-flex align-items-center"
              >
                <i className="bi bi-list-check me-2"></i>
                Self&nbsp;Assesment&nbsp;Data
              </button>
            </div>
          </div>

          <div>
            {showChart ? (
              <>
                <h6 className="text-primary fw-bold px-3 mt-3">Sleep Habits</h6>
                <div className="d-flex justify-content-center py-4">
                  <SleepHabitsVerticalBarChart
                    sleepHabitsData={chartsData?.sleepHabit}
                  />
                </div>
                <h6 className="text-primary fw-bold px-3">Chronography</h6>
                <div className="d-flex justify-content-center py-4">
                  <ChronotypeBarChart
                    chronographyData={chartsData?.chronography}
                  />
                </div>
              </>
            ) : (
              <h5 className="text-center text-purple py-md-4 py-4">
                {ismessage}
              </h5>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default ViewPatientData;
