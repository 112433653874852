import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Cornell from "../../assets/images/JW_headshot_Cornell.jpg";
import Boris from "../../assets/images/Boris Dubrovsky.jpg";
import Kishor from "../../assets/images/Kishor Gudimetla.jpg";
import christy from "../../assets/images/pfp_christy.png";
import Nikolova from "../../assets/images/Nikolova.jpg";

const SleepExpertCoponent = () => {
  const DoctorData = [
    {
      id: 1,
      image: Cornell,
      doctorName: "Dr. Jeremy Weingarten",
      doctortext: "Co-founder and Chief Medical Officer",
      description:
        "MD, MBA, MS has been a leader in clinical sleep medicine for 14 years.",
    },
    {
      id: 2,
      image: Kishor,
      doctorName: "Kishor Gudimetla",
      doctortext: "Co-founder and Chief Executive Officer",
      description:
        "MBA, MS has worked in technology, Insurance, and healthcare for over 19 years.",
    },
    {
      id: 3,
      image: Boris,
      doctorName: "Dr. Boris Dubrovsky",
      doctortext: "Chief Behavioral Officer",
      description:
        "PhD has worked in behavioral sleep medicine clinically for almost 20 years.",
    },
    {
      id: 4,
      image: christy,
      doctorName: "Christy Chan",
      doctortext: "Masters in psychology",
      description: "Masters in psychology with a particular focus on sleep.",
    },
    {
      id: 5,
      image: Nikolova,
      doctorName: "Stanimira-Ilieva Nikolova",
      doctortext: "Empathetic and solution-oriented",
      description:
        "Empathetic and solution-oriented sleep coach with 24 years of experience helping individuals obtain healthy lifestyles through better sleep practices.",
    },
  ];
  const ProfileCard = ({
    image,
    doctorName,
    doctorTitle,
    doctorDescriptioon,
  }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div className="doctor_profile mx-md-3 pb-3 pb-md-0 p-0 m-0 mt-5 mt-md-0">
        <div
          className={` doctor_card  p-0 m-0 ${isHovered ? "hovered" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="d-flex align-items-center justify-content-center p-0">
            <img
              src={image}
              alt="profile_image"
              className="doctor_image"
              // height="300px"
              // width="100%"
            />

            <div className="overlay">
              <div className="mt-md-3">
                <h6 className="doctor_name mt-md-1">
                  {/* <hr className="hr_style me-1" /> */}
                  {doctorName}
                </h6>
                <p className="doctor_title">{doctorTitle}</p>
              </div>
              <div className="doctor_description border-top">
                <span className="mt-2 text-white">{doctorDescriptioon}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="sleepcomponent py-md-5 d-none d-md-block">
        <Container className="py-md-5 d-none d-md-block">
          <h4 className="how_it_works_heading text-black">
            {/* Developed by <span className="text-dark_blue">Sleep Experts</span> */}
            Our Team
          </h4>
          <div className="d-flex justify-content-around align-items-center mt-md-5">
            {DoctorData.map((data: any) => {
              return (
                <div key={data.id}>
                  <ProfileCard
                    image={data.image}
                    doctorName={data.doctorName}
                    doctorTitle={data.doctortext}
                    doctorDescriptioon={data.description}
                  />
                </div>
              );
            })}
          </div>
        </Container>
      </div>
      <Container fluid className="d-block d-md-none py-4">
        <h4 className="how_it_works_heading text-black">Our team</h4>
        <Row>
          {DoctorData.map((data: any) => {
            return (
              <Col
                className="col-12 col-md-0 d-flex justify-content-center align-items-center "
                key={data.id}
              >
                <ProfileCard
                  image={data.image}
                  doctorName={data.doctorName}
                  doctorTitle={data.doctortext}
                  doctorDescriptioon={data.description}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default SleepExpertCoponent;
