import React from 'react'
import Heart1 from "../../assets/images/heart1 (1).png"
import Heart2 from "../../assets/images/heart1 (2).png"
import Heart3 from "../../assets/images/heart1 (3).png"
import Heart4 from "../../assets/images/heart1 (4).png"
import Heart5 from "../../assets/images/heart1 (5).png"
import Heart6 from "../../assets/images/heart1 (6).png"
import Heart7 from "../../assets/images/heart1 (7).png"
import Heart8 from "../../assets/images/heart1 (8).png"
import Brain_Img1 from "../../assets/images/Brain_imgs (8).png"
import Brain_Img2 from "../../assets/images/Brain_imgs (7).png"
import Brain_Img3 from "../../assets/images/Brain_imgs (6).png"
import Brain_Img4 from "../../assets/images/Brain_imgs (5).png"
import Brain_Img5 from "../../assets/images/Brain_imgs (4).png"
import Brain_Img6 from "../../assets/images/Brain_imgs (3).png"
import Brain_Img7 from "../../assets/images/Brain_imgs (2).png"
import Brain_Img8 from "../../assets/images/Brain_imgs (1).png"
import "./oursolution.css"

const SleepYourComponent = () => {
    return(
        <>
            <div className="main_top_bg">
                <div className="container text-center p-5 d-none d-md-block">
                    <div className="getmore">Sleep your way to Better Health</div>
                    <div className="relationship">Sleep plays a significant role in the development, progression, and management of
                        various chronic diseases. Here's how sleep is linked to some common chronic conditions:</div>
                </div>
                <div className="text-start p-2 d-block d-sm-none">
                    <div className="getmore">Sleep your way to Better Health</div>
                    <div className="relationship">Sleep plays a significant role in the development, progression, and management of
                        various chronic diseases. Here's how sleep is linked to some common chronic conditions:</div>
                </div>
            </div>
            <div className="container d-none d-md-block">
                <div className="row ">
                    <div className="col-md-6 col-sm-12 mt-2 ">
                        <h5 className="text-center brain ">Cardiovascular
                            Diseases</h5>
                        <img src={Heart2} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Neurological
                            Disorders</h5>
                        <img src={Heart3} className="heart" />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Mental Health Disorders</h5>
                        <img src={Heart4} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Diabetes</h5>
                        <img src={Heart5} className="heart" />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Respiratory
                            Disorders</h5>
                        <img src={Heart6} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Obesity</h5>
                        <img src={Heart7} className="heart" />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Immune Function</h5>
                        <img src={Heart1} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Pain
                            Conditions</h5>
                        <img src={Heart8} className="heart" />
                    </div>
                </div>
            </div>
            <div className="d-block d-sm-none p-2">
                <div className="row ">
                    <div className="col-md-6 col-sm-12 mt-2 ">
                        <h5 className="text-center brain ">Cardiovascular
                            Diseases</h5>
                        <img src={Brain_Img1} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Neurological
                            Disorders</h5>
                        <img src={Brain_Img2} className="heart" />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Mental Health Disorders</h5>
                        <img src={Brain_Img3} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-3">
                        <h5 className="text-center brain">Diabetes</h5>
                        <img src={Brain_Img4} className="heart" />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Respiratory
                            Disorders</h5>
                        <img src={Brain_Img5} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Obesity</h5>
                        <img src={Brain_Img6} className="heart" />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Immune Function</h5>
                        <img src={Brain_Img7} className="heart" />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                        <h5 className="text-center brain">Pain
                            Conditions</h5>
                        <img src={Brain_Img8} className="heart" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SleepYourComponent;