import React from "react";
import image1 from "../../assets/images/Frame_6 (1).png";
import image2 from "../../assets/images/Frame_6 (2).png";
import image3 from "../../assets/images/Frame_6 (3).png";
import image4 from "../../assets/images/Frame_6 (4).png";
import image5 from "../../assets/images/Frame_6 (5).png";
import image6 from "../../assets/images/Frame_6 (6).png";
// import image7 from "../../assets/images/Frame_6 (7).png";
import Fat from "../../assets/images/Fat.png";
import GetWeight from "../../assets/images/GetOFF.svg";
import Error from "../../assets/images/error.svg";
import Carousel from "react-bootstrap/Carousel";
import "./weightloss.css";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const WeightLossComponent = () => {
  const Navigate = useNavigate();
  const WeightData = [
    {
      id: 1,
      Image: Fat,
      text: "logo",
      heading: "Increased Obesity Risk",
      content:
        "Consistent short sleep disrupts appetite and metabolism,increasing obesity risk.",
    },
    {
      id: 2,
      Image: image6,
      text: "logo",
      heading: "38% Risk Increase",
      content:
        "Analysis of 5M+ people found a 38% higher obesity risk with short sleep.",
    },
    {
      id: 3,
      Image: image5,
      text: "logo",
      heading: "Fat Loss Challenge",
      content:
        "Short sleepers struggle to lose fat on calorie-restricted diets due to disrupted metabolism.",
    },
    {
      id: 4,
      Image: image4,
      text: "logo",
      heading: "Sleep & Weight",
      content:
        "Increasing sleep duration helps prevent weight gain compared to inadequate sleep.",
    },
    {
      id: 5,
      Image: image3,
      text: "logo",
      heading: "Neurotransmitter Impact",
      content:
        "Lack of sleep disrupts appetite-controlling ghrelin and leptin, leading to increased hunger.",
    },
    {
      id: 6,
      Image: image2,
      text: "logo",
      heading: "Food Preferences",
      content:
        "Sleep-deprived individuals tend to choose high-calorie, carb-rich foods, contributing to weight gain.",
    },
    {
      id: 7,
      Image: image1,
      text: "logo",
      heading: "Orexin & Endocannabinoid System",
      content:
        "Hypotheses suggest orexin and the endocannabinoid system play a role in sleep and appetite regulation.",
    },
    {
      id: 8,
      Image: GetWeight,
      content: "One of our first customers and get 75% off Nydra Well ",
    },
  ];
  const WeightCard = ({ id, Image, content, heading, text, link }: any) => {
    return (
      <div className={` pb-3 ${id}`}>
        <div className="card_weight">
          <div className="text-center">
            {id === 8 ? (
              <div className="mt-md-5 pt-md-3">
                <img src={Image} alt={text} />
              </div>
            ) : (
              <img src={Image} alt={text} />
            )}
          </div>
          <div className="pt-md-3 pt-2">
            <h5 className="card-title text-center">{heading}</h5>
            <p className="card-text text-center"> {content}</p>
          </div>
          {id === 8 && (
            <div className="d-flex justify-content-center mt-md-4">
              <button
                type="button"
                className="px-4 py-3 rounded-pill border-0 getstart mt-4"
                onClick={() => Navigate("/signup")}
              >
                Get Started<i className="bi bi-arrow-right"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className=" d-none d-md-block main_top_bg">
        <div className="container">
          <div className=" text-center">
            <div className="WeightLossHeading p-md-4">
              Get More ZZZ's to Lose More Pounds: Cracking the Sleep-Weight Loss
              Code!
            </div>
            <div className="weightlosstext px-4 mb-md-4">
              The relationship between insufficient sleep and obesity is
              intricate, involving disruptions in appetite regulation, altered
              food choices, and metabolic changes. Prioritizing better sleep is
              crucial for overall health and effective weight management
            </div>
          </div>
        </div>
      </div>
      <Container className=" d-none d-md-block">
        <div className=" d-block d-md-none">
          <div className="p-2 text-start">
            <div className="getmore ">
              Get More ZZZ's to Lose More Pounds: Cracking the Sleep-Weight Loss
              Code!
            </div>
            <div className="relationship ">
              The relationship between insufficient sleep and obesity is
              intricate, involving disruptions in appetite regulation, altered
              food choices, and metabolic changes. Prioritizing better sleep is
              crucial for overall health and effective weight management
            </div>
          </div>
        </div>
        <div className="row">
          {WeightData.map((data: any) => {
            return (
              <div key={data.id} className="col-md-3">
                <WeightCard
                  Image={data.Image}
                  heading={data.heading}
                  content={data.content}
                  onClick={data.link}
                  id={data.id}
                />
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-evenly evenly p-2 mb-5">
          <div className="mt-2 me-2">
            <img src={Error} alt="error" />
          </div>
          <div className="mt-1">
            <div className="Weightloassrelationship text-start">
              The relationship between short sleep and obesity
            </div>
            <p className=" weightlosstext">
              The complex relationship between short sleep and obesity involves
              physiological and behavioral factors. Improving sleep quality is
              essential for overall health and effective weight management.
            </p>
          </div>
        </div>
      </Container>
      {/* //mobile/ */}
      <Container fluid className="d-block d-md-none p-3">
        <div>
          <div className="WeightLossHeading p-md-4">
            Get More ZZZ's to Lose More Pounds: Cracking the Sleep-Weight Loss
            Code!
          </div>
          <div className="weightlosstext mb-md-4 mb-4">
            The relationship between insufficient sleep and obesity is
            intricate, involving disruptions in appetite regulation, altered
            food choices, and metabolic changes. Prioritizing better sleep is
            crucial for overall health and effective weight management
          </div>
        </div>
        <Carousel>
          <Carousel.Item className="carousel_item">
            <img src={Fat} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">Increased Obesity Risk</h5>
              <p className="card-text card-text-p mt-3">
                Consistent short sleep disrupts appetite and metabolism,
                increasing obesity risk.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel_item">
            <img src={image6} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">38% Risk Increase</h5>
              <p className="card-text card-text-p mt-3">
                Analysis of 5M+ people found a 38% higher obesity risk with
                short sleep.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel_item">
            <img src={image5} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">Fat Loss Challenge</h5>
              <p className="card-text card-text-p mt-3">
                Short sleepers struggle to lose fat on calorie-restricted diets
                due to disrupted metabolism.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel_item">
            <img src={image4} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">Sleep & Weight</h5>
              <p className="card-text card-text-p mt-3">
                Increasing sleep duration helps prevent weight gain compared to
                inadequate sleep.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel_item">
            <img src={image3} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">Neurotransmitter Impact</h5>
              <p className="card-text card-text-p mt-3">
                Lack of sleep disrupts appetite-controlling ghrelin and leptin,
                leading to increased hunger.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel_item">
            <img src={image2} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">Food Preferences</h5>
              <p className="card-text card-text-p mt-3">
                Sleep-deprived individuals tend to choose high-calorie,
                carb-rich foods, contributing to weight gain.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel_item">
            <img src={image1} className="card_img" />
            <Carousel.Caption>
              <h5 className="card-title">Orexin & Endocannabinoid System</h5>
              <p className="card-text card-text-p mt-3">
                Hypotheses suggest orexin and the endocannabinoid system play a
                role in sleep and appetite regulation.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <div className="mt-4">
          <div className="card px-3 py-4">
            <div className="text-center">
              <div className="pt-3">
                <img src={GetWeight} alt="Logo" />
              </div>
            </div>
            <div className="pt-4">
              <p className="card-text text-center">
                One of our first customers and get 75% off Nydra Well{" "}
              </p>
            </div>

            <div className="d-flex justify-content-center mt-md-4">
              <button
                type="button"
                className="px-4 py-3 rounded-pill border-0 getstart mt-4"
                onClick={() => Navigate("/signup")}
              >
                Get Started<i className="bi bi-arrow-right ms-2"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="d-flex justify-content-evenly evenly p-2 mb-5">
            <div className="mt-2 me-2">
              <img src={Error} alt="error" />
            </div>
            <div className="mt-1">
              <div className="relationship text-start">
                The relationship between short sleep and obesity
              </div>
              <p className="complex">
                The complex relationship between short sleep and obesity
                involves physiological and behavioral factors. Improving sleep
                quality is essential for overall health and effective weight
                management.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WeightLossComponent;
