import React from "react";
import Footer_Img from "../../assets/images/Subtract (1).png";
import { Container, Row, Col } from "react-bootstrap";
import Nidra_Well_Logo from "../../assets/images/Nydra_Wellness_logo_FINAL_2 2 (1).png";
import Facebook_icon from "../../assets/images/facebook-logo-2019 1.png";
import Twitter_icon from "../../assets/images/twitter 1.png";
import YouTube_icon from "../../assets/images/Exclude.png";
import Instagram_icon from "../../assets/images/instagram 1.png";
import Footer_Sm_Img from "../../assets/images/Subtract (2).png";
import Accordion from "react-bootstrap/Accordion";
import Logo_Sm from "../../assets/images/Nydra_Well_logo_FINAL_2 21.png";
import StillThinkingAboutIt from "./StillThinkingAboutIt";
import Google from "../../assets/images/Google.png";
import AppleStore from "../../assets/images/appleStore.svg";
import styles from "./Landing.module.scss";
import { Link } from "react-router-dom";

const FooterPage = () => {
  return (
    <>
      <div className="Footer_bg">
        <img
          src={Footer_Img}
          alt="Subtract (1)"
          className="footer_width d-none d-md-block "
        />

        {/* <img src={Footer_Sm_Img} alt="footer_sm_img" className="d-block d-sm-none footer_sm_img"/> */}
        <div className="footer_child bg-black w-100 mb-0">
          <div className="d-flex justify-content-center still_top">
            <StillThinkingAboutIt />
          </div>

          <Container fluid className="mt-md-5 d-none d-md-block ">
            <div className="footer_address ">
              <Row className="mb-md-5">
                <Col md={3}>
                  <Row>
                    <Col className="col-12">
                      <img src={Nidra_Well_Logo} alt="nidra_well_logo" />

                      <div className="mt-md-4">
                        <h5 className="follow text-white">FOLLOW US</h5>
                        <div className="ms-md-2">
                          <img
                            src={Facebook_icon}
                            alt="facebook"
                            className="me-md-2"
                          />
                          <img
                            src={Twitter_icon}
                            alt="twitter"
                            className="me-md-2"
                          />
                          <img
                            src={YouTube_icon}
                            alt="youtube"
                            className="me-md-2"
                          />
                          <img
                            src={Instagram_icon}
                            alt="instagram"
                            className="me-md-2"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mt-md-4">
                        <h6 className="ms-md-2 text-white mb-md-3">
                          Download our app from
                        </h6>
                        <div className="ms-md-4 d-felx flex-column ">
                          <a
                            href="https://play.google.com/store/apps/details?id=io.nydrahealth.nydrawell&pli=1"
                            target="_blank"
                          >
                            <img
                              src={Google}
                              alt="facebook"
                              className="me-md-2 w-50"
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/ae/app/nydra-well/id6449287979"
                            target="_blank"
                          >
                            <img
                              src={AppleStore}
                              alt="twitter"
                              className="me-md-2 mt-md-3 w-50"
                            />
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <h5 className="footer_web_heading text-white">Company</h5>
                  <ul className="footer_list_style">
                    <li className="my-md-3">
                      <Link to="/" className="footer_list_items">
                        Home
                      </Link>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        About
                      </a>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        Service
                      </a>
                    </li>
                    {/* <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        How it Works
                      </a>
                    </li> */}
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        Resource
                      </a>
                    </li>
                  </ul>
                </Col>
                {/* <Col md={3}>
                  <h5 className="footer_web_heading text-white">Quick Link</h5>
                  <ul className="footer_list_style">
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        B2B Market
                      </a>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        Health Insurers
                      </a>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        Gallery
                      </a>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        Partners
                      </a>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        Contact us
                      </a>
                    </li>
                  </ul>
                </Col> */}
                <Col md={4}>
                  <h5 className="footer_web_heading text-white">
                    Contact Info
                  </h5>
                  <ul className="footer_list_style">
                    {/* <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        (303) 555-0105
                      </a>
                    </li>
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        (303) 555-0105
                      </a>
                    </li> */}
                    <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        info@nydrawell.com
                      </a>
                    </li>
                    {/* <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        3517 W. Gray St. Utica,
                        <br />
                        aennsylvania 57867
                      </a>
                    </li> */}
                  </ul>
                </Col>
              </Row>
              <div className="pe-xxl-5 pe-md-5 mb-md-5 mb-xxl-0 affliation">
                <div
                  className="d-flex justify-content-between align-items-center py-md-3 px-md-4"
                  style={{
                    background: "#808080",
                    borderRadius: "10px",
                  }}
                >
                  <div>
                    <p className="p-0 m-0">
                      © 2023 NydraWellness. All Rights Reserved
                    </p>
                  </div>
                  <div>
                    <p className="p-0 m-0">Privacy Policy Cookie Policy</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div
        className="footer_bg_sm d-block d-sm-none"
        style={{ background: "#000", position: "relative", marginTop: "65%" }}
      >
        <div className="footer_content">
          <div className="d-flex justify-content-center mt-4">
            <img src={Logo_Sm} alt="logo_sm" style={{ marginTop: "50%" }} />
          </div>
          <Row className="mt-5">
            <Col sm={12} className="px-5 px-md-0">
              <Accordion className={`mb-4 ${styles.footerscustomAccordian}`}>
                <Accordion.Item
                  eventKey="0"
                  bsPrefix={`${styles.accordianItem}`}
                >
                  <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                    Company
                  </Accordion.Header>
                  <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                    <p className="text-white">Home</p>
                    <p className="text-white">About</p>
                    <p className="text-white">Service</p>
                    {/* <p className="text-white">How it Works</p> */}
                    <p className="text-white">Resource</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* </Col>/ */}
              {/* <Col sm={12}> */}
              {/* <Accordion className={` mb-4 ${styles.footerscustomAccordian}`}>
                <Accordion.Item eventKey="1" bsPrefix="Footer_accordion">
                  <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                    Quick Link
                  </Accordion.Header>
                  <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                    <p className="text-white">B2B Market</p>
                    <p className="text-white">Health Insurers</p>
                    <p className="text-white">Gallery</p>
                    <p className="text-white">Partners</p>
                    <p className="text-white">Contact us</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </Col>
            <Col sm={12} className="px-5 px-md-0">
              <Accordion className={` mb-4 ${styles.footerscustomAccordian}`}>
                <Accordion.Item eventKey="2" bsPrefix="Footer_accordion">
                  <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                    Contact Info.
                  </Accordion.Header>
                  <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                    <p>info@nydrawell.com</p>
                    {/* <p>(303) 555-0105</p>
                    <p>nydra@examale.com</p>
                    <p>
                      3517 W. Gray St. Utica,
                      <br />
                      aennsylvania 57867
                    </p> */}
                    {/* <li className="my-md-3">
                      <a href="#" className="footer_list_items">
                        info@nydrawell.com
                      </a>
                    </li> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <h6 className="copy_right_1">FOLLOW US</h6>
            <div className="mt-3">
              <img src={Facebook_icon} alt="facebook" className="mx-3" />
              <img src={Twitter_icon} alt="twitter" className="mx-3" />
              <img src={YouTube_icon} alt="youtube" className="mx-3" />
              <img src={Instagram_icon} alt="instagram" className="mx-3" />
            </div>
          </div>
          <div className="text-center copy_right py-2 mt-5">
            <p className="copy_right_1">
              © 2023 NydraWellness. All Rights Reserved
            </p>
            <a href="#" className="copy_right_1 mr-3">
              Privacy Policy
            </a>
            <a href="#" className="copy_right_1">
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterPage;
