import { useState, useRef } from "react";
import { slots } from "./slots";
import Form from "react-bootstrap/Form";
import NotificationCategoryEditModal from "./NotificationCategoryEditModal";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const NotificationOptionsWeb = ({
  index,
  notification,
  apiSuccessHandler,
  patientId,
  updateNotification,
}: any) => {
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const { status } = notification;

  const { MO, TU, WE, TH, FR, SA, SU } = notification?.wearabledata?.day;

  const { time } = notification?.wearabledata;

  const [
    isOpenNotificationCategoryEditModal,
    setIsOpenNotificationCategoryEditModal,
  ] = useState<boolean>(false);

  const closeNotificationCategoryEditModalHandler = () => {
    setIsOpenNotificationCategoryEditModal(false);
  };

  const updateField = (
    index: number,
    notificationId: number,
    field: string,
    value: string | boolean
  ) => {
    updateNotification(index, notificationId, field, value);
  };

  const deleteNotificationHandler = (id: number, patientId: number) => {
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/deleteNotification",
      data: {
        id: id,
        patientId: patientId,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          apiSuccessHandler();
          toast.update(toastId.current, {
            render: "Notification Deleted successfully",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((error) => {
        return;
      });
  };

  return (
    <>
      <NotificationCategoryEditModal
        isOpenNotificationCategoryEditModal={
          isOpenNotificationCategoryEditModal
        }
        closeNotificationCategoryEditModalHandler={
          closeNotificationCategoryEditModalHandler
        }
        apiSuccessHandler={apiSuccessHandler}
        notificationId={notification.id}
        notificationCategory={notification.notification_category}
        smsnotificationCategory={notification.sms_notification_category}
        patientId={patientId}
      />
      <tr>
        <td className="align-middle">
          <h6 className=" fs-14 d-flex align-items-center">
            {notification.notification_category}
            <span className="d-flex justify-content-end">
              {notification.patient_id !== null && (
                <div className="d-flex justify-content-center align-items-center mx-1">
                  <button
                    onClick={() => setIsOpenNotificationCategoryEditModal(true)}
                    className=" btn p-2 rounded-circle ion-activatable ripple-parent mx-1 cursor-pointer"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button
                    onClick={() =>
                      deleteNotificationHandler(
                        notification.id,
                        notification.patient_id
                      )
                    }
                    className="btn p-2 rounded-circle ion-activatable ripple-parent mx-1 cursor-pointer"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              )}
            </span>
          </h6>
        </td>
        <td className="align-middle">
          {notification.sms_notification_category}
        </td>
        <td className="align-middle">
          <Form.Check
            className="mx-auto"
            type="switch"
            checked={status}
            onChange={() =>
              updateField(index, notification.id, "status", !status)
            }
          />
        </td>
        <td className="align-middle">
          <select
            style={{ width: "100px" }}
            className="border-purple px-1 py-1 cursor-pointer"
            onChange={(event) =>
              updateField(index, notification.id, "time", event?.target?.value)
            }
            disabled={!status}
          >
            {slots.map((slot) => (
              <option selected={slot === time} key={slot} value={slot}>
                {slot}
              </option>
            ))}
          </select>
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={MO}
            checked={MO}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "MO", !MO)}
          />
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={TU}
            checked={TU}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "TU", !TU)}
          />
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={WE}
            checked={WE}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "WE", !WE)}
          />
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={TH}
            checked={TH}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "TH", !TH)}
          />
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={FR}
            checked={FR}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "FR", !FR)}
          />
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={SA}
            checked={SA}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "SA", !SA)}
          />
        </td>
        <td className="align-middle">
          <input
            className="form-check-input fs-18 d-block"
            type="checkbox"
            value={SU}
            checked={SU}
            disabled={!status}
            onClick={() => updateField(index, notification.id, "SU", !SU)}
          />
        </td>
      </tr>
    </>
  );
};

export default NotificationOptionsWeb;
