import React, { useContext, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import AuthContext from "../../context/authContext1";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Spinner from "../../utils/Spinner";
import { ToastContainer, toast } from "react-toastify";

const PatientList = () => {
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated } = useContext(AuthContext);
  const [patientsList, setPatientsList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filterResult = patientsList.filter((e: any) =>
    e.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  async function fetchPatientsList() {
    setIsLoading(true);
    await axiosPrivate({
      method: "POST",
      url: "/patientlist",
      data: {},
    })
      .then((response) => {
        if (response.data.code === "200") {
          setPatientsList(response.data.data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchPatientsList();
    }
  }, [isAuthenticated]);

  const handleDelete = (id: any) => {
    axiosPrivate({
      method: "DELETE",
      url: `/patients/${id}`,
      data: {},
    })
      // axios
      //   .delete(`/patients/${id}`, {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   })
      .then(() => {
        setPatientsList((prevList) =>
          prevList.filter((patient) => patient.id !== id)
        );
        toast.success("Patient deleted successfully.");
      })
      .catch((error) => {
        alert("Error deleting patient. Please try again later.");
      });
  };

  const totalRows = patientsList.length;
  const handleChangePage = (newPage: any) => {
    setCurrentPage(newPage);
  };
  let itemsPerPage = 10;

  const columns = [
    {
      name: "Sr No",
      cell: (row: any, index: any) => {
        const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
        return serialNumber;
      },
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row: any) => row.mobileno,
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   cell: (row: any) => (
    //     <button
    //       className="btn bg-danger rounded-1 text-white"
    //       onClick={() => handleDelete(row.id)}
    //     >
    //       <i className="bi bi-trash me-2"></i>Delete
    //     </button>
    //   ),
    // },
  ];

  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer />
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height  my-md-4">
          <div
            style={{ marginTop: "85px" }}
            className="Custom_card px-md-5 py-md-3"
          >
            <div className="d-flex align-items-center justify-content-between my-md-1">
              <div>
                <h4 className="">Patient List</h4>
              </div>
              <div className="px-md-3 py-md-2 border border-purple rounded d-flex align-items-center">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by name"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <i className="bi bi-search" style={{ color: "#6f4bff" }}></i>
              </div>
            </div>
            <div className="shadow">
              <DataTable
                pagination
                paginationTotalRows={totalRows}
                onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={columns}
                data={filterResult}
              />
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none">
        <div className="row mt-5 pt-5 d-flex align-items-center">
          <div className="col">
            <h6>Patient Lists</h6>
          </div>
        </div>
        <div>
          {patientsList.map((patient: any, idx: number) => {
            const { id } = patient;
            return (
              <Card className="my-3 border-light border">
                <div className={`px-3 py-1`}>
                  <div className="d-flex justify-content-between align-items-center mt-2 p-0 m-0">
                    <p className="text-uppercase fw-medium fs-14">
                      {patient.name}
                    </p>
                  </div>

                  <div>
                    <div className="d-flex align-items-center">
                      <i className="bi bi-telephone"></i>
                      <p className="my-0 ms-2 text-dark"> {patient.mobileno}</p>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-envelope"></i>
                      <p className="my-0 ms-2 text-dark"> {patient.email}</p>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end align-items-center my-2">
                    <button
                      className=" px-4 py-1 btn bg-danger text-white ms-2"
                      onClick={() => handleDelete(id)}
                    >
                      <i className="bi bi-trash me-1"></i>Delete
                    </button>
                  </div> */}
                </div>
              </Card>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default PatientList;
