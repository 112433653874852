import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Yogo from "../../assets/images/Yogo.png";
import YogoVector from "../../assets/images/yogo_vector.svg";
import Yogo_vector_mobile from "../../assets/images/yogo_vector_mobile.png";
import Mobile_yogo from "../../assets/images/mobile_image_yogo.png";
import Sol from "../../assets/images/solution1.svg";
import Sol2 from "../../assets/images/solution2.svg";
import Sol3 from "../../assets/images/solution3.svg";
import Sol4 from "../../assets/images/Sol5.svg";
import { useNavigate } from "react-router-dom";

const OurSolutionComponent = () => {
  const Navigate = useNavigate();
  const ourSolutionData = [
    { id: 1, Image: Sol, text: "Sol", content: "User assessment data" },
    {
      id: 2,
      Image: Sol2,
      text: "Sol2",
      content: "Sleep data from wearable devices",
    },
    { id: 3, Image: Sol3, text: "Sol3", content: "One-on-one sleep coaching" },
    {
      id: 4,
      Image: Sol4,
      text: "Sol4",
      content:
        "Crafted by Sleep and Behavior Science Experts for Sustainable Habit Building",
    },
  ];
  const OurSoultionData = ({ Image, text, content }: any) => {
    return (
      <div className="d-flex align-items-center">
        <div>
          <img src={Image} alt={text} className="our_solution_image" />
        </div>
        <div className="ms-3 our_solution_h">
          <h4>{content}</h4>
        </div>
      </div>
    );
  };
  return (
    <>
      <Container className="mt-md-5 d-none d-md-block">
        <Row>
          <Col style={{ position: "relative" }}>
            <img
              src={YogoVector}
              alt="yogo_vector"
              style={{ position: "absolute", top: "-275px", left: "-250px" }}
            />
            <img
              src={Yogo}
              alt="yogo"
              style={{ position: "absolute", top: 0, left: 0 }}
            />
          </Col>

          <Col className="ps-md-5 ms-md-5 ms-xxl-0">
            <div className="ps-md-4  ms-md-5 ms-xxl-0">
              <h3 className="our_solution_heading">Our Solution</h3>
              <p className="our_solution_p">
                A 12 week program to reinvigorate your sleep that’s based on a
                personalized approach to improving your sleep.
              </p>
              <p className="our_solution_p">
                Our program was developed utilizing cognitive-behavioral
                techniques to develop a lifetime of resilient sleep habits.
              </p>
              <div>
                {ourSolutionData.map((data: any) => {
                  return (
                    <div key={data.id} className="mt-3">
                      <OurSoultionData
                        Image={data.Image}
                        text={data.text}
                        content={data.content}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-md-4 ps-md-4 ms-md-5 ms-xxl-0">
              <button
                className="btn bg-dark_blue text-white rounded-pill py-md-3 d-flex align-items-center"
                onClick={() => Navigate("/signup")}
              >
                Get Started Now <i className="bi bi-arrow-right ms-2"></i>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="d-block d-md-none">
        <div className="text-center">
          <h3 className="our_solution_heading">Our Solution</h3>
        </div>
        <div style={{ position: "relative" }}>
          <img
            src={Yogo_vector_mobile}
            alt="yogo_vector"
            style={{ position: "absolute", top: 0, left: "-20px" }}
          />
          <img
            src={Mobile_yogo}
            alt="yogo"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        </div>
        <div style={{ marginTop: "353px" }}>
          <p className="our_solution_p">
            A 12 week program to reinvigorate your sleep that’s based on a
            personalized approach to improving your sleep.
          </p>
          <p className="our_solution_p">
            Our program was developed utilizing cognitive-behavioral techniques
            to develop a lifetime of resilient sleep habits.
          </p>
        </div>
        <div>
          {ourSolutionData.map((data: any) => {
            return (
              <div key={data.id} className="mt-3">
                <OurSoultionData
                  Image={data.Image}
                  text={data.text}
                  content={data.content}
                />
              </div>
            );
          })}
        </div>
        <div className="mt-4">
          <button className="btn bg-dark_blue text-white rounded-pill py-3 w-100 d-flex align-items-center justify-content-center">
            Get Started Now <i className="bi bi-arrow-right ms-2"></i>
          </button>
        </div>
      </Container>
    </>
  );
};

export default OurSolutionComponent;
