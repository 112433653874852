import React, { useState, useEffect, useContext } from "react";
import AuthContext from "./authContext1";
import axios from "../utils/axios";

type Props = {
  children?: React.ReactNode;
};

interface Profile {
  isProfileEdited: boolean;
  toggleIsProfileEditted: () => void;
  availableTimezones: any[];
}

const ProfileContext = React.createContext<Profile>({
  isProfileEdited: false,
  toggleIsProfileEditted: () => {},
  availableTimezones: [],
});

export const ProfileContextProvider: React.FC<Props> = (props) => {
  const {
    isAuthenticated,
    token,
    userRole,
    selectedTimezoneId,
    setSelectedTimezoneId,
  } = useContext(AuthContext);

  const [isProfileEdited, setIsProfileEdited] = useState(false);

  const [availableTimezones, setAvailableTimezones] = useState<any[]>([]);

  const toggleIsProfileEditted = () => {
    setIsProfileEdited(!isProfileEdited);
  };

  useEffect(() => {
    if (isAuthenticated && userRole !== "superadmin") {
      axios
        .get("/view_timezones")
        .then((response) => {
          const { data } = response.data;
          setAvailableTimezones(data);
        })
        .catch((err) => {
          return;
        });
    }
  }, [isAuthenticated]);

  const contextValue = {
    isProfileEdited,
    toggleIsProfileEditted,
    selectedTimezoneId,
    setSelectedTimezoneId,
    availableTimezones,
  };

  return (
    <ProfileContext.Provider value={contextValue}>
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
