import { useState } from "react";
import { Modal, Button, Col, Row, Spinner } from "react-bootstrap";
import { TextField } from "../utils/Fromcontrol";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useAxiosPrivate from "../utils/useAxiosPrivate";

const ForgotPasswordModal = ({
  isForgotPasswordModal,
  handleCloseForgotPasswordModal,
}: any) => {
  const axiosPrivate = useAxiosPrivate();
  const [isMessage, setIsMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const onSubmit = (values: any, { resetForm }: any) => {
    setIsLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/forgetPassword",
      data: {
        email: values.email,
      },
    })
      .then((res) => {
        const status = res.data.status === "success";
        if (status) {
          setIsMessage(res.data.message);
          setTimeout(() => {
            handleCloseForgotPasswordModal();
            setIsMessage("");
          }, 1000);
          resetForm();
        } else {
          setIsMessage(res.data.message);
          setTimeout(() => {
            setIsMessage("");
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        show={isForgotPasswordModal}
        onHide={handleCloseForgotPasswordModal}
        animation={false}
        centered
        fullscreen="md-down"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({}) => (
            <Form>
              <Modal.Header closeButton className="bg-purple">
                <Modal.Title className="text-white">
                  Forgot Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="py-md-3 px-md-4">
                <Row className="py-md-3">
                  <Col>
                    <TextField
                      type="email"
                      name="email"
                      label="Email Address"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5 className="text-purple text-center fw-600">
                      {isMessage}
                    </h5>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="border-0">
                <Button
                  className="btn btn-outline-purple"
                  onClick={handleCloseForgotPasswordModal}
                >
                  Close
                </Button>
                <Button
                  variant="purple"
                  type="submit"
                  className="d-flex justify-content-center"
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        animation="border"
                        variant="purple"
                        size="sm"
                        className="me-3"
                      />{" "}
                      Sending...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
