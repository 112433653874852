import React, { useRef } from "react";
import { EditDoctorProps } from "../../types/EditDoctorType";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { TextField, TextSelect } from "../../utils/Fromcontrol";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import { toast } from "react-toastify";

const EditDoctorModal = ({
  closeEditDoctorHandler,
  isOpenEditDoctorModal,
  apiSuccessHandler,
  doctorDetails,
}: EditDoctorProps) => {
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const initialValues = {
    fName: doctorDetails.firstname,
    lName: doctorDetails.lastname,
    email: doctorDetails.email,
    status: doctorDetails.status,
    mobile: doctorDetails.mobileno,
  };
  const validationSchema = Yup.object({
    fName: Yup.string().required("First Name is required"),
    lName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
    status: Yup.number().required("Status is required"),
  });

  const StatusDropDown = [
    { key: "InActive", value: "1" },
    { key: "Active", value: "0" },
  ];

  const submitHandler = (values: any) => {
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/createDoctor",
      data: {
        fName: values.fName,
        lName: values.lName,
        email: values.email,
        phone: values.mobile,
        status: values.status,
        doctor_id: doctorDetails.id,
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          closeEditDoctorHandler();
          apiSuccessHandler();
          toast.update(toastId.current, {
            render: "Doctor Updated successfully",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err: any) => {
        return;
      });
  };
  return (
    <Modal
      show={isOpenEditDoctorModal}
      onHide={closeEditDoctorHandler}
      backdrop="static"
      keyboard={false}
      centered
      fullscreen="md-down"
    >
      <Modal.Header className="py-2 bg-purple" closeButton>
        <Modal.Title className="text-white">Edit Doctor</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        <Form>
          <Modal.Body className="px-md-5">
            <div className="my-4">
              <TextField
                name="fName"
                type="text"
                label="First Name"
                placeholder="First Name"
              />
            </div>
            <div className="my-4">
              <TextField
                name="lName"
                type="text"
                label="Last Name"
                placeholder="Last Name"
              />
            </div>
            <div className="my-4">
              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="Email"
              />
            </div>
            <div className="my-4">
              <TextField
                name="mobile"
                type="number"
                label="Mobile"
                placeholder="mobile"
              />
            </div>
            <div>
              <TextSelect
                name="status"
                label="Status"
                options={StatusDropDown}
                placeholder="status"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="py-2 border border-0 px-md-5 pb-3">
            <button
              type="submit"
              className="btn btn-purple rounded-1 px-4 py-1"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closeEditDoctorHandler}
              className="btn btn-purple rounded-1 px-4 py-1 "
            >
              Close
            </button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export default EditDoctorModal;
