import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import Brain from "../../assets/images/head_image.svg";
import one from "../../assets/images/01.svg";
import Human_graph from "../../assets/images/humanGraph.svg";
import Two from "../../assets/images/02.svg";
import brain_bulb from "../../assets/images/Brain_bulb.svg";
import Three from "../../assets/images/03.svg";
import Four from "../../assets/images/04.svg";
import Five from "../../assets/images/05.svg";
import Six from "../../assets/images/06.svg";
import Half_brain from "../../assets/images/half_brain.svg";
import Setting from "../../assets/images/setting.svg";
import Full_brain from "../../assets/images/full_brain.svg";
import Bicep from "../../assets/images/bicep.svg";
import running from "../../assets/images/running.svg";
import heart from "../../assets/images/heart.svg";
import Slim from "../../assets/images/hands.svg";
import heartBeat from "../../assets/images/HeartBeat.svg";
import hand from "../../assets/images/head_image.svg";
import "./powerOfgoodsleep.css";

const PowerOfGoodSleepComponent = () => {
  const [activeTab, setActiveTab] = useState("brain");
  const PowerCardDataTwo = [
    {
      id: 4,
      Image: Full_brain,
      number: Four,
      altText1: "Full_brain",
      altText2: "four_image",
      content: "Reduced stress levels & improved stress management",
    },
    {
      id: 5,
      Image: Setting,
      number: Five,
      altText1: "Setting",
      altText2: "five_image",
      content: "Enhanced mood & emotional well-being",
    },
    {
      id: 6,
      Image: Half_brain,
      number: Six,
      altText1: "Half_brain",
      altText2: "six_image",
      content: "Increased creativity & problem-solving skills",
    },
  ];
  const PowerCardData = [
    {
      id: 1,
      Image: Brain,
      number: one,
      altText1: "Brain_image",
      altText2: "One_image",
      content: "Enhanced cognitive function & improved concentration",
    },
    {
      id: 2,
      Image: Human_graph,
      number: Two,
      altText1: "Human_graph",
      altText2: "two_image",
      content: "Increased productivity & better decision-making abilities",
    },
    {
      id: 3,
      Image: brain_bulb,
      number: Three,
      altText1: "brain_bulb",
      altText2: "three_image",
      content: "Improved memory retention & learning capabilities",
    },
  ];

  const PowerBodyData = [
    {
      id: 1,
      Image: Bicep,
      number: one,
      altText1: "Bicep_image",
      altText2: "One_image",
      content: "Strengthened immune system & reduced risk of illnesses",
    },
    {
      id: 2,
      Image: running,
      number: Two,
      altText1: "running",
      altText2: "two_image",
      content: "Improved physical performance & athletic abilities",
    },
    {
      id: 3,
      Image: heart,
      number: Three,
      altText1: "heart",
      altText2: "three_image",
      content: "Better cardiovascular health & reduced risk of heart disease",
    },
  ];

  const PowerBodyDataTwo = [
    {
      id: 4,
      Image: Slim,
      number: Four,
      altText1: "Slim",
      altText2: "four_image",
      content: "Improved metabolism & weight management",
    },
    {
      id: 5,
      Image: heartBeat,
      number: Five,
      altText1: "heartBeat",
      altText2: "five_image",
      content: "Reduced inflammation & improved overall health",
    },
    {
      id: 5,
      Image: hand,
      number: Six,
      altText1: "hand",
      altText2: "six_image",
      content: "Increased longevity & improved quality of life",
    },
  ];

  const PowerCard = ({ Image, number, content, altText1, altText2 }: any) => {
    return (
      <div className="mx-md-2 pb-2 pb-md-0">
        <div className="Power_card">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-md-4 pb-3">
            <div>
              <img src={Image} alt={altText1} className="power_image_1" />
            </div>
            <div>
              <img src={number} alt={altText2} className="power_image_2" />
            </div>
          </div>
          <div className="px-md-3 pt-3 pt-md-0 card-body">
            <div>
              <h5 className="power_text">{content}</h5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };
  return (
    <>
    <div className="power_component py-md-5 mt-md-5 position-relative d-none d-md-block">
        {/* <div className="upper-ellipse"></div>
        <div className="bottom-ellipse"></div> */}
        <Container className="">
          <div className="d-flex justify-content-between py-md-5">
            <div>
              <h1 className="power_heading">The Power of Good Sleep</h1>
            </div>
            <div>
              <div className="bg-white d-flex align-items-center justify-content-around rounded-pill px-md-2 py-md-2">
                <div
                  className={`tab ${activeTab === "brain" ? "active" : ""}`}
                  onClick={() => handleTabClick("brain")}
                >
                  Brain supercharger
                </div>
                <div
                  className={`tab  ${activeTab === "body" ? "active" : ""}`}
                  onClick={() => handleTabClick("body")}
                >
                  Body supercharger
                </div>
              </div>
            </div>
          </div>
          <div className="py-md-5">
            {activeTab === "brain" && (
              <>
                <div className="mt-md-3 d-md-flex justify-content-md-around align-items-md-center">
                  {PowerCardData.map((data: any) => {
                    return (
                      <PowerCard
                        Image={data.Image}
                        number={data.number}
                        altText1={data.altText1}
                        altText2={data.altText2}
                        content={data.content}
                      />
                    );
                  })}
                </div>
                <div className="mt-md-4 d-md-flex justify-content-md-around align-items-md-center pb-md-5">
                  {PowerCardDataTwo.map((data: any) => {
                    return (
                      <PowerCard
                        Image={data.Image}
                        number={data.number}
                        altText1={data.altText1}
                        altText2={data.altText2}
                        content={data.content}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {activeTab === "body" && (
              <>
                <div className="mt-md-3 d-md-flex justify-content-md-around align-items-md-center">
                  {PowerBodyData.map((data: any) => {
                    return (
                      <PowerCard
                        Image={data.Image}
                        number={data.number}
                        altText1={data.altText1}
                        altText2={data.altText2}
                        content={data.content}
                      />
                    );
                  })}
                </div>
                <div className="mt-md-4 d-md-flex justify-content-md-around align-items-md-center pb-md-5">
                  {PowerBodyDataTwo.map((data: any) => {
                    return (
                      <PowerCard
                        Image={data.Image}
                        number={data.number}
                        altText1={data.altText1}
                        altText2={data.altText2}
                        content={data.content}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </Container>
        </div>
      <Container fluid className="power_component py-5 d-block d-sm-none">
        {/* <div className="upper-ellipse"></div>
        <div className="bottom-ellipse"></div> */}
        <div className="pb-5">
          <h4 className="power_heading">The Power of Good Sleep</h4>
        </div>
        <div>
          <div className="bg-white d-flex align-items-center rounded-pill px-2 py-2">
            <div
              className={`tab fs-14 ${activeTab === "brain" ? "active" : ""}`}
              onClick={() => handleTabClick("brain")}
            ><div className="tab_sm">
              Brain supercharger
              </div>
            </div>
            <div
              className={`tab fs-14  ${activeTab === "body" ? "active" : ""}`}
              onClick={() => handleTabClick("body")}
            >
              <div className="tab_sm"> Body supercharger</div>
             
            </div>
          </div>
        </div>
        <div className="mt-4">
          {activeTab === "brain" && (
            <div className="d-flex align-items-center justify-content-center">
              <div className="pe-2">
                {PowerCardData.map((data: any) => {
                  return (
                    <PowerCard
                      Image={data.Image}
                      number={data.number}
                      altText1={data.altText1}
                      altText2={data.altText2}
                      content={data.content}
                    />
                  );
                })}
              </div>
              <div>
                {PowerCardDataTwo.map((data: any) => {
                  return (
                    <PowerCard
                      Image={data.Image}
                      number={data.number}
                      altText1={data.altText1}
                      altText2={data.altText2}
                      content={data.content}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {activeTab === "body" && (
            <div className="d-flex align-items-center justify-content-center">
              <div className="pe-2">
                {PowerBodyData.map((data: any) => {
                  return (
                    <PowerCard
                      Image={data.Image}
                      number={data.number}
                      altText1={data.altText1}
                      altText2={data.altText2}
                      content={data.content}
                    />
                  );
                })}
              </div>
              <div>
                {PowerBodyDataTwo.map((data: any) => {
                  return (
                    <PowerCard
                      Image={data.Image}
                      number={data.number}
                      altText1={data.altText1}
                      altText2={data.altText2}
                      content={data.content}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default PowerOfGoodSleepComponent;
