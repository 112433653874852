import { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const DoctorDashboard = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { token, isAuthenticated, userId } = useContext(AuthContext);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const filterResult = patientsList.filter((e: any) =>
    e.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(true);
    if (isAuthenticated) {
      axiosPrivate({
        method: "POST",
        url: "/patientlist",
        data: {},
      })
        .then((response: any) => {
          if (response.status === 200) {
            setPatientsList(response.data.data);
            setShowLoading(false);
          }
        })
        .catch((err: any) => {
          return;
          setShowLoading(false);
        });
    }
  }, [isAuthenticated, token]);

  const columns = [
    {
      name: "Sr No",
      cell: (row: any, index: any) => {
        const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
        return serialNumber;
      },
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Registered Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Actions",
      grow: 3,
      cell: (row: any) => (
        <>
          {row.sleep_profile_data === 1 && (
            <div
              className="viewresult_css me-2 fs-12 rounded-1"
              onClick={() => Navigate(`/viewData/${row.id}`)}
              style={{ cursor: "pointer" }}
            >
              Sleep&nbsp;Profile&nbsp;Data
            </div>
          )}

          <div
            className="viewdata_css fs-12 rounded-1"
            onClick={() => Navigate(`/sleepData/${row.id}`)}
            style={{ cursor: "pointer" }}
          >
            Wearable&nbsp;Data
          </div>
          <div
            className="btn-grid border_radius fs-12 ms-2"
            onClick={() => Navigate(`/doctorsPatientList/${row.id}`)}
            style={{ cursor: "pointer" }}
          >
            Patient&nbsp;Record
          </div>
          {/* <div
            className="bg-light_purple border_radius fs-12 ms-2 p-2"
            onClick={() => handleShow(row)}
            style={{ cursor: "pointer" }}
          >
            Create Appointment
          </div> */}
        </>
      ),
    },
  ];
  const totalRows = patientsList.length;
  const handleChangePage = (newPage: any) => {
    setCurrentPage(newPage);
  };
  let itemsPerPage = 10;

  return (
    <>
      {isShowLoading && <Spinner />}
      {/* <CreatePatientAppointmentModal /> */}
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height mt-md-3">
          <div style={{ marginTop: "85px" }}>
            <div>
              <h4 className="">Patients</h4>
            </div>
            <div className="Custom_card px-md-4 py-md-3">
              <div className="d-flex align-items-center justify-content-end my-md-1 mb-md-3">
                <div className="px-md-3 py-md-2 border border-purple rounded d-flex align-items-center">
                  <input
                    type="text"
                    className="w-100 border-0"
                    placeholder="Search by name"
                    style={{ outline: "none" }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <i className="bi bi-search" style={{ color: "#6f4bff" }}></i>
                </div>
              </div>
              <div className="shadow">
                <DataTable
                  pagination
                  paginationTotalRows={totalRows}
                  onChangePage={handleChangePage}
                  paginationRowsPerPageOptions={[10]}
                  paginationPerPage={10}
                  columns={columns}
                  data={filterResult}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none">
        <div className=" mt-5 pt-5 d-flex align-items-center justify-content-between">
          <div>
            <h6>Patient</h6>
          </div>
          <div>
            <div className="px-3 py-2 border border-purple rounded d-flex align-items-center">
              <input
                type="text"
                className="w-100 border-0"
                placeholder="Search by name"
                style={{ outline: "none" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <i className="bi bi-search" style={{ color: "#6f4bff" }}></i>
            </div>
          </div>
        </div>
        {filterResult.map((patient: any, index: string) => {
          const { id } = patient;
          return (
            <Card className="my-3 border-light_purple border">
              <div className={`px-3 py-1`}>
                <div className="d-flex align-items-center justify-content-between mt-2 p-0 m-0">
                  <p className="text-uppercase fw-medium fs-14">
                    {patient.name}
                  </p>
                  {/* <p className="mx-2">|</p> */}
                  <p className="text-uppercase fw-medium fs-12">
                    {patient.date}
                  </p>
                </div>
                <div className="m-0 p-0">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-envelope"></i>
                    <p className="my-0 ms-2 text-dark"> {patient.email}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-around my-2">
                  {patient.sleep_profile_data === 1 && (
                    <button
                      className="btn btn-outline-purple"
                      onClick={() => Navigate(`/viewData/${id}`)}
                    >
                      Sleep&nbsp;Profile&nbsp;Data
                    </button>
                  )}
                  <button
                    className="btn btn-outline-purple"
                    onClick={() => Navigate(`/sleepData/${id}`)}
                  >
                    Wearable&nbsp;Data
                  </button>
                </div>
                <div className="d-flex justify-content-around my-3">
                  <button
                    className="btn btn-outline-purple"
                    onClick={() => Navigate(`/doctorsPatientList/${id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    Patient&nbsp;Record
                  </button>
                </div>
              </div>
            </Card>
          );
        })}
      </Container>
    </>
  );
};

export default DoctorDashboard;
