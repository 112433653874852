import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AuthContext from "../context/authContext1";
import axios from "../utils/axios";
import Spinner from "../utils/Spinner";
import { ToastContainer, toast } from "react-toastify";

const RoutineBuilder = ({ show, handleClose }: any) => {
  const { token, userRole, isAuthenticated } = useContext(AuthContext);
  const toastId = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatus, setIsStatus] = useState<boolean>();
  const [isMessage, setIsMessage] = useState();
  const [bedTimes, setBedTimes] = useState<any>([]);
  const [bedTimeResponse, setBedTimeResponse] = useState<any>({});
  const [bedTimeNotificationResponse, setBedTimeNotificationResponse] =
    useState<any>({});
  const [notificationKeys, setNotificationKeys] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>({});

  const [isBedTimeApiSuccess, setIsBedTimeApiSuccess] =
    useState<boolean>(false);
  const [isNotificationApiSuccess, setIsNotificationApiSuccess] =
    useState<boolean>(false);

  const apiBedtimeSuccessHandler = useCallback(() => {
    setIsBedTimeApiSuccess(!isBedTimeApiSuccess);
  }, [isBedTimeApiSuccess]);

  const apiNotificationSuccessHandler = useCallback(() => {
    setIsNotificationApiSuccess(!isNotificationApiSuccess);
  }, [isNotificationApiSuccess]);

  async function fetchBedTimeAndNotificationList() {
    await axios
      .get("getBedTime", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { status, data, responseFormat } = response.data;
        if (status) {
          setBedTimes(data);
          setBedTimeResponse(responseFormat);
        }
        return axios
          .get("getBedTimeNotification", {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const { status, data } = response.data;
            if (status) {
              setNotificationKeys(Object.keys(data));
              setNotifications(data);

              let keyValuePairs: any = {};

              Object.values(data).map((notification: any, index) => {
                keyValuePairs[notification?.key] = notification?.value;
              });

              setBedTimeNotificationResponse(keyValuePairs);
            }
          })
          .catch((err) => {
            return;
          });
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (userRole === "patient" && isAuthenticated) {
      fetchBedTimeAndNotificationList();
    }
  }, []);

  const handleTimeChange = (event: any, day: string) => {
    const time = event.target?.value;
    const convertedTime = time?.split("T")[1];
    const updatedBedTimeResponse = Object.assign({}, bedTimeResponse, {
      [`${day}`]: convertedTime ? convertedTime : time,
    });
    setBedTimeResponse(updatedBedTimeResponse);
  };

  const submitBedTimeHandler = () => {
    setIsLoading(true);
    toastId.current = toast.loading("Loading...");
    axios
      .post("bedTime", bedTimeResponse, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { status } = response.data;
        setIsStatus(status);
        if (status) {
          toast.update(toastId.current, {
            render: "Your BedTime Created Successfully",
            type: "success",
            isLoading: false,
          });
          setIsMessage(response.data.message);
          // setIsToastDisplay(true);
          apiBedtimeSuccessHandler();
          handleClose();
          setTimeout(() => {
            // setIsToastDisplay(false);
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bedTimeNotificationHandler = (
    event: any,
    notificationOrder: string
  ) => {
    const isChecked = event.target.checked;
    const result = isChecked === true ? 1 : 0;

    const updatedBedTimeNotifications = Object.assign(
      {},
      bedTimeNotificationResponse,
      {
        [`${notificationOrder}`]: result,
      }
    );

    setBedTimeNotificationResponse(updatedBedTimeNotifications);
  };

  const submitBedTimeNotificationHandler = () => {
    setIsLoading(true);
    toastId.current = toast.loading("Loading...");
    axios
      .post("bedTimeNotification", bedTimeNotificationResponse, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { status } = response.data;
        setIsStatus(status);
        if (status) {
          toast.update(toastId.current, {
            render: "User BedTime Notification Created Successfully",
            type: "success",
            isLoading: false,
          });
          setIsMessage(response.data.message);
          apiNotificationSuccessHandler();
          handleClose();
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  let keys = Object.keys(bedTimeNotificationResponse);

  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        fullscreen="md-down"
      >
        <Modal.Header className="bg-purple text-white fw-600" closeButton>
          Routine Builder
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="targetbedtime"
            id="uncontrolled-tab-example"
            className="mb-3 event-nav-tabs"
          >
            <Tab eventKey="targetbedtime" title="TARGET BED TIME">
              <div>
                {bedTimes.map((bedTime: any, index: number) => {
                  const time = bedTimeResponse[`${bedTime.day}`];
                  // const showMessage =
                  //   bedTimeResponse[`${bedTime.day}`] == null ? true : false;

                  return (
                    <div className="d-flex justify-content-between py-md-1">
                      <div className="d-flex my-2 my-md-0">
                        <div>
                          <p className="fs-14 fw-600"> {bedTime.day}</p>
                        </div>
                      </div>
                      <div>
                        <input
                          style={{ outline: "none", width: "130px" }}
                          type="time"
                          id={`time-${index}`}
                          value={time}
                          onChange={(event: any) =>
                            handleTimeChange(event, bedTime.day)
                          }
                          className="border-purple btn text-purple"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-center py-md-2">
                <button
                  className="btn bg-purple  text-white"
                  onClick={submitBedTimeHandler}
                >
                  Submit
                </button>
              </div>
            </Tab>

            <Tab eventKey="notification" title="NOTIFICATION">
              {notificationKeys?.map((notification: any, id: string) => {
                const currentKey = notifications[`${notification}`].key;
                const currentValue =
                  bedTimeNotificationResponse[`${currentKey}`];

                return (
                  <div className="px-md-3 p-3 my-3 my-md-0 p-md-0" style={{ background: "#F4F1FF" }}>
                    <div
                      className="d-flex justify-content-between align-items-center py-md-3 my-md-3 "
                      key={id}
                    >
                      <div>
                        <p className="p-0 m-0">{notification}</p>
                      </div>
                      <div>
                        <Form.Switch
                          id="bedTimeNotificationToggle"
                          defaultChecked={currentValue === 1}
                          onChange={(event) =>
                            bedTimeNotificationHandler(event, `${currentKey}`)
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="d-flex justify-content-center mt-md-3">
                <button
                  className="btn btn-purple text-white"
                  onClick={submitBedTimeNotificationHandler}
                >
                  Submit
                </button>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoutineBuilder;
