import React, { useState, useEffect, useContext } from "react";
import Card from "react-bootstrap/Card";
import Success from "../../src/assets/images/success.svg";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import useAxiosPrivate from "../utils/useAxiosPrivate";
import AuthContext from "../context/authContext1";

const StripeSuccess = () => {
  const axiosPrivate = useAxiosPrivate();
  const [countdown, setCountdown] = useState(5);
  const [isPaymentStatus, setIsPaymentStatus] = useState();
  const authCtx = useContext(AuthContext);

  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {}, []);
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);

      return () => clearTimeout(timer);
    } else {
      axiosPrivate({
        method: "POST",
        url: "/paymentUpdate",
        data: {
          paymentStatus: "1",
        },
      })
        .then((res) => {
          setIsPaymentStatus(res.data.status);
          const status = res.data.status;
          if (res.data.status) {
            authCtx.subscriptionFlowNotRequiredHandler(!status);
            Navigate("/dashboard");
          }
        })
        .catch((err) => {
          return;
        });
    }
  }, [countdown]);
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="position-relative">
        <Card
          style={{ width: "45rem" }}
          className="position-absolute top-50 start-50 translate-middle pxs-md-5 "
        >
          <Card.Body>
            <Card.Title className="text-center">Payment Success</Card.Title>
            <Card.Text>
              <div className="text-center">
                <img src={Success} alt="Success" height="250px" />
              </div>
              <h3 className="text-purple text-center">
                The Page will redirect in{" "}
                <span className="fw-600">{countdown}</span> Seconds
              </h3>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default StripeSuccess;
