import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthContext from "../../../context/authContext1";
import QuestionarieContext from "../../../context/questionaireContext";
import {
  RadioFieldGroup,
  CheckBoxFieldGroup,
} from "../../../utils/Fromcontrol";
import Stepper from "./Stepper";
import Spinner from "../../../utils/Spinner";
import { Container } from "react-bootstrap";
import useAxiosPrivate from "../../../utils/useAxiosPrivate";

interface Values {
  1: string | null;
  2: string | null;
  3: string | null;
  4: string | null;
  5: string | null;
  6: string | null;
  7: string | null;
  8: string | null;
  9: string | null;
  10: string | null;
  49: string | null;
  50: string | null;
  51: string | null;
  52: string | null;
  53: string | null;
  54: string | null;
  55: string | null;
  56: string | null;
  57: string | null;
  58: string | null;
  59: string | null;
  60: string | null;
  61: string | null;
  62: string | null;
  63: string | null;
  64: string | null;
}

const QuestionariePart1: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();
  const [initialValues, setInitialValues] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, token } = useContext(AuthContext);

  const { activeStepHandler } = useContext(QuestionarieContext);

  const [questions, setQuestions] = useState([]);

  const [isQuestionFetching, setIsQuestionFetching] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validationSchema = Yup.object({
    1: Yup.string().required("A radio option is required").nullable(),
    2: Yup.string().required("A radio option is required").nullable(),
    3: Yup.string().required("A radio option is required").nullable(),
    4: Yup.string().required("A radio option is required").nullable(),
    5: Yup.string().required("A radio option is required").nullable(),
    6: Yup.string().required("A radio option is required").nullable(),
    7: Yup.string().required("A radio option is required").nullable(),
    8: Yup.string().required("A radio option is required").nullable(),
    9: Yup.string().required("A radio option is required").nullable(),
    10: Yup.string().required("A radio option is required").nullable(),
    49: Yup.string()
      .when("5", {
        is: (val: string) => val === "25",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    50: Yup.string()
      .when("5", {
        is: (val: string) => val === "25",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    51: Yup.string()
      .when("5", {
        is: (val: string) => val === "26",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    52: Yup.string()
      .when("5", {
        is: (val: string) => val === "27",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    53: Yup.string()
      .when("5", {
        is: (val: string) => val === "28",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    54: Yup.string()
      .when("5", {
        is: (val: string) => val === "29",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    55: Yup.string()
      .when("5", {
        is: (val: string) => val === "30",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    56: Yup.string()
      .when("5", {
        is: (val: string) => val === "31",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    57: Yup.string()
      .when("8", {
        is: (val: string) => val === "46",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    58: Yup.string()
      .when("8", {
        is: (val: string) => val === "46",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    59: Yup.string()
      .when("8", {
        is: (val: string) => val === "47",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    60: Yup.string()
      .when("8", {
        is: (val: string) => val === "48",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    61: Yup.string()
      .when("8", {
        is: (val: string) => val === "49",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    62: Yup.string()
      .when("8", {
        is: (val: string) => val === "50",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    63: Yup.string()
      .when("8", {
        is: (val: string) => val === "51",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    64: Yup.string()
      .when("8", {
        is: (val: string) => val === "52",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
  });

  let actualInitialValues: any = {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null,
    49: null,
    50: null,
    51: null,
    52: null,
    53: null,
    54: null,
    55: null,
    56: null,
    57: null,
    58: null,
    59: null,
    60: null,
    61: null,
    62: null,
    63: null,
    64: null,
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      setIsQuestionFetching(true);
      axiosPrivate({
        method: "POST",
        url: "/questionaire",
        data: { part: 1 },
      })
        .then((res) => {
          const { isCompleted, data } = res.data;
          if (isCompleted) {
            const keyValueArray = data.map((question: any) => {
              if (question?.question_type === "radio") {
                return [
                  question?.id,
                  question?.isSelectedOption,
                  question?.question_type,
                ];
              } else {
                const convertedSelectedOption = question?.isSelectedOption.map(
                  (option: number) => option.toString()
                );
                return [
                  question?.id,
                  convertedSelectedOption,
                  question?.question_type,
                ];
              }
            });

            keyValueArray.forEach((keyValuePair: any) => {
              const key = keyValuePair[0];
              const value = keyValuePair[1];
              const questionType = keyValuePair[2];
              if (questionType === "radio") {
                actualInitialValues[key] =
                  value === null ? null : value.toString();
              } else {
                actualInitialValues[key] = value === null ? [] : value;
              }
            });

            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          } else {
            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          }
          // setQuestions(
          //   res.data.data.filter((question: any) => question?.options)
          // );
        })
        .catch((err) => {
          return;
        })
        .finally(() => {
          setIsQuestionFetching(false);
          setIsLoading(false);
        });
    }
  }, [isAuthenticated, token]);

  const submitHandler = (values: Values) => {
    setIsSubmitting(true);
    axiosPrivate({
      method: "POST",
      url: "/submitQuestionaire",
      data: { questions: values, part: 1 },
    })
      .then((res) => {
        if (res.status === 200) {
          activeStepHandler();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      {isLoading && <Spinner />}
      {isSubmitting && <Spinner />}
      <Container>
        <div>
          <div className="my-4 d-flex justify-content-center">
            <div className="w-75">
              <Stepper />
            </div>
          </div>
          <h6 className="my-2">Questionarie 1</h6>
          <Formik
            initialValues={initialValues || actualInitialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            enableReinitialize={true}
          >
            {(formikProps) => {
              return (
                <Form>
                  {!isQuestionFetching && (
                    <div>
                      {questions.map((question: any) => {
                        if (question.question_type === "radio") {
                          return (
                            <RadioFieldGroup
                              key={question.id}
                              questionId={question.question_id}
                              optionId={question.option_id}
                              question={question.name}
                              questionSequence={question.question_sequence}
                              name={question.id}
                              options={question.options}
                              values={formikProps.values}
                              actualQuestionId={question.id}
                            />
                          );
                        } else if (question.question_type === "checkbox") {
                          return (
                            <CheckBoxFieldGroup
                              key={question.id}
                              questionId={question.question_id}
                              optionId={question.option_id}
                              question={question.name}
                              questionSequence={question.question_sequence}
                              name={question.id}
                              options={question.options}
                              values={formikProps.values}
                              actualQuestionId={question.id}
                            />
                          );
                        }
                      })}

                      <div className="text-center my-3">
                        <button
                          type="submit"
                          className="btn btn-purple rounded-1 px-md-5 px-5"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default QuestionariePart1;
