import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Spinner from "../../utils/Spinner";

const SubscriptionPlans = ({ setShowLoading }: any) => {
  const axiosPrivate = useAxiosPrivate();
  // const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isSubscriptionsPlans, setIsSubscriptionsPlans] = useState<any>();

  useEffect(() => {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/stripeSubscriptions",
      data: {},
    })
      .then((response) => {
        const { data, status } = response;
        if (status) {
          setIsSubscriptionsPlans(data.data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  }, []);

  const handleStripedata = (data: any) => {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/stripePaymentUrl",
      data: {
        productId: data.id,
        priceId: data.default_price,
      },
    })
      .then((res) => {
        if (res.data.code === "200") {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <>
      {/* <StripModal /> */}
      {/* {showLoading && <Spinner />} */}
      <Container className="px-3 px-xl-5 pb-md-5 d-none d-md-block d-flex aling-items-center justify-content-center">
        <div className="d-flex justify-content-around align-items-center">
          {isSubscriptionsPlans?.map((data: any) => {
            const ProductPrice = data.default_price;
            const cardStatus = data.active;
            return (
              cardStatus && (
                <div
                  className="card h-100 px-4 py-5"
                  style={{ width: "25rem" }}
                >
                  <h5>{data.name}</h5>
                  <h3>${data.price.unit_amount.toString().slice(0, -2)}</h3>

                  <div>
                    <button
                      className="btn btn-outline-purple w-100 mt-1"
                      // onClick={() => setProductId(ProductPrice)}
                      onClick={() => handleStripedata(data)}
                    >
                      Proceed to pay
                    </button>
                    <p className="font-weight-bold mt-4">Starter features:</p>
                    <div className="d-flex flex-column align-content-between pb-md-3 ">
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="d-felx">
                          <div className="ml-2">
                            <p className="mb-0">
                              <i className="bi bi-exclamation-circle me-2"></i>
                              Engage in virtual consulation with a dedication
                              sleep coach every 4 week
                            </p>
                          </div>
                        </div>

                        <div className="ml-auto text-success">
                          <i className="bi bi-check2"></i>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-2">
                          <p className="mb-0">
                            <i className="bi bi-exclamation-circle me-2"></i>
                            Seamlessly sync your wearable device to monitor and
                            analyse your sleep patterns.
                          </p>
                        </div>
                        <div className="ml-auto text-success">
                          <i className="bi bi-check2"></i>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-2">
                          <p className="mb-0">
                            <i className="bi bi-exclamation-circle me-2"></i>
                            Collaborate with your sleep coach to device a
                            tailored plan suited to your specific needs.
                          </p>
                        </div>
                        <div className="ml-auto text-success">
                          <i className="bi bi-check2"></i>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-2">
                          <p className="mb-0">
                            <i className="bi bi-exclamation-circle me-2"></i>
                            Receive timely email and sms remainders to
                            fecilitate adherence to your sleep goals.
                          </p>
                        </div>
                        <div className="ml-auto text-success">
                          <i className="bi bi-check2"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </Container>
      <Container className="d-block d-md-none">
        <div className="d-flex justify-content-around align-items-center">
          {isSubscriptionsPlans?.map((data: any) => {
            const ProductPrice = data.default_price;
            const cardStatus = data.active;
            return (
              cardStatus && (
                <div className="card h-100 p-3">
                  <h5>{data.name}</h5>
                  <h3>${data.price.unit_amount.toString().slice(0, -2)}</h3>

                  <div>
                    <button
                      className="btn btn-outline-purple w-100 mt-1"
                      // onClick={() => setProductId(ProductPrice)}
                      onClick={() => handleStripedata(data)}
                    >
                      Proceed to pay
                    </button>
                    <p className="font-weight-bold mt-4">Starter features:</p>
                    <div className="d-flex flex-column align-content-between pb-3 ">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-felx">
                          <div className="ml-2">
                            <p className="mb-0">
                              <i className="bi bi-exclamation-circle me-2"></i>
                              Engage in virtual consulation with a dedication
                              sleep coach every 4 week
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="ml-2">
                          <p className="mb-0">
                            <i className="bi bi-exclamation-circle me-2"></i>
                            Seamlessly sync your wearable device to monitor and
                            analyse your sleep patterns.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="ml-2">
                          <p className="mb-0">
                            <i className="bi bi-exclamation-circle me-2"></i>
                            Collaborate with your sleep coach to device a
                            tailored plan suited to your specific needs.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="ml-2">
                          <p className="mb-0">
                            <i className="bi bi-exclamation-circle me-2"></i>
                            Receive timely email and sms remainders to
                            fecilitate adherence to your sleep goals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default SubscriptionPlans;
