import React from "react";
import SleepMeditation_1 from "../../assets/images/SleepMeditation_1.png";
import SleepMeditation from "../../assets/images/SleepMeditation.png";
import { Col, Row } from "react-bootstrap";
const GuidedMeditationComponent = () => {
  return (
    <>
      <div className="mt-md-5 text-center d-none d-md-block">
        <Row>
          <Col>
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Yoga+Nidra+Meditaiton.mp4"
              poster={SleepMeditation_1}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col>
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Sleep_Meditation.mp4"
              poster={SleepMeditation}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
      <div className="mt-5 text-center d-block d-md-none">
        <Row>
          <Col className="col-12">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Yoga+Nidra+Meditaiton.mp4"
              poster={SleepMeditation_1}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="col-12 my-3">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Sleep_Meditation.mp4"
              poster={SleepMeditation}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GuidedMeditationComponent;
