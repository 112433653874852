import React, { useState, useContext } from "react";
import AuthContext from "../../context/authContext1";
import { Container, Form } from "react-bootstrap";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Card from "react-bootstrap/Card";

const DevicePlan = ({ setShowLoading }: any) => {
  const { updateSubscriptionFlowStep } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  // const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isAlreadyHaveAnWearableDevice, setIsAlreadyHaveAnWearableDevice] =
    useState<boolean>(false);

  const [isPurchaseFitbit, setIsPurchaseFitbit] = useState<boolean>(true);

  const devicePlanSubmitHandler = () => {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/fitbitDevice",
      data: {
        isDevice: isAlreadyHaveAnWearableDevice ? "Yes" : "No",
        isPurchaseDevice: isPurchaseFitbit ? "Yes" : "No",
      },
    })
      .then((response) => {
        if (response.data.status) {
          updateSubscriptionFlowStep("termsandconditions");
        }
      })
      .catch((error: any) => {
        return;
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <>
      {/* {showLoading && <Spinner />} */}
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="d-flex align-items-center justify-content-center my-md-4">
          <div className="first_card px-md-5 py-md-4 ">
            <div>
              <Form.Group className="d-flex mt-md-3">
                <Form.Label className="me-md-3 fw-600">
                  Do you already have a wearable device?
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Yes"
                  checked={isAlreadyHaveAnWearableDevice === true}
                  onChange={() => setIsAlreadyHaveAnWearableDevice(true)}
                  className="me-md-3"
                />

                <Form.Check
                  type="radio"
                  label="No"
                  checked={isAlreadyHaveAnWearableDevice === false}
                  onChange={() => setIsAlreadyHaveAnWearableDevice(false)}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="d-flex my-md-3">
                <Form.Label className="me-md-3 fw-600">
                  Purchasing a Fitbit with us :
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Yes"
                  checked={isPurchaseFitbit === true}
                  onChange={() => setIsPurchaseFitbit(true)}
                  className="me-md-3"
                />
                <Form.Check
                  type="radio"
                  label="No"
                  checked={isPurchaseFitbit === false}
                  onChange={() => setIsPurchaseFitbit(false)}
                />
              </Form.Group>
            </div>
            <div>
              <h6 className="my-md-3 text-purple text-center">
                A Fitbit Charges will be mailed to you at a cost of $50
              </h6>
            </div>
            <div className="text-center ">
              <button
                onClick={devicePlanSubmitHandler}
                className="btn btn-purple px-md-5 mt-md-3"
              >
                Next
              </button>
            </div>
            <div className=" text-center my-md-3">
              <span className="fs-14">
                If you selected Yes price $50 will be added on payment page
              </span>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none">
        <Card className="p-3">
          <div>
            <div>
              <Form.Group className="d-flex flex-column mt-3">
                <Form.Label className="me-md-3 fw-600">
                  Do you already have a wearable device?
                </Form.Label>
                <div className="d-flex">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    checked={isAlreadyHaveAnWearableDevice === true}
                    onChange={() => setIsAlreadyHaveAnWearableDevice(true)}
                    className="me-md-3"
                  />

                  <Form.Check
                    className="ms-2"
                    type="radio"
                    label="No"
                    checked={isAlreadyHaveAnWearableDevice === false}
                    onChange={() => setIsAlreadyHaveAnWearableDevice(false)}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="my-3">
              <Form.Group className="d-flex flex-column my-md-3">
                <Form.Label className="me-md-3 fw-600">
                  Purchasing a Fitbit with us :
                </Form.Label>
                <div className="d-flex">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    checked={isPurchaseFitbit === true}
                    onChange={() => setIsPurchaseFitbit(true)}
                    className="me-md-3"
                  />
                  <Form.Check
                    type="radio"
                    className="ms-2"
                    label="No"
                    checked={isPurchaseFitbit === false}
                    onChange={() => setIsPurchaseFitbit(false)}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="my-3">
              <h6 className="text-purple text-center">
                A Fitbit Charges will be mailed to you at a cost of $50
              </h6>
            </div>
            <div className="text-center">
              <button
                onClick={devicePlanSubmitHandler}
                className="btn btn-purple px-5 mt-3"
              >
                Next
              </button>
            </div>
            <div className=" text-center my-3">
              <span className="fs-14 text-purple fw-bold">
                If you selected Yes price $50 will be added on payment page
              </span>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default DevicePlan;
