import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/authContext1";
import { ProfileContextProvider } from "./context/profileContext";
import { QuestionarieContextProvider } from "./context/questionaireContext";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

let inactivityTimer: any;
function resetInactivityTimer() {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    localStorage.clear();
  }, 20 * 60 * 1000);
}

document.addEventListener("mousemove", resetInactivityTimer);
document.addEventListener("keydown", resetInactivityTimer);
document.addEventListener("click", resetInactivityTimer);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ProfileContextProvider>
        <QuestionarieContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QuestionarieContextProvider>
      </ProfileContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

reportWebVitals();
