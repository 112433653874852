import Header from "../components/Header";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  return (
    <>
      <div>
        <div>
          <Header />
        </div>

        <div className="desktop_header_height">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
