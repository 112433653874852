import React, { useContext } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import QuestionarieContext from "../../../context/questionaireContext";

const Stepper: React.FC = () => {
  const { activeStepIndex }: any = useContext(QuestionarieContext);
  const now = +(((activeStepIndex - 1) / 6) * 100).toFixed(0);
  return <ProgressBar now={now} label={`${now}%`} />;
};

export default React.memo(Stepper);
