import React from "react";
import Card from "react-bootstrap/Card";

const StripeFail = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="position-relative">
        <Card className="p-md-5 p-3">
          <Card.Body>
            <Card.Title className="text-center">Payment Failed</Card.Title>
            <Card.Text className="text-center d-flex flex-column justify-content-center align-items-center">
              <div
                className={`bg-danger d-flex justify-content-center align-items-center my-4`}
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "50%",
                }}
              >
                <i className="bi bi-x-lg text-white"></i>
              </div>
              We're sorry, but your payment was unsuccessful. Please check your
              payment details and try again.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default StripeFail;
