import React, { useState } from "react";

type Props = {
  children?: React.ReactNode;
};

type ContextType = {
  activeStepIndex: number;
  setActiveStepIndex: React.Dispatch<React.SetStateAction<number>>;
  activeStepHandler: () => void;
};

const QuestionarieContext = React.createContext<ContextType>({
  activeStepIndex: 1,
  setActiveStepIndex: () => {},
  activeStepHandler: () => {},
});

export const QuestionarieContextProvider: React.FC<Props> = (props) => {
  const [activeStepIndex, setActiveStepIndex] = useState(1);

  const activeStepHandler = () => {
    setActiveStepIndex((prevActiveState) => prevActiveState + 1);
  };

  const contextValue = {
    activeStepIndex,
    setActiveStepIndex,
    activeStepHandler,
  };

  return (
    <QuestionarieContext.Provider value={contextValue}>
      {props.children}
    </QuestionarieContext.Provider>
  );
};

export default QuestionarieContext;
