import { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { slots } from "../../utils/slots";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import { toast, ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Spinner from "../../utils/Spinner";

const localizer = momentLocalizer(moment);

const DoctorSchedule = () => {
  const axiosPrivate = useAxiosPrivate();
  const {
    userId,
    selectedTimezoneId,
    userRole,
    token,
    isAuthenticated,
    timezoneUpdateHandler,
  } = useContext(AuthContext);
  const toastId = useRef<any>(null);
  const [month, setMonth] = useState(
    +moment(new Date()).format("M YYYY").split(" ")[0]
  );
  const [year, setYear] = useState(
    +moment(new Date()).format("M YYYY").split(" ")[1]
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [shiftAvailableFrom, setShiftAvailableFrom] = useState<string>("00:00");
  const [showFromNoOptions, setShowFromNoOptions] = useState(0);
  const [myEventsList, setMyEventsList] = useState<any>();
  const [myevents, setMyEvents] = useState<any>();
  const [showToNoOptions, setShowToNoOptions] = useState(0);
  const [shiftAvailableTo, setShiftAvailableTo] = useState<string>("00:00");
  const [isApiSuccessfull, setIsApiSuccessfull] = useState<boolean>(false);
  const [currentShift, setCurrentShift] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [availableTimezones, setAvailableTimezones] = useState<any[]>([]);
  const [isDoctorSlotMOdal, setIsDoctorSlotModal] = useState<boolean>(false);
  const [isDeleteSlot, setIsDeleteSlot] = useState<any>();
  const [iserrorMessage, setIsErrorMessage] = useState(false);
  const [isShowLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userRole === "doctor") {
      setShowLoading(true);
      axiosPrivate({
        method: "POST",
        url: "/view_doctor_schedule",
        data: {
          user_id: userId,
          month: month,
          year: year,
        },
      })
        .then((response) => {
          const { data } = response.data;
          const convertedData = Object.values(data).map((data: any) =>
            data
              ? {
                  date: data.date,
                  shifts: Object.entries(data.shifts).map(
                    (entry: any) => entry[1]
                  ),
                }
              : []
          );
          setMyEvents(convertedData);
        })
        .catch((err) => {
          return;
        })
        .finally(() => setShowLoading(false));
    }
  }, [isAuthenticated, selectedTimezoneId, month, isApiSuccessfull]);

  useEffect(() => {
    const events = myevents?.flatMap((event: any) => {
      const shifts = Array.isArray(event.shifts)
        ? event.shifts
        : [event.shifts];
      return shifts.map((shift: any) => {
        const start = moment(
          `${event.date} ${shift.available_on}`,
          "YYYY-MM-DD HH:mm"
        ).toDate();
        const end = moment(
          `${event.date} ${shift.available_on}`,
          "YYYY-MM-DD HH:mm"
        ).toDate();

        return {
          title: shift.available_on,
          start,
          end,
          shift: shift.shift,
        };
      });
    });

    setMyEventsList(events);
  }, [myevents]);

  const handleClose = () => {
    setShowModal(false);
    setShiftAvailableFrom("00:00");
    setShiftAvailableTo("00:00");
    setErrorMessage("");
  };

  const handleShow = (event: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(event.start);
    const shiftDate = moment(event.start).format("YYYY-MM-DD");
    const shift = myEventsList?.filter(
      (event: any) => moment(event.start).format("YYYY-MM-DD") === shiftDate
    );
    setCurrentShift(shift.length === 1 ? 2 : 1);

    const isPastDateOrBothShiftsFilled = moment(selectedDate).isBefore(
      moment(currentDate).startOf("day"),
      "day"
    );

    const existingEvents = myEventsList?.filter(
      (e: any) => moment(e.start).format("YYYY-MM-DD") === shiftDate
    );

    if (!isPastDateOrBothShiftsFilled) {
      if (existingEvents.length >= 2) {
        // setIsFullToastDisplay(false);
        toast.info("Shifts are Filled");
      } else {
        setShowModal(true);
        setSelectedEvent(event);
      }
    } else {
      // setIsToastDisplay(true);
      toast.error("Please select Future Date");

      // setTimeout(() => {
      //   setIsToastDisplay(false);
      // }, 1000);
    }
  };

  function CreateSchedule() {
    setShowLoading(true);
    handleClose();
    axiosPrivate({
      method: "POST",
      url: "/create_doctor_schedule",
      data: {
        user_id: userId,
        timezone_id: selectedTimezoneId,
        avaliable_from: shiftAvailableFrom,
        avaliable_to: shiftAvailableTo,
        date: moment(selectedEvent.start).format("YYYY-MM-DD"),
        shift_type: currentShift,
      },
    })
      .then((response) => {
        const Status = response.data.status === "success" ? true : false;
        if (Status) {
          setIsApiSuccessfull(!isApiSuccessfull);
          handleClose();
          toast.success("Time Table Created Successfully");
        } else {
          toast.error(`${response.data.message}`);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  }
  const handleAddEvent = (event: any) => {
    const slotStartTime = moment(shiftAvailableFrom, "HH:mm");
    const slotEndTime = moment(shiftAvailableTo, "HH:mm");
    const diffSlot1 = slotEndTime.diff(slotStartTime, "minutes");
    const isSecondShiftStatus = currentShift === 2 ? true : false;

    if (diffSlot1 < 20) {
      setErrorMessage(
        "Start Time and End Time Difference must be atleast 20 mins."
      );
    } else if (shiftAvailableFrom === null) {
      setErrorMessage("Fill all the required Fields");
    } else if (shiftAvailableTo === null) {
      setErrorMessage("Fill all the required Fields");
    } else if (selectedTimezoneId === null) {
      setErrorMessage("Select Timezone");
    } else if (isSecondShiftStatus) {
      const selectedDate = moment(selectedEvent.start).format("YYYY-MM-DD");

      // Filter myevents based on the selected date
      let filteredEvents = myevents.filter((data: any) => {
        return moment(data.date).format("YYYY-MM-DD") === selectedDate;
      });

      // Process the filtered events
      filteredEvents
        .map((filteredEvent: any) => {
          if (
            filteredEvent &&
            filteredEvent.shifts &&
            filteredEvent.shifts[0]
          ) {
            const available_on = filteredEvent.shifts[0].available_on;

            if (typeof available_on === "string") {
              const [startTime, endTime] = available_on.split(" to ");

              // Compare endTime with shiftAvailableFrom
              const isGreaterThan = moment(endTime, "HH:mm").isSameOrAfter(
                moment(shiftAvailableFrom, "HH:mm")
              );
              if (!isGreaterThan) {
                CreateSchedule();
              } else {
                toast.error(
                  "Shift 2 start time must be greater than Shift 1 End time."
                );
              }
            } else {
              toast.error("Something went worng");
            }
          }

          return null;
        })
        .filter(Boolean);
    } else {
      CreateSchedule();
    }
  };

  const handleNavigate = (newDate: any) => {
    const convertedMonthYear = moment(newDate).format("M YYYY");
    setMonth(+convertedMonthYear.split(" ")[0]);
    setYear(+convertedMonthYear.split(" ")[1]);
  };

  const handleDoctorSlotModalClose = () => setIsDoctorSlotModal(false);

  const DoctorSlotModal = () => {
    return (
      <Modal
        show={isDoctorSlotMOdal}
        onHide={handleDoctorSlotModalClose}
        centered
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="text-center text-purple fw-600">
          {iserrorMessage}
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <button
            className="btn btn-purple px-md-5"
            onClick={handleDeleteDoctor}
          >
            Yes
          </button>
          <button
            className="btn border-purple color-purple px-md-5"
            onClick={handleDoctorSlotModalClose}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleBothEvents = (event: any) => {
    setIsDeleteSlot(event);
    const selectedDate = moment(isDeleteSlot?.start).format("YYYY-MM-DD");
    const selectedShift = isDeleteSlot?.shift;
    axiosPrivate({
      method: "POST",
      url: "/verify_doctor_schedule",
      data: {
        user_id: userId,
        shift: selectedShift,
        date: selectedDate,
        type: 0,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setIsErrorMessage(res.data.message);
          handleEventSelect();
        } else {
          setIsErrorMessage(res.data.message);
          handleEventSelect();
        }
      })
      .catch((err) => {
        return;
      });
  };

  const handleDeleteDoctor = () => {
    const selectedDate = moment(isDeleteSlot?.start).format("YYYY-MM-DD");
    const selectedShift = isDeleteSlot?.shift;
    axiosPrivate({
      method: "POST",
      url: "/delete_doctor_schedule",
      data: {
        user_id: userId,
        shift: selectedShift,
        date: selectedDate,
        type: 1,
      },
    }).then((response) => {
      if (response.status === 200) {
        setIsApiSuccessfull(!isApiSuccessfull);
        setIsDoctorSlotModal(false);
        toast.success("Slot Deleted Successfully ");
      }
    });
  };

  const handleEventSelect = () => {
    setIsDoctorSlotModal(true);
  };

  useEffect(() => {
    if (isAuthenticated && userRole !== "superadmin") {
      axios
        .get("/view_timezones")
        .then((response) => {
          const { data } = response.data;
          setAvailableTimezones(data);
        })
        .catch((err) => {
          return;
        });
    }
  }, [isAuthenticated]);

  const handleSelectedTimezone = (e: any) => {
    axiosPrivate({
      method: "POST",
      url: "/change_timezone",
      data: {
        timezone_id: parseInt(e.currentTarget.value),
      },
    }).then((response) => {
      if (response.status === 200) {
        timezoneUpdateHandler(parseInt(response.data.data));
      }
    });
  };

  return (
    <>
      {isShowLoading && <Spinner />}
      <ToastContainer />
      <DoctorSlotModal />
      <Container fluid className="px-md-5">
        <div className="desktop_header_height mt-xxl-4 mt-md-2">
          <div style={{ marginTop: "85px" }}>
            <div className="my-md-4">
              <h5>Doctor Schedule</h5>
            </div>
            <div className="Custom_card px-md-4 py-md-3 py-xl-2 py-xxl-4">
              <div className="d-flex justify-content-between align-items-center d-none d-md-block">
                <div className="d-flex align-items-center justify-content-end mt-md-3">
                  <div>
                    <Form.Label>Timezone : </Form.Label>
                  </div>
                  <div className="ms-2">
                    <Form.Select
                      aria-label="Select Timezone"
                      className="border-purple"
                      onChange={handleSelectedTimezone}
                      value={selectedTimezoneId}
                    >
                      {availableTimezones.map((timezone) => (
                        <option
                          key={timezone.id}
                          value={timezone.id}
                          selected={
                            timezone.id === selectedTimezoneId ? true : false
                          }
                        >
                          {timezone.name} ({`${timezone.offset}`})
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </div>

              <div className="d-block d-md-none">
                <div>
                  <h6>Doctor Schedule</h6>
                </div>

                <div className="my-2 my-md-0">
                  <Form.Select
                    aria-label="Select Timezone"
                    className="border-purple"
                    onChange={handleSelectedTimezone}
                    // defaultChecked={}
                  >
                    {availableTimezones.map((timezone) => (
                      <option
                        key={timezone.id}
                        value={timezone.id}
                        selected={
                          timezone.id === selectedTimezoneId ? true : false
                        }
                      >
                        {timezone.name} ({`${timezone.offset}`})
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              <div className="container px-md-5 my-3 my-md-3">
                <div className="px-md-5">
                  <Calendar
                    views={["month"]}
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 550 }}
                    onSelectSlot={handleShow}
                    selectable
                    onNavigate={handleNavigate}
                    onSelectEvent={handleBothEvents}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title className="d-flex align-items-center">
                <h5>
                  Select slots for{" "}
                  <span className="text-purple">
                    {selectedEvent &&
                      moment(selectedEvent.start).format("MM-DD-YYYY")}
                  </span>
                </h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-start align-items-sm-center">
                    <div className="mb-auto">
                      <h6 className="pe-3 my-2">From:</h6>
                    </div>
                    <Form.Select
                      htmlSize={showFromNoOptions}
                      className="border-0 border-bottom border-purple rounded-0"
                      onBlur={() => setShowFromNoOptions(1)}
                      onFocus={() => setShowFromNoOptions(5)}
                      onChange={(e) => setShiftAvailableFrom(e.target.value)}
                    >
                      {slots.map((slot, index) => {
                        return (
                          <option value={slot} key={index}>
                            {slot}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-start align-items-sm-center">
                    <div className="mb-auto">
                      <h6 className="pe-3 my-2">To:</h6>
                    </div>

                    <Form.Select
                      className="border-0 border-bottom border-purple rounded-0"
                      htmlSize={showToNoOptions}
                      onBlur={() => setShowToNoOptions(1)}
                      onFocus={() => setShowToNoOptions(5)}
                      onChange={(e) => setShiftAvailableTo(e.target.value)}
                      style={{ boxShadow: "none" }}
                    >
                      {slots.map((slot, index) => {
                        return (
                          <option value={slot} key={index}>
                            {slot}
                          </option>
                        );
                      })}
                    </Form.Select>

                    {/* <select
                      size={showToNoOptions}
                      className="form-select form-select-md border-0 border-bottom border-primary rounded-0"
                      onBlur={() => setShowToNoOptions(1)}
                      onFocus={() => setShowToNoOptions(5)}
                      onChange={(e) => setShiftAvailableTo(e.target.value)}
                    > */}
                    {/* {slots.map((slot, index) => {
                        return (
                          <option value={slot} key={index}>
                            {slot}
                          </option>
                        );
                      })} */}
                    {/* </select> */}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {/* {myEventsList.length > 0 && (
              <div>{JSON.stringify(myEventsList)}</div>
            )} */}

              {errorMessage.length > 0 && (
                <p className="my-2 text-danger">{errorMessage}</p>
              )}
              <button
                className="btn border-purple px-md-5"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-purple px-md-5"
                onClick={handleAddEvent}
              >
                Add Slot
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default DoctorSchedule;
