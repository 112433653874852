import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Container } from "react-bootstrap";
import pendingicon from "../../assets/images/pending.svg";
import successicon from "../../assets/images/success.svg";
import canceledicon from "../../assets/images/canceled.svg";
import zoomicon from "../../assets/images/zoom.svg";
// import ErrorToast from "../../components/ErrorToast";
import AuthContext from "../../context/authContext1";
import Spinner from "../../utils/Spinner";
import { toast } from "react-toastify";
import ErrorToast from "../../components/ErrorToast";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const AppointmentsPage = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const toastId = useRef<any>(null);
  const { token, userId, isAuthenticated } = useContext(AuthContext);
  const [appointments, setAppointments] = useState([]);
  const [isAppointmentAllowed, setIsAppointmentAllowed] =
    useState<boolean>(true);

  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const fetchStatus = (status: number) => {
    switch (status) {
      case 2:
        return {
          bg_color: "light_red",
          message: "Rejected",
          color: "text_red",
        };
      case 3:
        return {
          bg_color: "light_green",
          message: "Completed",
          color: "text_green",
        };
      case 4:
        return {
          bg_color: "light_red",
          message: "Cancelled",
          color: "text_red",
        };
      case 5:
        return {
          bg_color: "light_red",
          message: "Auto Cancelled",
          color: "text_red",
        };
    }
  };

  const fetchMobileStatus = (status: number) => {
    switch (status) {
      case 0:
        return {
          color: "warning",
          message: "Pending",
          status: "pending",
          icon: pendingicon,
        };
      case 1:
        return {
          color: "success",
          message: "Accepted",
          status: "accepted",
          icon: successicon,
        };
      case 2:
        return {
          color: "danger",
          message: "Rejected",
          status: "rejected",
          icon: canceledicon,
        };
      case 3:
        return {
          color: "success",
          message: "Completed",
          status: "completed",
          icon: successicon,
        };
      case 4:
        return {
          color: "danger",
          message: "Cancelled",
          status: "rejected",
          icon: canceledicon,
        };
      case 5:
        return {
          color: "danger",
          message: "Auto Cancelled",
          status: "rejected",
          icon: canceledicon,
        };
    }
  };

  function fetchAppointmentData() {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/view_patient_appointments",
      data: {},
    })
      .then((response) => {
        setAppointments(response.data.data);
        setIsAppointmentAllowed(response.data.isAppointmentAllow);
        setShowLoading(false);
      })
      .catch((err) => {
        return;
        setShowLoading(false);
      })
      .finally(() => setShowLoading(false));
  }

  useEffect(() => {
    fetchAppointmentData();
  }, [isAuthenticated, token]);

  const bookAppointment = () => {
    if (isAppointmentAllowed) {
      Navigate("/patient_appointments/create");
    } else {
      setShowWarningMessage(true);
      setTimeout(() => {
        setShowWarningMessage(false);
      }, 3000);
    }
  };

  const submitActions = (appointmentId: number, status: number) => {
    setShowLoading(true);
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/appointment_status",
      data: { user_id: userId, appointment_id: appointmentId, status: status },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.update(toastId.current, {
            render: "Your Appointment has been Cancelled",
            type: "success",
            isLoading: false,
          });
          setShowLoading(false);
          fetchAppointmentData();
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        setShowLoading(false);
        return;
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {showWarningMessage && (
        <ErrorToast
          type="danger"
          heading="Unable to Create Appointment"
          content="You already have an appointment that's either confirmed or waiting for confirmation. Please cancel it if you'd like to request a new slot"
        />
      )}
      {showLoading && <Spinner />}
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height my-md-3">
          <div style={{ marginTop: "85px" }}>
            <h4 className="my-md-3 ff-Sora">My Appointments</h4>
          </div>
          <div className="Custom_card px-md-4 py-md-3">
            <div className="py-1 d-flex justify-content-end align-items-center">
              <button
                onClick={bookAppointment}
                className="btn btn-outline-purple"
              >
                <i className="bi bi-plus me-2"></i>
                Book An Appointment
              </button>
            </div>

            <table className="table myTable mt-2 table-striped table-responsive">
              <thead>
                <tr className="text-dark fw-bold">
                  <th className="text-center" scope="col">
                    S.No
                  </th>
                  <th scope="col">Doctor Name</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="pb-3 shadow">
                {appointments.length > 0 ? (
                  <>
                    {appointments.map((appointment: any, index: number) => {
                      const currentStatus = fetchStatus(
                        appointment.appointment_status
                      );
                      return (
                        <tr key={index + 1}>
                          <td className="text-center align-middle">
                            {index + 1}
                          </td>
                          <td className="align-middle">
                            {appointment.doctor_name}
                          </td>
                          <td className="align-middle">{appointment.date}</td>
                          <td className="align-middle">{appointment.time}</td>
                          <td className="align-middle">
                            {appointment.appointment_status === 0 && (
                              <>
                                <button className="button_pending me-md-3">
                                  Appointment Pending
                                </button>
                                <button
                                  onClick={() =>
                                    submitActions(appointment.appointment_id, 4)
                                  }
                                  className="button_danger"
                                >
                                  Cancel Appointment
                                </button>
                              </>
                            )}

                            {/* accepted appointment */}

                            {appointment.appointment_status === 1 && (
                              <div className="d-flex">
                                <button
                                  onClick={() =>
                                    window.open(
                                      `${appointment.zoom_client_url}`,
                                      "_blank"
                                    )
                                  }
                                  className="button_zoom me-md-3"
                                >
                                  <i className="bi bi-camera-video"></i> Zoom
                                </button>

                                <button
                                  onClick={() =>
                                    submitActions(appointment.appointment_id, 4)
                                  }
                                  className="button_danger d-flex align-items-center"
                                >
                                  <i className="bi bi-x-lg me-1"></i>
                                  Cancel
                                </button>
                              </div>
                            )}
                            {/* status of appointment */}
                            <div className="d-flex">
                              {currentStatus && (
                                <span
                                  className={`px-5 py-2 fs-12 fw-600 text-${currentStatus.color} bg-${currentStatus.bg_color} rounded`}
                                >
                                  {currentStatus.message}
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5} className="text-purple text-center fw-bold">
                      No Appoinments For This Date
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
      <Container fluid className="px-3 d-block d-md-none">
        <div className="row mt-5 pt-5 d-flex align-items-center">
          <div className="col">
            <h6>My Appointments</h6>
          </div>

          <div
            className="col d-flex justify-content-end"
            onClick={bookAppointment}
          >
            <button className="btn btn-purple px-2 py-0 m-0 d-flex align-items-center">
              <div>
                <i className="bi bi-plus-lg"></i>
              </div>
              <div className="ms-1">
                <span className="text-white">create</span>
              </div>
            </button>
          </div>
        </div>

        <div>
          {appointments.map((appointment: any) => {
            const currentStatus = fetchMobileStatus(
              appointment.appointment_status
            );
            return (
              <Card
                className="my-3 border-light border"
                key={appointment.appointment_id}
              >
                <div className={`px-2 py-1 ${currentStatus?.status}`}>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <p className="text-uppercase my-0 fw-medium text-dark d-block fs-14">
                      {appointment.doctor_name}
                    </p>
                    <div className="d-flex">
                      <p className="my-0 fs-12 fw-bold">{appointment.date}</p>
                      <div
                        className={`mx-1 border-end border-${currentStatus?.color}`}
                      >
                        <div className="vr"></div>
                      </div>
                      <p className="my-0 fs-12 fw-bold">{appointment.time}</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="d-flex align-items-center m-0 p-0">
                      <img
                        height={18}
                        className="me-1"
                        src={currentStatus?.icon}
                        alt="status"
                      />
                      <p
                        className={`fs-14 mb-0 d-flex align-item-center text-uppercase text-${currentStatus?.color}`}
                      >
                        {currentStatus?.message}
                      </p>
                    </div>
                    <div>
                      {appointment.appointment_status === 0 && (
                        <button
                          onClick={() =>
                            submitActions(appointment.appointment_id, 4)
                          }
                          className="btn text-danger"
                        >
                          Cancel
                        </button>
                      )}
                      {appointment.appointment_status === 1 && (
                        <div className="d-flex align-items-center">
                          <button
                            onClick={() =>
                              window.open(
                                `${appointment.zoom_client_url}`,
                                "_blank"
                              )
                            }
                            className="border-0 border-success p-1 me-2 rounded bg-transparent"
                          >
                            <img
                              height={20}
                              src={zoomicon}
                              alt="zoom connect"
                            />
                          </button>
                          <button
                            onClick={() =>
                              submitActions(appointment.appointment_id, 4)
                            }
                            className="btn text-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default AppointmentsPage;
