import { useContext, useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { Container } from "react-bootstrap";
import Nydra_well_logo from "../assets/images/nydraWell.png";
import Nydra_well_logo_mobile from "../assets/images/Nydra_Well_logo_Mobile.svg";
import AuthContext from "../context/authContext1";
import { useNavigate } from "react-router-dom";
import MyProfileModal from "./MyProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";
import RoutineBuilder from "./RoutineBuilder";
import useAxiosPrivate from "../utils/useAxiosPrivate";

const Header = () => {
  const axiosPrivate = useAxiosPrivate();
  const [name, setName] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [myprofilemodalshow, setMyProfileModalShow] = useState(false);
  const handlemyprofileClose = () => setMyProfileModalShow(false);
  const handlemyprofileShow = () => setMyProfileModalShow(true);
  const [changepasswordshow, setChangePasswordShow] = useState(false);
  const handlechangepasswordClose = () => setChangePasswordShow(false);

  const [isroutineBuilder, setIsRoutineBuilder] = useState(false);
  const handleChangeIsRoutineBuilderClose = () => setIsRoutineBuilder(false);
  const handleChangeIsRoutineBuilderOpen = () => setIsRoutineBuilder(true);

  const { token, userId, userRole, logout, isAuthenticated, isFirsttimeLogIn } =
    useContext(AuthContext);
  const Navigate = useNavigate();

  const blobToImage = (blob: string) => {
    const image = blob;
    return image;
  };
  const HandleLogout = () => {
    Navigate("/login");
    logout();
    localStorage.clear();
  };

  useEffect(() => {
    if (isAuthenticated && userRole !== "superadmin") {
      axiosPrivate({
        method: "POST",
        url: "/viewprofile",
        data: {
          user_id: `${userId}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data[0];
            if (data.name !== name || data.profile_image !== image) {
              setName(data.name);

              if (data.profile_image !== null && data.profile_image !== "") {
                setImage(blobToImage(data.profile_image));
              } else {
                setImage(
                  "https://ionicframework.com/docs/img/demos/avatar.svg"
                );
              }
            }
          }
        })
        .catch((err) => {
          return;
        });
    }
  }, [isAuthenticated, userRole, userId, token, name, image]);

  let headerroutes;

  if (userRole === "superadmin") {
    headerroutes = (
      <>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => Navigate("/admin_patient_lists")}
        >
          <i className="bi bi-list me-2"></i> Patient Lists
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => Navigate("/admin_notifications_category")}
        >
          <i className="bi bi-bell me-2"></i>
          Notification
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={HandleLogout}
        >
          <i className="bi bi-box-arrow-left me-2" />
          Logout
        </Dropdown.Item>
      </>
    );
  } else if (userRole === "patient") {
    headerroutes = (
      <>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={handlemyprofileShow}
        >
          <i className="bi bi-person me-2" />
          Profile
        </Dropdown.Item>

        {isAuthenticated && !isFirsttimeLogIn && (
          <Dropdown.Item
            className="dropdown dropdown-item-hover text-purple"
            onClick={handleChangeIsRoutineBuilderOpen}
          >
            <i className="bi bi-card-checklist me-2"></i>
            Routine Builder
          </Dropdown.Item>
        )}

        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => setChangePasswordShow(true)}
        >
          <i className="bi bi-pencil-square me-2" />
          Change password
        </Dropdown.Item>

        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={HandleLogout}
        >
          <i className="bi bi-box-arrow-left me-2" />
          Logout
        </Dropdown.Item>
      </>
    );
  } else if (userRole === "doctor") {
    headerroutes = (
      <>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => Navigate("/doctorschedule")}
        >
          <i className="bi bi-calendar3 me-2"></i>
          My Schedule
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => Navigate("/doctorappointments")}
        >
          <i className="bi bi-calendar-check me-2"></i>
          My Appointment
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => Navigate("/doctorsPatientList")}
        >
          <i className="bi bi-list me-2"></i>
          Patient Lists
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={handlemyprofileShow}
        >
          <i className="bi bi-person me-2" />
          Edit Profile
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={() => setChangePasswordShow(true)}
        >
          <i className="bi bi-pencil-square me-2" />
          Change password
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown dropdown-item-hover text-purple"
          onClick={HandleLogout}
        >
          <i className="bi bi-box-arrow-left me-2" />
          Logout
        </Dropdown.Item>
      </>
    );
  }

  const handleDashboard = () => {
    if (userRole === "superadmin") {
      Navigate("/managedoctors");
    } else if (userRole === "patient") {
      Navigate("/dashboard");
    } else if (userRole === "doctor") {
      Navigate("/doctorappointments");
    }
  };

  let HeaderImage;

  if (userRole === "superadmin") {
    HeaderImage = (
      <span className="me-2 fs-14 text-black fw-600">Super Admin</span>
    );
  } else {
    HeaderImage = (
      <>
        <span className="me-2 fs-14 text-black fw-600">{name}</span>
        <img
          src={image}
          alt="doctor_logo"
          height="42px"
          width="42px"
          className="rounded-circle"
        />
      </>
    );
  }

  return (
    <>
      <MyProfileModal
        show={myprofilemodalshow}
        handleClose={handlemyprofileClose}
      />
      <ChangePasswordModal
        show={changepasswordshow}
        handleClose={handlechangepasswordClose}
      />
      {userRole === "patient" && (
        <RoutineBuilder
          show={isroutineBuilder}
          handleClose={handleChangeIsRoutineBuilderClose}
        />
      )}

      <Navbar
        fixed="top"
        variant="white"
        bg="white"
        className="d-flex justify-content-between border-bottom shadow-sm"
        style={{ height: "76px" }}
      >
        <Container fluid className="px-md-5">
          <Navbar.Brand
            className="d-flex align-items-center"
            onClick={handleDashboard}
            style={{ cursor: "pointer" }}
          >
            <img
              src={Nydra_well_logo}
              alt="Nydra_well_logo"
              width="50%"
              className="d-none d-md-block"
            />
            <img
              src={Nydra_well_logo_mobile}
              alt="Nydra_well_logo"
              className="d-block d-md-none"
            />
          </Navbar.Brand>

          <Dropdown align="end" className="p-0 m-0 ">
            <Dropdown.Toggle
              variant="white"
              id="dropdown-basic"
              className="border-0 outline-0"
            >
              {HeaderImage}
            </Dropdown.Toggle>

            <Dropdown.Menu className="m-0 p-0">{headerroutes}</Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
