import React from "react";
import SnapShot from "../../assets/images/snapShoot4.png";
import { Col, Row } from "react-bootstrap";
const BreathWorkSleep = () => {
  return (
    <>
      <div className="mt-md-5 text-center d-none d-md-block">
        <Row>
          <Col>
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Breath+exercises+for+Sleep.mp4"
              poster={SnapShot}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
      <div className="mt-5 text-center d-block d-md-none">
        <Row>
          <Col className="col-12">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Breath+exercises+for+Sleep.mp4"
              poster={SnapShot}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BreathWorkSleep;
