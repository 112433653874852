import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthContext from "../../../context/authContext1";
import QuestionarieContext from "../../../context/questionaireContext";
import {
  RadioFieldGroup,
  CheckBoxFieldGroup,
} from "../../../utils/Fromcontrol";
import Stepper from "./Stepper";
import Spinner from "../../../utils/Spinner";
import { Container } from "react-bootstrap";
import useAxiosPrivate from "../../../utils/useAxiosPrivate";

interface Values {
  17: string | null;
  18: string | null;
  19: string | null;
  20: string | null;
  21: string | null;
  22: string | null;
  23: string | null;
  24: string | null;
  25: string | null;
  96: string | null;
  97: string | null;
  98: string | null;
  99: string | null;
}

const QuestionariePart3: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated, token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { activeStepHandler } = useContext(QuestionarieContext);

  const [questions, setQuestions] = useState([]);
  const [isQuestionFetching, setIsQuestionFetching] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const validationSchema = Yup.object({
    17: Yup.string().required("A radio option is required").nullable(),
    18: Yup.string().required("A radio option is required").nullable(),
    19: Yup.string().required("A radio option is required").nullable(),
    20: Yup.string().required("A radio option is required").nullable(),
    21: Yup.string().required("A radio option is required").nullable(),
    22: Yup.array().required("At least one checkbox is required").nullable(),
    23: Yup.string().required("A radio option is required").nullable(),
    24: Yup.string()
      .when("23", {
        is: (val: string) => val === "226",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    25: Yup.string()
      .when("23", {
        is: (val: string) => val === "226",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    96: Yup.string()
      .when("23", {
        is: (val: string) => val === "227",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    97: Yup.string()
      .when("23", {
        is: (val: string) => val === "227",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    98: Yup.string()
      .when("23", {
        is: (val: string) => val === "228",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    99: Yup.string()
      .when("23", {
        is: (val: string) => val === "228",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
  });

  let actualInitialValues: any = {
    17: null,
    18: null,
    19: null,
    20: null,
    21: null,
    22: null,
    23: null,
    24: null,
    25: null,
    96: null,
    97: null,
    98: null,
    99: null,
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      setIsQuestionFetching(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      axiosPrivate({
        method: "POST",
        url: "/questionaire",
        data: { part: 3 },
      })
        .then((res: any) => {
          const { isCompleted, data } = res.data;
          if (isCompleted) {
            const keyValueArray = data.map((question: any) => {
              if (question?.question_type === "radio") {
                return [
                  question?.id,
                  question?.isSelectedOption,
                  question?.question_type,
                ];
              } else {
                const convertedSelectedOption = question?.isSelectedOption.map(
                  (option: number) => option.toString()
                );
                return [
                  question?.id,
                  convertedSelectedOption,
                  question?.question_type,
                ];
              }
            });

            keyValueArray.forEach((keyValuePair: any) => {
              const key = keyValuePair[0];
              const value = keyValuePair[1];
              const questionType = keyValuePair[2];
              if (questionType === "radio") {
                actualInitialValues[key] =
                  value === null ? null : value.toString();
              } else {
                actualInitialValues[key] = value === null ? [] : value;
              }
            });

            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          } else {
            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          }
        })
        .catch((err: any) => {
          return;
        })
        .finally(() => {
          setIsQuestionFetching(false);
          setIsLoading(false);
        });
    }
  }, [isAuthenticated]);

  const submitHandler = (values: Values) => {
    setIsSubmitting(true);
    axiosPrivate({
      method: "POST",
      url: "/submitQuestionaire",
      data: { questions: values, part: 3 },
    })
      .then((res) => {
        if (res.status === 200) {
          activeStepHandler();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      {isLoading && <Spinner />}
      {isSubmitting && <Spinner />}
      <Container>
        <div>
          <div className="my-4 d-flex justify-content-center">
            <div className="w-75">
              <Stepper />
            </div>
          </div>
          <h6 className="my-2">Questionarie 3</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            enableReinitialize={true}
          >
            {(formikProps) => {
              return (
                <Form>
                  {!isQuestionFetching && (
                    <div>
                      {questions.map((question: any) => {
                        if (question.question_type === "radio") {
                          return (
                            <RadioFieldGroup
                              key={question.id}
                              questionId={question.question_id}
                              optionId={question.option_id}
                              question={question.name}
                              questionSequence={question.question_sequence}
                              name={question.id}
                              options={question.options}
                              values={formikProps.values}
                              actualQuestionId={question.id}
                            />
                          );
                        } else if (question.question_type === "checkbox") {
                          return (
                            <CheckBoxFieldGroup
                              key={question.id}
                              questionId={question.question_id}
                              optionId={question.option_id}
                              question={question.name}
                              questionSequence={question.question_sequence}
                              name={question.id}
                              options={question.options}
                              values={formikProps.values}
                              actualQuestionId={question.id}
                            />
                          );
                        }
                      })}

                      <div className="text-center my-3">
                        <button
                          type="submit"
                          className="btn btn-purple rounded-1 px-5"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default QuestionariePart3;
