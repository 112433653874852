import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Email_Image from "../../assets/images/email_image.svg";
import { useNavigate } from "react-router-dom";

const StillThinkingAboutIt = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div className="d-none d-md-block">
        <Container className=" p-0 m-0 d-md-flex justify-content-md-center align-items-center email_template_card email_component">
          <div className="px-md-5 py-md-4">
            <Row>
              <Col className=" py-md-4 d-flex flex-column">
                <div className="mt-md-4">
                  <h6 className="email_template_text">
                    Still thinking about it?
                  </h6>
                </div>

                <div className="mt-md-3">
                  <p className="email_template_p">
                    Sign up to learn more about Nydra Well
                  </p>
                </div>

                <div className=" row d-flex align-items-center bg-white rounded-5 py-2 mt-md-5">
                  <div className="col">
                    <input
                      type="search"
                      style={{ outline: "none" }}
                      className="border-0"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="col justify-content-end">
                    <button
                      style={{ background: "#3470FF" }}
                      className="btn rounded-pill w-100 text-white"
                      onClick={() => Navigate("/signup")}
                    >
                      Sign Up Now
                    </button>
                  </div>
                </div>
              </Col>
              <Col className="px-md-5 mx-md-5">
                <img src={Email_Image} alt="email_image" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div
        className="d-block d-sm-none px-4 still_thinking_sm "
        style={{
          position: "absolute",
          zIndex: "1",
          marginTop: "22% !important",
        }}
      >
        <div
          className="px-4 py-5 email_template_card email_component"
          style={{ width: "100%", height: "100%" }}
        >
          <Row className="">
            <Col sm={12} className="d-flex flex-column">
              <div className="">
                <h6 className="email_template_text text-center">
                  Still thinking about it?
                </h6>
              </div>

              <div className="">
                <p className="email_template_p text-center">
                  Sign up to learn more about Nydra Well
                </p>
              </div>

              <div className="row d-flex align-items-center bg-white rounded-5 py-2 ">
                <div className="col p-0">
                  <input
                    type="search"
                    style={{
                      outline: "none",
                      fontSize: "13px",
                      marginLeft: "11px",
                    }}
                    className="border-0 "
                    placeholder="Enter Your Email"
                  />
                </div>
                <span className="col d-flex justify-content-center p-0 mb-0">
                  <button
                    style={{ background: "#3470FF", fontSize: "13px" }}
                    className="btn rounded-pill text-white"
                    onClick={() => Navigate("/signup")}
                  >
                    Sign Up Now
                  </button>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default StillThinkingAboutIt;
