import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext1";
import { Card, Container } from "react-bootstrap";
import CreateNotificationCategoryModal from "../../components/admin/CreateNotificationCategoryModal";
import EditNotificationModal from "../../components/admin/EditNotificationModal";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Spinner from "../../utils/Spinner";

const NotificationCategory = () => {
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated } = useContext(AuthContext);

  const [notificationId, setNotificationId] = useState(0);
  const [notificationCategoryTitle, setNotificationCategoryTitle] =
    useState("");
  const [smsNotificationCategoryTitle, setSMSNotificationCategoryTitle] =
    useState("");

  const [notificationsList, setNotificationsList] = useState([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenNotificationCategoryModal, setIsOpenNotificationCategoryModal] =
    useState<boolean>(false);

  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);

  const [
    isOpenNotificationCategoryEditModal,
    setIsOpenNotificationCategoryEditModal,
  ] = useState<boolean>(false);

  const closeNotificationCategoryModalHandler = () => {
    setIsOpenNotificationCategoryModal(false);
  };

  const openNotificationCategoryEditHandler = (
    id: number,
    notificationTitle: string,
    smsNotificationCategoryTitle: string
  ) => {
    setNotificationId(id);
    setNotificationCategoryTitle(notificationTitle);
    setIsOpenNotificationCategoryEditModal(true);
    setSMSNotificationCategoryTitle(smsNotificationCategoryTitle);
  };

  const closeNotificationCategoryEditModalHandler = () => {
    setIsOpenNotificationCategoryEditModal(false);
    setNotificationId(0);
    setNotificationCategoryTitle("");
  };
  const notificationDeleteHandler = (id: number) => {
    axiosPrivate({
      method: "POST",
      url: "/deleteNotification",
      data: {
        id: id,
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          setIsApiSuccess(!isApiSuccess);
        }
      })
      .catch((err: any) => {
        return;
      });
  };
  const apiSuccessHandler = () => {
    setIsApiSuccess(!isApiSuccess);
  };
  async function fetchNotifications() {
    setIsLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/getNotifications",
      data: {},
    })
      .then((response) => {
        setNotificationsList(response.data.data);
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (isAuthenticated) {
      fetchNotifications();
    }
  }, [isAuthenticated, isApiSuccess]);
  return (
    <>
      <CreateNotificationCategoryModal
        isOpenNotificationCategoryModal={isOpenNotificationCategoryModal}
        closeNotificationCategoryModalHandler={
          closeNotificationCategoryModalHandler
        }
        apiSuccessHandler={apiSuccessHandler}
      />
      <EditNotificationModal
        isOpenNotificationCategoryEditModal={
          isOpenNotificationCategoryEditModal
        }
        closeNotificationCategoryEditModalHandler={
          closeNotificationCategoryEditModalHandler
        }
        notificationId={notificationId}
        notificationCategory={notificationCategoryTitle}
        smsnotificationCategory={smsNotificationCategoryTitle}
        apiSuccessHandler={apiSuccessHandler}
      />
      {isLoading && <Spinner />}
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height my-md-4">
          <div
            style={{ marginTop: "85px" }}
            className="Custom_card px-md-5 py-md-3"
          >
            <div className="d-flex justify-content-between align-items-center my-md-3">
              <h4>Notification Category</h4>
              <button
                onClick={() => setIsOpenNotificationCategoryModal(true)}
                className="btn btn-purple"
              >
                Create Notification Category
              </button>
            </div>
            <div>
              <table className="table myTable table-striped table-responsive">
                <thead className="thead-dark">
                  <tr className="text-dark fw-bold shadow-lg">
                    <th className="text-center" scope="col">
                      Sr.No
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">Sms Notification</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="pb-3 shadow">
                  {notificationsList.map((notification: any, idx: number) => {
                    return (
                      <tr key={notification.id}>
                        <td className="text-center align-middle">{idx + 1}</td>
                        <td className="align-middle">
                          {notification.notification_category}
                        </td>
                        <td className="align-middle">
                          {notification.sms_notification_category}
                        </td>
                        <td className="d-flex justify-content-center">
                          <button
                            onClick={() =>
                              openNotificationCategoryEditHandler(
                                notification.id,
                                notification.notification_category,
                                notification.sms_notification_category
                              )
                            }
                            className="btn bg-purple rounded-1 text-white me-1 "
                          >
                            <i className="bi bi-pencil-square me-2"></i> Edit
                          </button>
                          <button
                            onClick={() =>
                              notificationDeleteHandler(notification.id)
                            }
                            className="btn bg-danger rounded-1 text-white "
                          >
                            <i className="bi bi-trash me-2"></i>Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none">
        <div className="desktop_header_height my-md-4">
          <div
            style={{ marginTop: "85px" }}
            className="Custom_card px-md-5 py-md-3"
          >
            <div className="row mt-2 d-flex align-items-center">
              <div className="col">
                <h6>Notification Category</h6>
              </div>
              <div
                className="col d-flex justify-content-end"
                onClick={() => setIsOpenNotificationCategoryModal(true)}
              >
                <button className="btn btn-purple px-2 py-0 m-0 d-flex align-items-center">
                  <div>
                    <i className="bi bi-plus-lg"></i>
                  </div>
                  <div className="ms-1">
                    <span className="text-white">create</span>
                  </div>
                </button>
              </div>
            </div>
            <div>
              {notificationsList.map((notification: any) => {
                const { id } = notification;
                return (
                  <Card className="my-3 border-light border">
                    <div className={`px-3 py-1`}>
                      <div className="d-flex justify-content-between align-items-center mt-2 p-0 m-0">
                        <p className="text-uppercase fw-medium fs-14">
                          {notification.notification_category}
                        </p>
                      </div>
                      <div className="d-flex justify-content-end align-items-center my-2">
                        <button
                          onClick={() =>
                            openNotificationCategoryEditHandler(
                              notification.id,
                              notification.notification_category,
                              notification.sms_notification_category
                            )
                          }
                          className=" px-4 py-1 btn btn-purple"
                        >
                          <i className="bi bi-pencil me-1"></i> Edit
                        </button>
                        <button
                          className=" px-4 py-1 btn bg-danger text-white ms-2"
                          onClick={() =>
                            notificationDeleteHandler(notification.id)
                          }
                        >
                          <i className="bi bi-trash me-1"></i>Delete
                        </button>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NotificationCategory;
