import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

const SleepHabitsBarChart = ({ sleepHabitsData }: any) => {
  const tickFormatter = (value: string) => {
    const limit = 12; // put your maximum character
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
  };

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    // const limit = 28;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={4}
          y={20}
          dy={-28}
          textAnchor="end"
          fill="#666"
          transform="rotate(-38)"
          className="fs-10"
        >
          {/* {payload?.value.length > limit
            ? payload.value.substring(0, limit)
            : payload.value} */}
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer className="Charts" width="99%" height={460}>
      <BarChart
        data={sleepHabitsData}
        layout="vertical" // This sets the chart to be horizontal
        margin={{ top: 12, left: 7, right: 5, bottom: 10 }}
      >
        <CartesianGrid stroke="white" fill="#EAEAF2" />
        <XAxis type="number" tickCount={10} interval={1} domain={[0, 2]} />
        <YAxis
          width={110}
          tickFormatter={tickFormatter}
          tick={CustomizedAxisTick}
          dataKey="name"
          minTickGap={-120}
          type="category"
        />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8">
          {sleepHabitsData.map((entry: any, index: number) => {
            const color =
              entry.value > 0.7
                ? "#007300"
                : entry.value < 0.3
                ? "#FF0000"
                : "#E6E600";
            return <Cell key={index} fill={color} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SleepHabitsBarChart;
