import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext1";
import { Card, Container } from "react-bootstrap";
import Spinner from "../../utils/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import NotificationOptionsWeb from "../../components/doctor/NotificationOptionsWeb";
import NotificationCategoryModal from "../../components/doctor/NotificationCategoryModal";
import NotificationOptionsMobile from "../../components/doctor/NotificationOptionsMobile";
import { toast, ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const NotificationPage = () => {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const Navigate = useNavigate();
  const { patientId } = useParams();
  const [email, setEmail] = useState<boolean>(true);
  const [sms, setSms] = useState<boolean>(true);
  const [notificationsList, setNotificationsList] = useState<any>([]);
  const [isOpenNotificationCategoryModal, setIsOpenNotificationCategoryModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const apiSuccessHandler = () => {
    setIsApiSuccess(!isApiSuccess);
  };
  const updateNotification = (
    index: number,
    id: number,
    field: string,
    value: string | boolean
  ) => {
    const filteredNotification = notificationsList.filter(
      (notification: any) => notification.id === id
    );

    if (field === "time") {
      filteredNotification[0].wearabledata[field] = value;
    } else if (field === "status") {
      filteredNotification[0][field] = value;
    } else {
      filteredNotification[0].wearabledata.day[field] = value;
    }

    const updatedNotificationList = Object.assign([], notificationsList, {
      [index]: filteredNotification[0],
    });

    setNotificationsList(updatedNotificationList);
  };
  const closeNotificationCategoryModalHandler = () => {
    setIsOpenNotificationCategoryModal(false);
  };

  useEffect(() => {
    if (isAuthenticated && userRole === "doctor") {
      setIsLoading(true);
      axiosPrivate({
        method: "POST",
        url: "/nonWearableNotifications",
        data: { patientId: patientId },
      })
        .then((response) => {
          const { email, sms } = response.data;
          const { data } = response.data;
          setEmail(email);
          setSms(sms);
          setNotificationsList(data);
        })
        .catch((err) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated, isApiSuccess]);

  const submitHandler = () => {
    setIsLoading(true);
    if (email || sms) {
      axiosPrivate({
        method: "POST",
        url: "/submitNonWearableNotifications",
        data: {
          email: email,
          sms: sms,
          patientId: patientId,
          data: notificationsList,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(`${response.data.message}`);
            apiSuccessHandler();
            setTimeout(() => {
              Navigate(-1);
            }, 2000);
          }
        })
        .catch((error) => {
          return;
        })
        .finally(() => setIsLoading(false));
    } else {
      toast.error(
        "At least one of the delivery mechanisms must be in an active state."
      );
    }
  };
  return (
    <>
      <NotificationCategoryModal
        isOpenNotificationCategoryModal={isOpenNotificationCategoryModal}
        closeNotificationCategoryModalHandler={
          closeNotificationCategoryModalHandler
        }
        apiSuccessHandler={apiSuccessHandler}
        patientId={patientId}
      />
      {isLoading && <Spinner />}
      <ToastContainer />
      <Container fluid className="px-md-5 d-none d-md-block">
        <div style={{ marginTop: "85px" }} className="desktop_header_height">
          <Card className="px-md-4 py-md-3 shadow-lg">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Sleep Hygiene Reminder </h4>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <button
                    className="btn btn-purple mx-2 "
                    onClick={() => Navigate(-1)}
                  >
                    <i className="bi bi-arrow-left"></i>
                  </button>
                </div>
                <div>
                  <p className="p-0 m-0 me-3">Notification Sent Type:</p>
                </div>

                <div className="form-check ">
                  <input
                    className="form-check-input cursor-pointer border-purple"
                    type="checkbox"
                    checked={email}
                    id="email"
                    onClick={() => setEmail(!email)}
                  />
                  <label className="form-check-label" htmlFor="email">
                    Email
                  </label>
                </div>
                <div className="form-check mx-3">
                  <input
                    className="form-check-input cursor-pointer border-purple"
                    type="checkbox"
                    checked={sms}
                    id="sms"
                    onClick={() => setSms(!sms)}
                  />
                  <label className="form-check-label" htmlFor="sms">
                    SMS
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer border-purple"
                    type="checkbox"
                    disabled
                    checked
                    id="inAppNotification"
                  />
                  <label className="form-check-label" htmlFor="sms">
                    In App Notification
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end my-md-2">
              <button
                onClick={() => setIsOpenNotificationCategoryModal(true)}
                className="btn btn-purple px-md-5"
              >
                Create
              </button>
            </div>
            <div className="shadow">
              <table className="table myTable table-striped table-responsive">
                <thead className="thead-dark">
                  <tr className="text-dark">
                    <th scope="col" className="align-middle">
                      Category
                    </th>
                    <th scope="col" className="align-middle">
                      SMS Category
                    </th>
                    <th scope="col" className="align-middle">
                      Activate
                    </th>
                    <th scope="col" className="align-middle">
                      Time of the Day
                    </th>
                    <th scope="col" className="align-middle">
                      Mon
                    </th>
                    <th scope="col" className="align-middle">
                      Tue
                    </th>
                    <th scope="col" className="align-middle">
                      Wed
                    </th>
                    <th scope="col" className="align-middle">
                      Thu
                    </th>
                    <th scope="col" className="align-middle">
                      Fri
                    </th>
                    <th scope="col" className="align-middle">
                      Sat
                    </th>
                    <th scope="col" className="align-middle">
                      Sun
                    </th>
                  </tr>
                </thead>
                <tbody className="pb-3">
                  {notificationsList.map((notification: any, idx: number) => (
                    <NotificationOptionsWeb
                      key={notification.id}
                      index={idx}
                      patientId={patientId}
                      notification={notification}
                      updateNotification={updateNotification}
                      apiSuccessHandler={apiSuccessHandler}
                    />
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-center my-3">
                <button
                  onClick={submitHandler}
                  className="btn btn-purple px-md-5 py-md-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </Card>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none px-3">
        <div className="row mt-5 pt-4 d-flex align-items-center">
          <div className="d-flex mt-3 flex-column justify-content-between px-3">
            <div className="d-flex justify-content-between py-2">
              <div>
                <h6 className="fw-500 mt-1 ">Notification Sent Type &nbsp;:</h6>
              </div>

              <div>
                <button
                  className="btn btn-purple py-1"
                  onClick={() => Navigate(-1)}
                >
                  <i className="bi bi-arrow-left"></i>
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={email}
                  id="email"
                  onClick={() => setEmail(!email)}
                />
                <label className="form-check-label" htmlFor="email">
                  Email
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={sms}
                  id="sms"
                  onClick={() => setSms(!sms)}
                />
                <label className="form-check-label" htmlFor="sms">
                  SMS
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input cursor-pointer border-purple"
                  type="checkbox"
                  disabled
                  checked
                  id="inAppNotification"
                />
                <label className="form-check-label" htmlFor="sms">
                  In App Notification
                </label>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <button
              onClick={() => setIsOpenNotificationCategoryModal(true)}
              className="btn btn-purple w-100 py-1"
            >
              Create
            </button>
          </div>

          <div>
            {notificationsList.map((notification: any, idx: number) => (
              <NotificationOptionsMobile
                key={notification.id}
                index={idx}
                patientId={patientId}
                notification={notification}
                updateNotification={updateNotification}
                apiSuccessHandler={apiSuccessHandler}
              />
            ))}
          </div>
          <div className="d-flex justify-content-center my-3">
            <button
              onClick={submitHandler}
              className="btn btn-purple px-md-5 py-md-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NotificationPage;
