import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import { toast } from "react-toastify";
import Spinner from "../../utils/Spinner";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import styles from "./Accordion.module.scss";

const PastFiveDaysData = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setShowLoading] = useState(false);
  const [isData, setIsData] = useState<any>();

  function pastfivedaysData() {
    setShowLoading(true);
    axiosPrivate({
      method: "GET",
      url: "/windDownData",
    })
      .then((response) => {
        const { status, data } = response.data;
        if (status) {
          setIsData(data);
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  }
  useEffect(() => {
    pastfivedaysData();
  }, []);
  return (
    <>
      {isLoading && <Spinner />}
      <div>
        <h4>Data from last five days</h4>
        <Accordion
          className={`${styles.faqscustomAccordian}`}
          defaultActiveKey="0"
        >
          {isData &&
            isData.map((entry: any, index: any) => (
              <Accordion.Item
                key={index}
                eventKey={index.toString()}
                bsPrefix={`${styles.accordianItem}`}
              >
                <Accordion.Header
                  bsPrefix={`${styles.accordianHeader} py-md-2`}
                >
                  {moment(entry.date).format("MM-DD-YYYY")}
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  <div>
                    <h5>How was your day:</h5>
                    {entry.how_was_your_day &&
                    entry.how_was_your_day.length > 0 ? (
                      <ul>
                        {entry.how_was_your_day.map((item: any, i: any) => (
                          <>
                            <li key={i}>{item.overall_feeling}</li>
                            <li key={i}>{item.lessons_learned}</li>
                            <li key={i}>{item.joyful_moments}</li>
                            <li key={i}>{item.upsetting_events}</li>
                          </>
                        ))}
                      </ul>
                    ) : (
                      <div>
                        <p>There is no data available for this date.</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <h5>Window Goal:</h5>
                    {entry.windowGoal && entry.windowGoal.length > 0 ? (
                      <ul>
                        {entry.windowGoal.map((item: any, i: any) => (
                          <li key={i}>{item.goal}</li>
                        ))}
                      </ul>
                    ) : (
                      <div>
                        <p>There is no data available for this date.</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <h5>Window Gratitude:</h5>
                    {entry.windowGratitude &&
                    entry.windowGratitude.length > 0 ? (
                      <ul>
                        {entry.windowGratitude.map((item: any, i: any) => (
                          <li key={i}>{item.grade}</li>
                        ))}
                      </ul>
                    ) : (
                      <div>
                        <p>There is no data available for this date.</p>
                      </div>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </>
  );
};

export default PastFiveDaysData;
