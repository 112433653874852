import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Diet from "../../assets/images/eating.png";
import Exericise from "../../assets/images/warmup.png";
import Sleep from "../../assets/images/sleeping.png";
import "./Piller.css";
import { useNavigate } from "react-router-dom";

const PillerComponent = () => {
  const Navigate = useNavigate();
  const [marginTop, setMarginTop] = useState("-150px");
  const CardComponentData = [
    {
      id: 1,
      heading: "Sleep",
      image: Sleep,
      motive: "Rest, Recover, & Rejuvenate",
      content:
        "Decreased stress levels Improved cognitive function Improved mood Stronger immune system",
    },
    {
      id: 2,
      image: Diet,
      heading: "Diet",
      motive: "Nourish your body",
      content:
        "Improved physical well-being Enhanced mental clarity Reduced risk of chronic diseases",
    },
    {
      id: 3,
      image: Exericise,
      heading: "Exericise",
      motive: "Move, Strengthen, & Energize",
      content:
        "Increased energy levels Stronger muscles & bones Improved cardiovascular health",
    },
  ];

  const PillerCardComponent = ({ image, motive, content, heading }: any) => {
    return (
      <div className="mx-md-3 py-md-3 px-md-3  py-3">
        <div className="piller_card p-3">
          <img src={image} className=" piller_card_img" />
          <div className="px-md-3">
            <div>
              <h6 className="text-white piller_button border-0 py-2 rounded text-start ps-3 text-uppercase">
                {heading}
              </h6>
            </div>

            <div>
              <h5 className="fw-bold py-2">{motive}</h5>
              <p className="text-start">{content}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 786) {
        setMarginTop("-150px");
      } else {
        setMarginTop("0");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Container style={{ marginTop }}>
        <div className="text-center pb-4 pb-md-0 mt-xxl-5 pt-xxl-5">
          <h5 className="piller_heading_text d-none d-md-block mt-xxl-5">
            Compelling Reasons to Invest in Your Sleep through our Nydra Well
            Program
          </h5>
          <h5 className="piller_heading_text text-start mt-4 d-block d-sm-none">
            Compelling Reasons to Invest in Your Sleep through our Nydra Well
            Program
          </h5>
          <p className="piller_text text-center d-none d-md-block">
            Three Pillars of Good Health
          </p>
          <p className="piller_text text-start d-block d-sm-none">
            Three Pillars of Good Health
          </p>
          <div className="d-flex flex-column flex-md-row justify-content-md-around align-items-center py-md-4 text-start">
            {CardComponentData.map((data: any) => (
              <PillerCardComponent
                key={data.id}
                heading={data.heading}
                image={data.image}
                motive={data.motive}
                content={data.content}
              />
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-3 mt-md-0 d-none d-mb-block">
          <button
            className="btn bg-dark_blue text-white rounded-pill py-md-2 py-3 d-flex align-items-center getstart me-2"
            onClick={() => Navigate("/signup")}
          >
            Get Started Now <i className="bi bi-arrow-right mt-1 ms-2"></i>
          </button>
        </div>

        <div className="d-flex justify-content-center mt-3 mt-md-0 d-block d-mb-none mb-4 mb-md-0">
          <button
            className="btn bg-dark_blue text-white rounded-pill py-md-2 py-3 d-flex align-items-center getstart_1 "
            onClick={() => Navigate("/signup")}
          >
            Get Started Now <i className="bi bi-arrow-right mt-1 ms-md-3"></i>
          </button>
        </div>
      </Container>
    </>
  );
};

export default PillerComponent;
