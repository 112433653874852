import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import AuthContext from "../../context/authContext1";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import CheckMark from "../../assets/images/checkmark.png";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const PatientForm = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated } = useContext(AuthContext);
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assementData, setAssementData] = useState([]);
  const [ismessage, setIsMessage] = useState<string>();
  const [isStatus, setIsStatue] = useState<boolean>();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      axiosPrivate({
        method: "POST",
        url: "/viewSleepProfile",
        data: {
          id: userId,
        },
      })
        .then((response: any) => {
          const { data, status } = response.data;
          setIsStatue(response.data.status);
          if (status) {
            setAssementData(data);
          } else {
            setIsMessage(response.data.message);
          }
        })
        .catch((err: any) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated]);
  return (
    <>
      {isLoading && <Spinner />}
      <Container className="px-md-5 pb-4">
        <div className="desktop_header_height my-md-3 pb-md-5">
          <div style={{ marginTop: "85px" }} className="d-none d-md-block">
            <div className="d-flex align-items-center justify-content-between my-md-3">
              <div className="mt-md-2">
                <h5>Sleep Form</h5>
              </div>
              <div
                className="btn btn-purple border-0 me-2"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <i className="bi bi-arrow-left"></i>
              </div>
            </div>
          </div>
          <div className="d-block d-md-none pt-4 mt-5">
            <div className="d-flex align-items-center pt-3">
              <div>
                <button
                  className="btn btn-purple me-2 px-2 py-0 m-0 "
                  onClick={() => Navigate(-1)}
                >
                  <i className="bi bi-arrow-left"></i>
                </button>
              </div>
              <div>
                <h6 className="pt-2">Sleep Form</h6>
              </div>
            </div>
          </div>
          <div className="border px-md-3 py-md-3">
            {assementData?.map((data: any, index: number) => {
              return (
                <div key={index}>
                  <div className="px-1">
                    <div className="text-center fw-bold">
                      <h4 className="text-uppercase fw-bolder text-purple">
                        {data.title}
                      </h4>
                    </div>
                    <div className="mt-md-4">
                      {data.sleepResponse.map(
                        (response: any, index: number) => {
                          return (
                            <div key={index} className="my-md-4 my-2 px-1 ">
                              <h6 className=" fw-bold my-md-3">
                                {response.name}
                              </h6>
                              <div className="ms-md-4">
                                <h6 className="text-success">
                                  {response.option}
                                  <img
                                    height={16}
                                    width={16}
                                    src={CheckMark}
                                    alt="selectedOption"
                                    className="ms-md-2"
                                  />
                                </h6>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

export default PatientForm;
