import { useEffect, useState } from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <input
            className={` ${meta.touched && meta.error && "is-invalid"}`}
            {...props}
            value={field.value}
            onChange={(e) => field.onChange(e)}
            placeholder=" "
          />
          <span>{label}</span>
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const TextSelect = ({ label, options, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <select
            {...field}
            {...props}
            type={label}
            className={`${meta.touched && meta.error && "is-invalid"}`}
            required
          >
            {options?.map((option: any) => {
              return (
                <option
                  key={option.value}
                  value={option.value}
                  onChange={option.value}
                >
                  {option.key}
                </option>
              );
            })}
          </select>
          <span>{label}</span>
        </div>
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const SignUpTextSelect = ({ label, options, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <select
            {...field}
            {...props}
            type={label}
            className={`${meta.touched && meta.error && "is-invalid"}`}
            required
          >
            <option value="" disabled selected></option>
            {options?.map((option: any) => {
              return (
                <option
                  key={option.id}
                  value={option.id.toString()}
                  onChange={option.value}
                >
                  {option.name} ({`${option.offset}`})
                </option>
              );
            })}
          </select>
          <span>{label}</span>
        </div>
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const TimeZoneSelect = ({
  label,
  availableTimezones,
  selectedTimezoneId,
  ...props
}: any) => {
  const [field, meta] = useField(props);

  const handleChange = (event: any) => {
    field.onChange(event);
  };

  return (
    <div>
      <div className="input-bx my-md-2 form-row w-100">
        <div className="col">
          <select
            {...field}
            {...props}
            className={` ${meta.touched && meta.error && "is-invalid"}`}
            placeholder=" "
          >
            {availableTimezones?.map((timezone: any) => (
              <option
                className="cursor-pointer"
                key={timezone.id}
                value={timezone.id.toString()}
                selected={
                  timezone.id?.toString() === selectedTimezoneId?.toString()
                    ? true
                    : false
                }
                onChange={handleChange}
              >
                {timezone.name} ({`${timezone.offset}`})
              </option>
            ))}
          </select>
          <span>{label}</span>
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const TextArea = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <input
            className={` ${meta.touched && meta.error && "is-invalid"}`}
            {...props}
            value={field.value}
            onChange={(e) => field.onChange(e)}
            placeholder=" "
          />
          <span>{label}</span>
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const PasswordField = ({
  label,
  showPassword,
  togglePasswordVisibility,
  ...props
}: any) => {
  const [field] = useField(props);

  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <input
            type={showPassword ? "text" : "password"}
            {...field}
            {...props}
            placeholder=""
          />
          <span>{label}</span>

          <i
            onClick={togglePasswordVisibility}
            className={`bi bi-eye${
              showPassword ? "" : "-slash"
            } position-absolute bottom-0 end-0 mb-2 me-3 cursor-pointer`}
          ></i>
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const RadioFieldGroup = ({
  questionId,
  optionId,
  question,
  questionSequence,
  options,
  values,
  actualQuestionId,
  ...props
}: any) => {
  const [field] = useField(props);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  useEffect(() => {
    setSelectedOption(field.value ? +field.value : null);
  }, [values]);

  const handleChange = (event: any) => {
    field.onChange(event);
    setSelectedOption(+event.target.value);
  };

  const isChecked = (optionId: number, isSelected: any) => {
    return optionId === isSelected ? true : false;
  };

  const showQuestionHandler = (questId: any, optId: any) => {
    if (
      values[questId] !== null &&
      (values[questId] === optId.toString() ||
        values[questId]?.includes(optId.toString()))
    ) {
      return true;
    } else {
      if (values[actualQuestionId]) {
        values[actualQuestionId] = null;
        setSelectedOption(null);
      }

      return false;
    }
  };

  return (
    <div className="my-4">
      {optionId === null && (
        <>
          <p className="mb-2">
            {questionSequence}) {question}
          </p>

          {options.map((option: any) => {
            return (
              <div key={option.id} className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name={props.name}
                  id={option.id}
                  type="radio"
                  value={option.id}
                  checked={isChecked(option.id, selectedOption)}
                  onChange={handleChange}
                />
                <label
                  className={`form-check-label cursor-pointer ${
                    option.id === selectedOption ? "text-primary" : ""
                  }`}
                  htmlFor={option.id}
                >
                  {option.name}
                </label>
              </div>
            );
          })}

          <ErrorMessage
            component="small"
            name={field.name}
            className="error text-danger ms-2"
          />
        </>
      )}

      {optionId !== null && showQuestionHandler(questionId, optionId) && (
        <>
          <p className="mb-2">
            {questionSequence}) {question}
          </p>

          {options.map((option: any) => {
            return (
              <div key={option.id} className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name={props.name}
                  id={option.id}
                  type="radio"
                  value={option.id}
                  checked={isChecked(option.id, selectedOption)}
                  onChange={handleChange}
                />
                <label
                  className={`form-check-label cursor-pointer ${
                    option.id === selectedOption ? "text-primary" : ""
                  }`}
                  htmlFor={option.id}
                >
                  {option.name}
                </label>
              </div>
            );
          })}

          <ErrorMessage
            component="small"
            name={field.name}
            className="error text-danger ms-2"
          />
        </>
      )}
    </div>
  );
};

export const CheckBoxFieldGroup = ({
  questionId,
  optionId,
  question,
  questionSequence,
  options,
  values,
  actualQuestionId,
  ...props
}: any) => {
  const [field] = useField(props);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const isChecked = (checkedValue: number) => {
    return field.value?.includes(checkedValue.toString());
  };

  const handleChange = (event: any) => {
    field.onChange(event);
    setSelectedOptions(field.value);
  };

  const showQuestionHandler = (questId: any, optId: any) => {
    if (
      values[questId] !== null &&
      (values[questId]?.includes(optId.toString()) ||
        values[questId] === optId.toString())
    ) {
      return true;
    } else {
      if (values[actualQuestionId]) {
        values[actualQuestionId] = null;
        setSelectedOptions([]);
      }
      return false;
    }
  };

  return (
    <div className="my-4">
      {optionId === null && (
        <>
          <p className="mb-2">
            {questionSequence}) {question}
          </p>

          {options.map((option: any) => {
            return (
              <div key={option.id} className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name={props.name}
                  id={option.id}
                  type="checkbox"
                  value={option.id}
                  checked={isChecked(option.id)}
                  onChange={handleChange}
                />
                <label
                  className={`form-check-label cursor-pointer ${
                    isChecked(option.id) ? "text-primary" : ""
                  }
              `}
                  htmlFor={option.id}
                >
                  {option.name}
                </label>
              </div>
            );
          })}

          <ErrorMessage
            component="small"
            name={field.name}
            className="error text-danger ms-2"
          />
        </>
      )}

      {optionId !== null && showQuestionHandler(questionId, optionId) && (
        <>
          <p className="mb-2">
            {questionSequence}) {question}
          </p>

          {options.map((option: any) => {
            return (
              <div key={option.id} className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name={props.name}
                  id={option.id}
                  type="checkbox"
                  value={option.id}
                  checked={isChecked(option.id)}
                  onChange={handleChange}
                />
                <label
                  className={`form-check-label cursor-pointer ${
                    isChecked(option.id) ? "text-primary" : ""
                  }
              `}
                  htmlFor={option.id}
                >
                  {option.name}
                </label>
              </div>
            );
          })}

          <ErrorMessage
            component="small"
            name={field.name}
            className="error text-danger ms-2"
          />
        </>
      )}
    </div>
  );
};

export const MobileField = ({ label, ...props }: any) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.startsWith("+1")) {
      helpers.setValue(value);
    } else {
      const numericValue = value.replace(/[^0-9]/g, "");
      const prefixedValue = numericValue ? `+1${numericValue}` : "";
      helpers.setValue(prefixedValue);
    }
  };

  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <input
            className={` ${meta.touched && meta.error ? "is-invalid" : ""}`}
            {...props}
            value={field.value}
            onChange={handleChange}
            placeholder=" "
          />
          <span>{label}</span>
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};
