import { useContext, useState } from "react";
import { Container, Row, Col, Spinner as Loader } from "react-bootstrap";
import Logo_Mobile from "../assets/images/Nydra_Well_logo_FINAL_2 26.png";
import { Form, Formik } from "formik";
import { TextField } from "../utils/Fromcontrol";
import * as Yup from "yup";
import { LoginType } from "../types/LoginType";
import axios from "../utils/axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/authContext1";
import ForgotPasswordModal from "../components/ForgotPasswordModal";
import Nydra_well from "../assets/images/nidrawell-Logo.png";
import { ToastContainer, toast } from "react-toastify";

const LoginPage = () => {
  const authCtx = useContext(AuthContext);
  const [isForgotPasswordModal, setIsForgotPasswordModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handleCloseForgotPasswordModal = () => setIsForgotPasswordModal(false);
  const handleShowForgotPasswordModal = () => setIsForgotPasswordModal(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const Navigate = useNavigate();

  const initialValues = {
    email: userName,
    password: password,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .required("Password is required"),
  });

  const onSubmit = (values: LoginType) => {
    setIsLoading(true);
    const { email, password } = values;
    setUserName(email);
    setPassword(password);
    axios
      .post(
        "/login",
        {
          email: email,
          password: password,
          type: "web",
          fcm_token: null,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        const { status } = response.data;

        if (response.status === 200) {
          if (status) {
            setUserName("");
            setPassword("");
            authCtx.login(
              response.data.token,
              response.data.profile.user_id,
              response.data.role,
              response.data.profile.timezone,
              response.data.refreshToken,
              response.data.is_fitbit
            );

            if (response.data.role === "patient") {
              if (response.data.isFirstLogin === true) {
                authCtx.subscriptionFlowStepHandler(
                  response.data.isFirstLogin,
                  response.data.isScreen
                );
                Navigate("/patientsubscription");
              } else {
                Navigate("/dashboard");
                authCtx.subscriptionFlowNotRequiredHandler(
                  response.data.isFirstLogin
                );
              }
            } else if (response.data.role === "doctor") {
              Navigate("/doctorappointments");
            } else {
              Navigate("/managedoctors");
            }
          } else {
            toast.error(`${response?.data?.message}`);
          }
        }
      })
      .catch((err) => {
        toast.warning("Something went wrong");
        setPassword("");
      })
      .finally(() => setIsLoading(false));
  };

  const LoginComponent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          return (
            <>
              <Form className="px-md-5">
                <div className="row">
                  <div className="col-12 my-2 my-md-2">
                    <TextField type="email" name="email" label="Email Id" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 my-2 my-md-2">
                    <TextField
                      type="password"
                      name="password"
                      label="Password"
                    />
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-12 d-flex justify-content-end fw-600 "
                    onClick={handleShowForgotPasswordModal}
                    style={{ cursor: "pointer" }}
                  >
                    <small className=" text-purple">Forgot Password ?</small>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <button
                      className="btn btn-purple py-2 rounded-0 fw-normal text- fw-bold w-100"
                      type="submit"
                    >
                      {isLoading ? (
                        <>
                          <Loader
                            animation="border"
                            variant="white"
                            size="sm"
                            className="me-3"
                          />
                          Logging in, please wait...
                        </>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </div>
                <div className="my-md-4">
                  <h2 className="line_hr">
                    <span>OR</span>
                  </h2>
                </div>

                <div className="mt-md-3 d-flex justify-content-center">
                  <>
                    <div
                      className="py-2 rounded-0 fw-500 d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      Don't Have an Account ?
                      <span
                        className="ms-0 ms-md-2 text-purple"
                        onClick={() => Navigate("/signup")}
                      >
                        SIGN UP
                      </span>
                    </div>
                  </>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      {/* {isLoading && <Spinner />} */}
      <ForgotPasswordModal
        isForgotPasswordModal={isForgotPasswordModal}
        handleCloseForgotPasswordModal={handleCloseForgotPasswordModal}
      />
      <ToastContainer />
      <Container fluid={true} className=" px-xl-5 d-none d-md-block">
        <div className="position-absolute d-none d-md-block login_card">
          <Row className="bg-light rounded">
            <Col md={6} className="bg_dark position-relative ">
              <div className="position-absolute image_login ">
                <img src={Nydra_well} alt="company_logo" />
              </div>
            </Col>

            <Col md={6} className="py-md-4 px-md-3 my-xxl-4">
              <h4 className="d-flex justify-content-center my-md-3 text-uppercase">
                Login
              </h4>
              <LoginComponent />

              <div className=" d-flex justify-content-center">
                <button
                  className="btn btn-white rounded-0 fw-normal text-purple fw-bold d-flex align-items-center "
                  onClick={() => Navigate("/")}
                >
                  <i className="bi-arrow-left-short fs-4"></i>Back to Home Page
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* mobile code */}
      <Container fluid={true} className="d-block d-md-none py-5">
        <Row>
          <Col className="col-12 col-md-0 d-flex justify-content-center align-items-center">
            <img src={Logo_Mobile} alt="Comapny_logo" />
          </Col>

          <Col className="col-12 col-md-0 my-4">
            <h4 className="d-flex justify-content-center mt-md-2 mt-3">
              Login
            </h4>
            <LoginComponent />

            <div className=" d-flex justify-content-center">
              <button
                className="btn btn-white rounded-0 fw-normal text-purple fw-bold d-flex align-items-center"
                style={{ outline: "none" }}
              >
                <i className="bi-arrow-left-short fs-4"></i>Back to Home Page
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
