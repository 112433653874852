import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import {
  ChangePasswordType,
  ChangePasswordModalProps,
} from "../types/ChangePasswordType";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "../utils/Fromcontrol";
import { toast } from "react-toastify";
import useAxiosPrivate from "../utils/useAxiosPrivate";

const ChangePasswordModal = ({
  show,
  handleClose,
}: ChangePasswordModalProps) => {
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
  });
  const submitHandler = (values: ChangePasswordType, actions: any) => {
    toastId.current = toast.loading("Loading...");
    const { password, confirmPassword } = values;
    axiosPrivate({
      method: "POST",
      url: "/changepassword",
      data: {
        password: password,
        confirmPassword: confirmPassword,
      },
    })
      .then((response) => {
        if (response.data.status) {
          actions.resetForm({
            values: {
              password: "",
              confirmPassword: "",
            },
          });
          handleClose();
          toast.update(toastId.current, {
            render: "Your Password has been updated successfully",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        fullscreen="md-down"
      >
        <div className="d-flex align-items-center justify-content-between px-4 py-2 bg-purple">
          <div>
            <h5 className="text-white">Change Password</h5>
          </div>
          <div className="d-flex justify-content-end" onClick={handleClose}>
            <i className="bi bi-x-lg text-white"></i>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          <Form className="px-md-5 py-md-4 px-3">
            <div className="my-md-4 my-3">
              <TextField name="password" type="password" label="New Password" />
            </div>
            <div className="my-3 my-md-0">
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
              />
            </div>

            <div className="d-flex justify-content-end mt-md-3">
              <button
                type="submit"
                className="btn btn-purple text-white rounded-0 px-4 py-1 rounded-1"
              >
                Save
              </button>
              <button
                className="btn btn-purple text-white rounded-0 px-4 py-1 ms-3  rounded-1"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
