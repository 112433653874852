import { useEffect, useContext, useRef } from "react";
import axiosPrivate from "./axios";
import AuthContext from "../context/authContext1";
import axios from "./axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useAxiosPrivate = () => {
  const toastId = useRef<any>(null);
  const Navigate = useNavigate();
  const { token, refreshToken, refreshTokenSetHandler, logout } =
    useContext(AuthContext);

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    let isRefreshing = false;
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              await axios
                .post("/generateToken", {
                  refreshToken: refreshToken,
                })
                .then((res) => {
                  if (res.data.status) {
                    const { access_token, refresh_token } = res.data.data;
                    refreshTokenSetHandler(access_token, refresh_token);
                    originalRequest.headers.Authorization = `Bearer ${access_token}`;
                    return axios(originalRequest);
                  } else {
                    logout();
                    Navigate("/login");
                  }
                });
            } catch (err: any) {
              return;
            } finally {
              isRefreshing = false; // Reset the lock after the operation completes
            }
          } else {
            // If already refreshing, wait for the first request to complete and retry the original request
            // You can potentially queue the original requests and resolve them once the token is refreshed.
            // For simplicity, this example just returns the original request as-is.
            return axios(originalRequest);
          }
        } else if (error?.response?.status === 500) {
          toast.update(toastId.current, {
            render: "Something went wrong",
            type: "error",
          });
        } else if (error?.response?.code === "ERR_NETWORK") {
          toast.update(toastId.current, {
            render:
              "Connection Timed Out.Check your internet connection and try again.",
            type: "error",
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refreshToken]);

  return axiosPrivate;
};

export default useAxiosPrivate;
