import React from "react";
import { Container } from "react-bootstrap";
import check from "../../assets/images/check.svg";
import watch from "../../assets/images/watch.svg";
import computer from "../../assets/images/computer.svg";
import Apple from "../../assets/images/apple.svg";
import fitbit from "../../assets/images/Fitbit.svg";
import Whoop from "../../assets/images/Whoop.svg";

const HowItWorkComponent = () => {
  const howItCardData = [
    {
      id: 1,
      step: "STEP 1",
      color: "dark_blue",
      image: check,
      alttext: "CheckList_image",
      heading: "Create a comprehensive sleep profile ",
      content:
        "Utilize the Questionnaire to Chart Your Sleep Across 18 Distinct Data Points.",
    },
    {
      id: 2,
      step: "STEP 2",
      color: "button_green",
      image: watch,
      alttext: "watch_imafe",
      heading: "Connect your wearable device  ",
      content:
        "Monitoring Your Sleep Schedule, Duration, and Quality for Enhanced Understanding with Your ",
    },
    {
      id: 3,
      step: "STEP 3",
      color: "button_light_green",
      image: computer,
      alttext: "computer_image",
      heading: "Book your video assessment with your sleep coach. ",
      content:
        "Three Individualized Sessions with Your Sleep Coach: Crafting, Executing, and Monitoring Strategies for Optimal Habit Formation Over 12 weeks.",
    },
  ];
  const HowCard = ({ step, color, image, alttext, heading, content }: any) => {
    return (
      <div className="mx-md-3 pb-3">
        <div className="how_it_work_card p-4">
          <div className="text-center ">
            <button
              className={`btn fw-bold text-white rounded-pill px-md-4 py-md-2 bg-${color}`}
            >
              {step}
            </button>
          </div>
          <div className="py-3 py-md-4 border-bottom text-center">
            <img src={image} alt={alttext} className="how_itWork_image" />
          </div>
          <div className="pt-3 pt-md-4">
            <h5 className="how_it_work_heading">{heading}</h5>
            <p className="how_it_work_p"> {content}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="how_it_works pb-md-5 d-none d-md-block">
        <Container>
          <div className="py-md-5">
            <h4 className="how_it_works_heading ">How it Works</h4>
          </div>
          <div className="d-flex justify-content-around align-items-center">
            {howItCardData.map((data: any) => {
              return (
                <div key={data.id}>
                  <HowCard
                    step={data.step}
                    color={data.color}
                    image={data.image}
                    heading={data.heading}
                    content={data.content}
                  />
                </div>
              );
            })}
          </div>
          <div className="text-center displayDevices py-md-3">
            <img src={Apple} alt="apple" />
            <img src={fitbit} alt="fitbit" className="mx-md-5" />
            <img src={Whoop} alt="Whoop" />
          </div>
        </Container>
      </div>
      <Container fluid className="d-block d-md-none how_it_works">
        <div className="py-4">
          <h4 className="how_it_works_heading ">How it Works</h4>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {howItCardData.map((data: any) => {
            return (
              <div key={data.id}>
                <HowCard
                  step={data.step}
                  color={data.color}
                  image={data.image}
                  heading={data.heading}
                  content={data.content}
                />
              </div>
            );
          })}
        </div>
        <div className="text-center displayDevices py-2 px-2">
          <img
            src={Apple}
            alt="apple"
            style={{ width: "102.572px", height: "37.299px" }}
          />
          <img
            src={fitbit}
            alt="fitbit"
            className="mx-2"
            style={{ width: "102.572px", height: "37.299px" }}
          />
          <img
            src={Whoop}
            alt="Whoop"
            style={{ width: "102.572px", height: "37.299px" }}
          />
        </div>
      </Container>
    </>
  );
};

export default HowItWorkComponent;
