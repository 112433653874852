import React from "react";
import WeightLossComponent from "../components/landing/WeightLossComponent";
import FrequentlyAskedQuestions from "../components/landing/FrequentlyAskedQuestions";
import InitialProgram from "../components/landing/InitialProgram";

const WeightLossPage = () => {
  return (
    <>
      <WeightLossComponent />
      <InitialProgram />
      <FrequentlyAskedQuestions />
    </>
  );
};

export default WeightLossPage;
