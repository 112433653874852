import React from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import sleepHygiene from "../../assets/images/sleepHizen.svg";
import peaceful from "../../assets/images/peaceful-sleep-concept.svg";
import Sleep_deprivation from "../../assets/images/SLEEP-DEPRIVATION.svg";
import styles from "./BlogAccordion.module.scss";

const BlogsPage = () => {
  return (
    <Container className={`${styles.BlogPage}`}>
      <Accordion className={`${styles.blogcustomAccordian}`}>
        <Accordion.Item eventKey="0" bsPrefix={`${styles.blogaccordianItem}`}>
          <Accordion.Header bsPrefix={`${styles.blogPageSubHeading}`}>
            <span
              style={{
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Nydra Well sleep Improvement approach and the science behind
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <h3 className="blogPageSubHeading">
                Personalized sleep hygiene counseling was effective in
                increasing sleep duration
              </h3>
              <div>
                <p className="blogPageParaOne">
                  <span className="blogsPageT">T</span>he Nydra Well approach is
                  developed through collaboration among sleep physicians and
                  behavioral scientists, incorporating principles from both
                  Cognitive Behavioral Therapy for Insomnia (CBT-i) and chronic
                  care management to provide comprehensive support. Below are
                  several clinical studies demonstrating the effectiveness of
                  CBT-i and sleep hygiene, upon which Nydra Well's principles
                  are founded.
                </p>
              </div>
              <Row>
                <Col className="col-md-6 col-12 d-flex align-items-center">
                  <img src={sleepHygiene} alt="sleepHygiene" width="100%" />
                </Col>
                <Col className="col-md-6 col-12">
                  <h3 className="blogPagekeyFinding">Key Findings:</h3>
                  <div className="p-0 m-0">
                    <ul>
                      <li className="blogPageli">
                        Participants who received personalized sleep hygiene
                        counseling were able to increase their sleep duration by
                        an average of 1.2 hours per night.
                      </li>
                      <li className="blogPageli">
                        The increase in sleep duration was a significant
                        improvement compared to participants who received
                        general sleep education
                      </li>
                      <li className="blogPageli">
                        The counseling session likely helped participants make
                        behavioral changes that promoted better sleep habits and
                        improved sleep quality.
                      </li>
                    </ul>
                  </div>
                  <h3 className="blogPagekeyFinding">Conclusion:</h3>
                  <div className="p-0 m-0">
                    <ul>
                      <li className="blogPageli">
                        Improving sleep duration through personalized
                        interventions may have important implications for
                        overall health and well-being, including weight
                        management and metabolic health.
                      </li>
                      <li className="blogPageli">
                        The study concluded that personalized sleep hygiene
                        counseling was effective in increasing sleep duration in
                        young, overweight adults with habitual short sleep
                        duration.
                      </li>
                      <li className="blogPageli">
                        This study highlights the potential benefits of
                        personalized sleep interventions in addressing
                        inadequate sleep duration, particularly among
                        populations at risk for health issues such as overweight
                        and obesity.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <div className="my-md-4 d-none d-md-block">
                <p className="blogPageLink">
                  <a
                    href="https://www.uchicagomedicine.org/forefront/research-and-discoveries-articles/getting-more-sleep-reduces-caloric-intake"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.uchicagomedicine.org/forefront/research-and-discoveries-articles/getting-more-sleep-reduces-caloric-intake
                  </a>
                </p>
              </div>
            </div>
            <div className="my-4 d-block d-md-none">
              <p className="blogPageLink">
                <a
                  href="https://www.uchicagomedicine.org/forefront/research-and-discoveries-articles/getting-more-sleep-reduces-caloric-intake"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to External Link
                </a>
              </p>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                The effects of sleep hygiene education on sleep quality in
                patients with hypertension: A randomized controlled trial
              </h3>
              <Row className="mt-md-5">
                <Col className="col-md-6 col-12">
                  <h3 className="blogPagekeyFinding">Key Findings:</h3>
                  <div className="p-0 m-0">
                    <ul>
                      <li className="blogPageli">
                        The intervention group showed significant improvements
                        in sleep quality compared to the control group.
                      </li>
                      <li className="blogPageli">
                        Additionally, the intervention group demonstrated
                        reductions in symptoms of depression compared to the
                        control group.
                      </li>
                    </ul>
                  </div>
                  <h3 className="blogPagekeyFinding">Conclusion:</h3>
                  <div className="p-0 m-0">
                    <ul>
                      <li className="blogPageli">
                        The study concluded that sleep hygiene education is
                        effective in improving sleep quality and reducing
                        symptoms of depression. Providing information and
                        guidance on sleep hygiene can be a valuable intervention
                        for individuals experiencing sleep disturbances and
                        associated mood symptoms.
                      </li>
                      <li className="blogPageli">
                        This study highlights the potential benefits of sleep
                        hygiene education in promoting healthy sleep habits and
                        addressing sleep-related problems, such as insomnia and
                        depression. It underscores the importance of
                        incorporating sleep hygiene principles into clinical
                        practice to improve overall sleep quality and mental
                        well-being.
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col className="col-md-6 col-12 d-flex align-items-center">
                  <img src={peaceful} alt="sleepHygiene" width="100%" />
                </Col>
              </Row>
              <div className="my-md-4 d-none d-md-block">
                <p className="blogPageLink">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5296457/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5296457/
                  </a>
                </p>
              </div>
              <div className="my-4 d-block d-md-none">
                <p className="blogPageLink">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5296457/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to External Link
                  </a>
                </p>
              </div>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                The effects of sleep hygiene education on sleep quality and
                depression: A randomized controlled trial
              </h3>
              <Row className="mt-md-5">
                <Col className="col-md-6 col-12 d-flex align-items-center">
                  <img
                    src={Sleep_deprivation}
                    alt="sleepHygiene"
                    width="100%"
                  />
                </Col>
                <Col className="col-md-6 col-12">
                  <h3 className="blogPagekeyFinding">Key Findings:</h3>
                  <div className="p-0 m-0">
                    <ul>
                      <li className="blogPageli">
                        The sleep hygiene education group showed significant
                        improvements in sleep quality compared to the control
                        group.
                      </li>
                      <li className="blogPageli">
                        Participants who received sleep hygiene education
                        demonstrated greater reductions in sleep disturbances
                        and improvements in sleep efficiency compared to those
                        who did not receive the intervention.
                      </li>
                    </ul>
                  </div>
                  <h3 className="blogPagekeyFinding">Conclusion:</h3>
                  <div className="p-0 m-0">
                    <ul>
                      <li className="blogPageli">
                        This randomized controlled trial concluded that sleep
                        hygiene education is effective in improving sleep
                        quality in patients with hypertension. Providing
                        information and guidance on sleep hygiene can be a
                        valuable intervention for improving sleep disturbances
                        and promoting better sleep quality in this population.
                      </li>
                      <li className="blogPageli">
                        This study highlights the potential benefits of
                        incorporating sleep hygiene education into the
                        management of hypertension, as improving sleep quality
                        may have positive effects on blood pressure control and
                        overall cardiovascular health.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              {/* <div className="my-md-4">
                <p className="blogPageLink">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5296457/
                "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to External Link
                  </a>
                </p>
              </div> */}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" bsPrefix={`${styles.blogaccordianItem}`}>
          <Accordion.Header bsPrefix={`${styles.blogPageSubHeading}`}>
            <span
              style={{
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Sleep and Weight Loss
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p className="blogPageParaOne">
                <span className="blogsPageT">T</span>he connection between sleep
                and weight gain is intricate and multifaceted. Below, several
                clinical studies elucidate different facets of this intricate
                relationship.
              </p>
            </div>
            <div>
              <h3 className="blogPageSubHeading">
                Impact of sleep duration on energy intake and weight gain
              </h3>
              <div>
                <Row>
                  <Col>
                    <h3 className="blogPagekeyFinding text-center">
                      Key Findings:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          Participants consumed significantly more calories (an
                          average increase of 22%) during the period of
                          restricted sleep compared to normal sleep.
                        </li>
                        <li className="blogPageli">
                          The increase in calorie intake was primarily due to
                          greater consumption of snacks in the late evening and
                          early morning hours.
                        </li>
                        <li className="blogPageli">
                          Despite the increased caloric intake, participants did
                          not expend more energy during the period of restricted
                          sleep. In fact, energy expenditure remained relatively
                          stable.
                        </li>
                      </ul>
                    </div>
                    <h3 className="blogPagekeyFinding text-center">
                      Conclusion:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          The study concluded that sleep restriction led to
                          increased caloric intake, particularly from snacks
                          consumed during late-night hours.
                        </li>
                        <li className="blogPageli">
                          The findings suggest that inadequate sleep may
                          contribute to weight gain by promoting overeating,
                          without a compensatory increase in energy expenditure.
                        </li>
                        <li className="blogPageli">
                          This study provides valuable insights into the
                          relationship between sleep duration and caloric
                          intake, highlighting the potential role of sleep in
                          regulating appetite and food consumption.
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col>
                <img src={sleepHygiene} alt="sleepHygiene" />
              </Col> */}
                </Row>
                <div className="my-md-4 d-none d-md-block">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/17308390/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://pubmed.ncbi.nlm.nih.gov/17308390/
                    </a>
                  </p>
                </div>
                <div className="my-md-4 d-block d-md-none">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/17308390/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to External Link
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                Impact of insufficient sleep on total daily energy expenditure,
                food intake, and weight gain
              </h3>
              <div>
                <Row>
                  <Col>
                    <h3 className="blogPagekeyFinding text-center">
                      Key Findings:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          The study found that insufficient sleep led to a
                          reduction in total daily energy expenditure, meaning
                          participants burned fewer calories when sleep-deprived
                          compared to when they had adequate sleep.
                        </li>
                        <li className="blogPageli">
                          Additionally, participants consumed more food
                          (calories) when sleep-deprived compared to when they
                          had normal sleep duration.
                        </li>
                        <li className="blogPageli">
                          The combination of decreased energy expenditure and
                          increased food intake resulted in a positive energy
                          balance, contributing to weight gain over time.
                        </li>
                        <li className="blogPageli">
                          These findings suggest that insufficient sleep may
                          disrupt the balance between energy intake and
                          expenditure, leading to weight gain and potentially
                          increasing the risk of obesity.
                        </li>
                      </ul>
                    </div>
                    <h3 className="blogPagekeyFinding text-center">
                      Conclusion:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          The study concluded that insufficient sleep can
                          negatively impact total daily energy expenditure, food
                          intake, and weight gain.
                        </li>
                        <li className="blogPageli">
                          These findings highlight the importance of adequate
                          sleep in maintaining energy balance and preventing
                          weight gain.
                        </li>
                        <li className="blogPageli">
                          Addressing sleep deficiencies may be an important
                          aspect of weight management and obesity prevention
                          strategies.
                        </li>
                        <li className="blogPageli">
                          This study provides valuable insights into the
                          physiological mechanisms linking sleep duration with
                          energy metabolism and weight regulation, emphasizing
                          the importance of promoting healthy sleep habits for
                          overall health and well-being.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <div className="my-md-4 d-none d-md-block">
                  <p className="blogPageLink">
                    <a
                      href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3619301/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3619301/
                    </a>
                  </p>
                </div>
                <div className="my-4 d-block d-md-none">
                  <p className="blogPageLink">
                    <a
                      href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3619301/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to External Link
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                Association of sleep duration with adolescents' fat and
                carbohydrate consumption
              </h3>
              <div>
                <Row>
                  <Col className="p-0 m-0">
                    <h3 className="blogPagekeyFinding text-center">
                      Key Findings:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          Adolescents with short sleep duration consumed
                          significantly more fat and fewer carbohydrates
                          compared to those with optimal sleep duration.
                        </li>
                        <li className="blogPageli">
                          Conversely, adolescents with long sleep duration
                          consumed significantly fewer fats and more
                          carbohydrates compared to those with optimal sleep
                          duration.
                        </li>
                        <li className="blogPageli">
                          These associations remained significant even after
                          adjusting for potential confounding factors such as
                          age, sex, physical activity, and socioeconomic status.
                        </li>
                      </ul>
                    </div>
                    <h3 className="blogPagekeyFinding text-center">
                      Conclusion:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          This study demonstrates a significant association
                          between sleep duration and adolescents' fat and
                          carbohydrate consumption. Adolescents with short sleep
                          duration tended to consume more fats and fewer
                          carbohydrates, while those with long sleep duration
                          tended to consume fewer fats and more carbohydrates.
                          These findings suggest that sleep duration may
                          influence dietary behaviors in adolescents, which
                          could have implications for their overall health and
                          well-being.
                        </li>
                        <li className="blogPageli">
                          This study provides valuable insights into the
                          relationship between sleep duration and dietary habits
                          among adolescents, highlighting the importance of
                          adequate sleep for promoting healthy eating behaviors.
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
                </Row>
                <div className="my-md-4 d-none d-md-block">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/20857867/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://pubmed.ncbi.nlm.nih.gov/20857867/
                    </a>
                  </p>
                </div>
                <div className="my-4 d-block d-md-none">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/20857867/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to External Link
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                Changes in sleep duration and visceral adipose tissue
                accumulation (belly fat/abdominal fat) in adults
              </h3>
              <div>
                <Row>
                  <Col className="p-0 m-0">
                    <h3 className="blogPagekeyFinding text-center">
                      Key Findings:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          Shortening of sleep duration over the 6-year period
                          was associated with an increase in VAT accumulation,
                          independent of baseline VAT and other covariates. .
                        </li>
                        <li className="blogPageli">
                          Participants who decreased their sleep duration by 2
                          hours or more per day experienced a greater increase
                          in VAT accumulation compared to those with stable
                          sleep duration.
                        </li>
                        <li className="blogPageli">
                          Conversely, participants who increased their sleep
                          duration by 2 hours or more per day had a smaller
                          increase in VAT accumulation compared to those with
                          stable sleep duration.
                        </li>
                      </ul>
                    </div>
                    <h3 className="blogPagekeyFinding text-center">
                      Conclusion:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          This study suggests that changes in sleep duration
                          over a 6-year period are associated with changes in
                          visceral adipose tissue accumulation in adults.
                          Shortening of sleep duration was linked to greater VAT
                          accumulation, while lengthening of sleep duration was
                          associated with smaller increases in VAT. These
                          findings underscore the importance of sleep duration
                          in the regulation of adipose tissue distribution and
                          metabolic health.
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
                </Row>
                <div className="my-md-4 d-none d-md-block">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/24420871/#:~:text=Results%3A%20Participants%20gained%20an%20average,respectively%2C%20P%20%3C%200.05"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://pubmed.ncbi.nlm.nih.gov/24420871/#:~:text=Results%3A%20Participants%20gained%20an%20average,respectively%2C%20P%20%3C%200.05
                    </a>
                  </p>
                </div>
                <div className="my-4 d-block d-md-none">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/24420871/#:~:text=Results%3A%20Participants%20gained%20an%20average,respectively%2C%20P%20%3C%200.05"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to External Link
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                The effects of sleep restriction on energy metabolism and
                appetite
              </h3>
              <div>
                <Row>
                  <Col className="p-0 m-0">
                    <h3 className="blogPagekeyFinding text-center">
                      Key Findings:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          Sleep restriction led to a decrease in resting
                          metabolic rate compared to normal sleep, indicating
                          reduced energy expenditure.
                        </li>
                        <li className="blogPageli">
                          Sleep-restricted participants had impaired glucose
                          metabolism, with higher insulin resistance compared to
                          when they had normal sleep duration.
                        </li>
                        <li className="blogPageli">
                          Sleep restriction resulted in decreased levels of
                          leptin (the hormone that suppresses appetite) and
                          increased levels of ghrelin (the hormone that
                          stimulates appetite), leading to increased feelings of
                          hunger and appetite.
                        </li>
                        <li className="blogPageli">
                          Subjective ratings of hunger and appetite were higher
                          during the period of sleep restriction compared to
                          normal sleep.
                        </li>
                      </ul>
                    </div>
                    <h3 className="blogPagekeyFinding text-center">
                      Conclusion:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          This study demonstrates that sleep restriction has
                          adverse effects on energy metabolism and appetite
                          regulation. Sleep-deprived individuals experience
                          reduced energy expenditure, impaired glucose
                          metabolism, and dysregulated appetite hormones, which
                          may contribute to increased hunger and overeating.
                          These findings highlight the importance of adequate
                          sleep in maintaining metabolic health and regulating
                          appetite.
                        </li>
                        <li className="blogPageli">
                          This study provides valuable insights into the
                          physiological mechanisms underlying the relationship
                          between sleep restriction, energy metabolism, and
                          appetite regulation, emphasizing the detrimental
                          effects of insufficient sleep on metabolic health and
                          weight regulation.
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
                </Row>
                <div className="my-md-4 d-none d-md-block">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/30870662/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://pubmed.ncbi.nlm.nih.gov/30870662/
                    </a>
                  </p>
                </div>
                <div className="my-4 d-block d-md-none">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/30870662/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to External Link
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="blogPageSubHeading">
                Sleep curtailment in healthy young men is associated with
                decreased leptin levels, elevated ghrelin levels, and increased
                hunger and appetite
              </h3>
              <div>
                <Row>
                  <Col className="p-0 m-0">
                    <h3 className="blogPagekeyFinding text-center">
                      Key Findings:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          Sleep curtailment led to decreased levels of leptin
                          (the hormone that suppresses appetite) compared to
                          normal sleep duration.
                        </li>
                        <li className="blogPageli">
                          Sleep-deprived participants had elevated levels of
                          ghrelin (the hormone that stimulates appetite)
                          compared to when they had normal sleep duration.
                        </li>
                        <li className="blogPageli">
                          Subjective ratings of hunger and appetite were
                          significantly higher during the period of sleep
                          curtailment compared to normal sleep.
                        </li>
                      </ul>
                    </div>
                    <h3 className="blogPagekeyFinding text-center">
                      Conclusion:
                    </h3>
                    <div className="p-0 m-0">
                      <ul>
                        <li className="blogPageli">
                          This study demonstrates that sleep curtailment in
                          healthy young men is associated with alterations in
                          appetite-regulating hormones, including decreased
                          leptin levels and elevated ghrelin levels, leading to
                          increased feelings of hunger and appetite. These
                          findings suggest that insufficient sleep may
                          dysregulate appetite control mechanisms, contributing
                          to overeating and weight gain.
                        </li>
                        <li className="blogPageli">
                          This study provides important insights into the
                          physiological mechanisms underlying the association
                          between sleep curtailment and changes in
                          appetite-regulating hormones, highlighting the
                          potential role of sleep in regulating hunger and
                          appet.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <div className="my-md-4 d-none d-md-block">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/15583226/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://pubmed.ncbi.nlm.nih.gov/15583226/
                    </a>
                  </p>
                </div>
                <div className="my-4 d-block d-md-none">
                  <p className="blogPageLink">
                    <a
                      href="https://pubmed.ncbi.nlm.nih.gov/15583226/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to External Link
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" bsPrefix={`${styles.blogaccordianItem}`}>
          <Accordion.Header bsPrefix={`${styles.blogPageSubHeading}`}>
            <span
              style={{
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Sleep and Athletes
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p className="blogPageParaOne">
                <span className="blogsPageT">S</span>leep plays a critical role
                in athletic performance, influencing various aspects such as
                physical endurance, reaction time, decision-making, and injury
                prevention. Adequate sleep duration and quality are essential
                for optimal recovery, muscle repair, and overall well-being.
                Research shows that sleep deprivation can impair cognitive
                function, decrease reaction times, and increase the risk of
                sports-related injuries. Athletes should prioritize sleep
                hygiene practices and ensure sufficient rest to maximize
                performance and achieve their full potential. Below are some
                clinical studies proving the association
              </p>
            </div>
            <div className="my-md-2">
              <h3 className="blogPageSubHeading text-start">
                Reaction Time and Speedn
              </h3>
              <div>
                <Row>
                  <Col>
                    <div className="p-0 m-0 blogPageli">
                      <span className="fw-600 text-black">Study :</span> Mah CD,
                      Mah KE, Kezirian EJ, Dement WC. The effects of sleep
                      extension on the athletic performance of collegiate
                      basketball players. Sleep. 2011;34(7):943-950.
                    </div>
                    <div className="p-0 m-0 blogPageli mt-md-3">
                      <span className="fw-600 text-black">Findings :</span> This
                      study showed that increased sleep duration led to
                      improvements in reaction time, speed, and shooting
                      accuracy among collegiate basketball players.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="my-md-2">
              <h3 className="blogPageSubHeading text-start">
                Accuracy and Cognitive Function
              </h3>
              <div>
                <Row>
                  <Col>
                    <div className="p-0 m-0 blogPageli">
                      <span className="fw-600 text-black">Study :</span>{" "}
                      Fullagar HH, Skorski S, Duffield R, Hammes D, Coutts AJ,
                      Meyer T. Sleep and athletic performance: the effects of
                      sleep loss on exercise performance, and physiological and
                      cognitive responses to exercise. Sports Med.
                      2015;45(2):161-186.
                    </div>
                    <div className="p-0 m-0 blogPageli mt-md-3">
                      <span className="fw-600 text-black">Findings :</span> This
                      review highlights the negative impact of sleep loss on
                      cognitive function, including attention, decision-making,
                      and motor control, which can affect athletic performance.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="my-md-2">
              <h3 className="blogPageSubHeading text-start">
                Strength and Power
              </h3>
              <div>
                <Row>
                  <Col>
                    <div className="p-0 m-0 blogPageli">
                      <span className="fw-600 text-black">Study :</span> Reilly
                      T, Piercy M. The effect of partial sleep deprivation on
                      weight-lifting performance. Ergonomics.
                      1994;37(1):107-115.
                    </div>
                    <div className="p-0 m-0 blogPageli mt-md-3">
                      <span className="fw-600 text-black">Findings :</span> This
                      study demonstrated that partial sleep deprivation led to
                      reduced performance in weight-lifting exercises,
                      indicating a negative impact on strength and power output.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="my-md-2">
              <h3 className="blogPageSubHeading text-start">Endurances</h3>
              <div>
                <Row>
                  <Col>
                    <div className="p-0 m-0 blogPageli">
                      <span className="fw-600 text-black">Study :</span> Oliver
                      SJ, Costa RJ, Laing SJ, Bilzon JL, Walsh NP. One night of
                      sleep deprivation decreases treadmill endurance
                      performance. Eur J Appl Physiol. 2009;107(2):155-161.
                    </div>
                    <div className="p-0 m-0 blogPageli mt-md-3">
                      <span className="fw-600 text-black">Findings :</span>This
                      study showed that one night of sleep deprivation resulted
                      in decreased endurance performance on a treadmill,
                      indicating the importance of sleep for sustained physical
                      effort.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="my-md-2">
              <h3 className="blogPageSubHeading text-start">Recovery</h3>
              <div>
                <Row>
                  <Col>
                    <div className="p-0 m-0 blogPageli">
                      <span className="fw-600 text-black">Study :</span> Halson
                      SL. Sleep in elite athletes and nutritional interventions
                      to enhance sleep. Sports Med. 2014;44 Suppl 1(Suppl
                      1):S13-S23.
                    </div>
                    <div className="p-0 m-0 blogPageli mt-md-3">
                      <span className="fw-600 text-black ">Findings :</span>{" "}
                      This review discusses the role of sleep in recovery
                      processes, including muscle repair, glycogen
                      replenishment, and hormone regulation, which are essential
                      for optimal athletic performance.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="my-md-2">
              <h3 className="blogPageSubHeading text-start"> Injury Risk</h3>
              <div>
                <Row>
                  <Col>
                    <div className="p-0 m-0 blogPageli">
                      <span className="fw-600 text-black fs-14">Study :</span>{" "}
                      Milewski MD, Skaggs DL, Bishop GA, et al. Chronic lack of
                      sleep is associated with increased sports injuries in
                      adolescent athletes. J Pediatr Orthop. 2014;34(2):129-133.
                    </div>
                    <div className="p-0 m-0 blogPageli">
                      <p className="fw-600 text-black ">Findings:</p>
                      <div className="p-0 m-0">
                        <ul>
                          <li className="">
                            This study found that chronic sleep deprivation was
                            associated with an increased risk of sports-related
                            injuries among adolescent athletes, emphasizing the
                            importance of adequate sleep for injury prevention.
                          </li>
                          <li className="mt-md-3">
                            These studies demonstrate the significant impact of
                            sleep on various aspects of athletic performance,
                            highlighting the importance of prioritizing sleep
                            for athletes to optimize their training, recovery,
                            and overall performance.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" bsPrefix={`${styles.blogaccordianItem}`}>
          <Accordion.Header bsPrefix={`${styles.blogPageSubHeading}`}>
            <span
              style={{
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Sleep and Health
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <div>
                <p className="blogPageParaOne">
                  <span className="blogsPageT">Q</span>uality sleep is
                  fundamental for overall health and well-being. Adequate sleep
                  supports physical, mental, and emotional health by promoting
                  optimal immune function, cognitive performance, mood
                  regulation, and metabolic balance. Prioritizing sufficient
                  sleep duration and quality is essential for maintaining a
                  healthy lifestyle and reducing the risk of chronic diseases
                  such as obesity, diabetes, cardiovascular disease, and mental
                  health disorders. Incorporating healthy sleep habits into
                  daily routines can positively impact overall health and
                  enhance quality of life. Below are findings from clinical
                  studies..
                </p>
              </div>
            </div>
            <div>
              <Row>
                <Col>
                  <ol>
                    <li className="my-md-2">
                      <span className="fw-600">Chronic Diseases:</span> Numerous
                      studies have linked insufficient sleep to an increased
                      risk of chronic conditions such as obesity, type 2
                      diabetes, cardiovascular disease, and hypertension.
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">Immune Function:</span> Sleep
                      plays a crucial role in regulating the immune system.
                      Research suggests that inadequate sleep can weaken immune
                      function, making individuals more susceptible to
                      infections and illnesses.
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">Mental Health:</span> Poor sleep
                      has been associated with various mental health issues,
                      including depression, anxiety, and mood disorders.
                      Conversely, improving sleep quality can have positive
                      effects on mental well-being.
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">Cognitive Function:</span> Sleep
                      is essential for cognitive processes such as memory
                      consolidation, learning, and decision-making. Studies have
                      shown that inadequate sleep can impair cognitive function,
                      affecting attention, concentration, and problem-solving
                      abilities.
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">Metabolic Health:</span> Sleep
                      deprivation can disrupt hormonal regulation, leading to
                      imbalances in appetite-regulating hormones such as leptin
                      and ghrelin. This can contribute to weight gain, metabolic
                      syndrome, and insulin resistance.
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">Quality of Life:</span> Poor
                      sleep quality and sleep disorders can significantly impact
                      overall quality of life, affecting physical, emotional,
                      and social well-being
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">Aging:</span> Sleep patterns
                      change with age, and older adults may experience more
                      fragmented sleep and insomnia. Sleep disturbances in older
                      adults have been linked to an increased risk of falls,
                      cognitive decline, and mortality.
                    </li>
                    <li className="my-md-2">
                      <span className="fw-600">
                        Shift Work and Sleep Disorders:
                      </span>{" "}
                      Shift work and sleep disorders such as insomnia, sleep
                      apnea, and restless legs syndrome can have profound
                      effects on health and well-being. Studies have explored
                      the impact of these conditions on various health outcomes
                      and interventions to improve sleep in affected
                      individuals.
                    </li>
                  </ol>
                </Col>
              </Row>
            </div>

            <div className="pt-md-3">
              <h5>References:</h5>
              <ul className="list-unstyled">
                <li className="my-md-2 my-2">
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/15602591/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://pubmed.ncbi.nlm.nih.gov/15602591/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/21300732/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://pubmed.ncbi.nlm.nih.gov/21300732/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3816913/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/
                    <br />
                    PMC3816913/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2661105/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC2661105/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8602722/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC8602722/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8651630/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC8651630/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2656292/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC2656292/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9031614/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC9031614/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7826982/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC7826982/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4679552/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC4679552/
                  </a>
                </li>
                <li className="my-md-2 my-2">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5932908/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.ncbi.nlm.nih.gov/pmc/articles/ <br />
                    PMC5932908/
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default BlogsPage;
