import React from "react";
import SnapShot from "../../assets/images/snapshot.png";
import Snapshot1 from "../../assets/images/snapshot2.png";
import SnapShot2 from "../../assets/images/snapshot3.png";
import { Row, Col } from "react-bootstrap";

const CalmingMusicComponent = () => {
  return (
    <>
      <div className="mt-md-5 d-none d-md-block">
        <Row>
          <Col className="col-md-6">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/sleep+meditation.mp4"
              poster={SnapShot}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="col-md-6">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/water+video.mp4"
              poster={Snapshot1}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="mt-md-5 col-md-6">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/1213.mp4"
              poster={SnapShot2}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
      <div className="mt-5 d-block d-md-none">
        <Row>
          <Col className="col-12">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/sleep+meditation.mp4"
              poster={SnapShot}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="col-12 my-3">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/water+video.mp4"
              poster={Snapshot1}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="mt-md-5 col-12">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/1213.mp4"
              poster={SnapShot2}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CalmingMusicComponent;
