import React from "react";
import Sleep_head from "../../assets/images/sleep_head.svg";
import Side_Img from "../../assets/images/Rectangle 6129 (2).png";
import Play_Icon from "../../assets/images/play_icon.png";
import Side_Img_Sm from "../../assets/images/side_angle_sm.png";
import Sleep_head_Sm from "../../assets/images/vector.png";
import "./Banner.css";
import { useNavigate } from "react-router-dom";

const BannerComponent = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div className="main_bg container-fluid">
        <div className="every d-none d-md-block">
          <img src={Side_Img} className="image " />
          <div className="sleep_img ">
            <img src={Sleep_head} className="bed_img " />
          </div>
          <div className="translate-middle-y">
            <p className="every_good_day">
              Every <span className="GoodDay">Good Day</span>
              <br />
              Begins with a Restful
              <br /> Night's Sleep – How's
              <br />
              Your Sleep Been
              <br /> Lately?
            </p>
            <div>
              We combine the latest research on sleep and circadian biology to
              <br /> create a customized approach to help you consistently sleep
              better.
            </div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="px-3 py-3 rounded-pill border-0 getstart mt-4"
                onClick={() => Navigate("/signup")}
              >
                Get Started<i className="bi bi-arrow-right ms-md-2"></i>
              </button>

              <button
                type="button"
                className="border-0 bg-light  d-flex align-items-center mt-4 pt-1  "
                onClick={() =>
                  (window.location.href =
                    "https://www.youtube.com/watch?v=QF-8m-cuCD4")
                }
              >
                <img src={Play_Icon} className="play_img ms-2" />
                How&nbsp;It&nbsp;Works
              </button>
            </div>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <div className="row p-3">
            <p className="col-sm-12 every_good_day_sm">
              Every<span className="GoodDay">Good Day</span>
              <br />
              Begins with a Restful Night's Sleep – How's Your Sleep Been
              Lately?
            </p>
            <div className="col-sm-12 latest">
              We combine the latest research on sleep and circadian biology to
              create a customized approach to help you consistently sleep
              better.
            </div>

            <button
              type="button"
              className="px-4 py-3 rounded-pill border-0 getstart mt-4 mr-4"
              onClick={() => Navigate("/signup")}
            >
              Get Started<i className="bi bi-arrow-right"></i>
            </button>
            <div className="d-flex justify-content-center mt-4">
              <img src={Play_Icon} className="play_img" />
              <button
                type="button"
                className="border-0"
                style={{
                  background:
                    "linear-gradient(180deg, #D7F2FF 0%, rgba(215, 242, 255, 0) 100%)",
                }}
              >
                <a href="#" className="play_icon text-decoration-none">
                  How It Works
                </a>
              </button>
            </div>
          </div>
          <div className="position-relative mt-4 ">
            <img
              src={Side_Img_Sm}
              // style={{ marginLeft: "-%" }}
              className="mr-0"
            />
            <div className="position-absolute">
              <img src={Sleep_head_Sm} className="vector_img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerComponent;
