import React from "react";
import LandingPageHeader from "../components/landing/LandingPageHeader";
import { Outlet } from "react-router-dom";
import LandingPageFooter from "../components/landing/LandingPageFooter";

const LandingLayout = () => {
  return (
    <div>
      <div>
        <LandingPageHeader />
      </div>

      <div className="desktop_header_height">
        <Outlet />
      </div>
      <div>
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default LandingLayout;
