import React, { useContext, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../context/authContext1";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

interface ModalProps {
  closeNotificationCategoryEditModalHandler: () => void;
  apiSuccessHandler: () => void;
  isOpenNotificationCategoryEditModal: boolean;
  notificationId: number;
  notificationCategory: string;
  patientId?: number;
  smsnotificationCategory: string;
}

const NotificationCategoryEditModal: React.FC<ModalProps> = ({
  isOpenNotificationCategoryEditModal,
  closeNotificationCategoryEditModalHandler,
  apiSuccessHandler,
  notificationId,
  notificationCategory,
  smsnotificationCategory,
  patientId,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { token } = useContext(AuthContext);
  const toastId = useRef<any>(null);
  const [notificationCategoryInput, setNotificationCategoryInput] =
    useState(notificationCategory);
  const [smsnotificationCategoryInput, setSMSNotificationCategoryInput] =
    useState(smsnotificationCategory);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const notificationChangeHandler = (e: any) => {
    if (e.target.value.trim().length === 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    setNotificationCategoryInput(e.target.value.trim());
  };
  const smsNotificationHandler = (e: any) => {
    setSMSNotificationCategoryInput(e.target.value);
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/updateNotification",
      data: {
        id: notificationId,
        notificationName: notificationCategoryInput,
        smsNotificationName: smsnotificationCategoryInput,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.update(toastId.current, {
            render: "Reminder has been updated succesfully",
            type: "success",
            isLoading: false,
          });
          closeNotificationCategoryEditModalHandler();
          apiSuccessHandler();
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      });
  };

  return (
    <>
      <Modal
        className="custom-scroll"
        show={isOpenNotificationCategoryEditModal}
        onHide={closeNotificationCategoryEditModalHandler}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen="md-down"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header className="py-2 bg-purple" closeButton>
            <Modal.Title className="text-white">
              Edit Notification Category
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="mb-2">Notifcation Category:</label>
              <textarea
                rows={6}
                className={`form-control ${
                  notificationCategoryInput.length === 0
                    ? "border-danger"
                    : "border-primary"
                } border-0 border-bottom rounded-0`}
                defaultValue={notificationCategory}
                onChange={notificationChangeHandler}
              />
            </div>
            <div className="mt-md-2">
              <label className="mb-2">SMS Notifcation:</label>
              <input
                type="text"
                className={`form-control ${
                  isFormValid ? "border-primary" : "border-danger"
                } border-0 border-bottom rounded-0`}
                defaultValue={smsnotificationCategory}
                onChange={smsNotificationHandler}
                name="isSMS"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="py-2 border border-0">
            <button
              type="submit"
              className="btn bg-purple text-white rounded-0 px-3 py-1"
              disabled={notificationCategoryInput.length === 0}
            >
              Save
            </button>
            <button
              type="button"
              className="btn bg-purple text-white rounded-0 px-3 py-1"
              onClick={closeNotificationCategoryEditModalHandler}
            >
              Close
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NotificationCategoryEditModal;
