import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthContext from "../../../context/authContext1";
import QuestionarieContext from "../../../context/questionaireContext";
import {
  RadioFieldGroup,
  CheckBoxFieldGroup,
} from "../../../utils/Fromcontrol";
import Stepper from "./Stepper";
import { useContext, useEffect, useState } from "react";
import Spinner from "../../../utils/Spinner";
import { Container } from "react-bootstrap";
import useAxiosPrivate from "../../../utils/useAxiosPrivate";

interface Values {
  29: null | string;
  30: null | string;
  31: null | string;
  32: null | string;
  33: null | string;
  34: null | string;
  35: null | string;
  36: null | string;
  37: null | string;
  38: null | string;
  39: null | string;
  40: null | string;
  41: null | string;
  42: null | string;
  43: null | string;
  44: null | string;
  45: null | string;
  46: null | string;
  47: null | string;
  77: null | string;
  78: null | string;
  81: null | string;
  82: null | string;
  83: null | string;
  84: null | string;
  85: null | string;
  86: null | string;
  87: null | string;
  88: null | string;
  89: null | string;
  90: null | string;
  91: null | string;
  92: null | string;
  93: null | string;
  94: null | string;
  95: null | string;
  107: null | string;
  108: null | string;
  109: null | string;
  110: null | string;
}

const QuestionariePart5: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated, token } = useContext(AuthContext);
  const [initialValues, setInitialValues] = useState<any>({});
  const { activeStepHandler } = useContext(QuestionarieContext);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isQuestionFetching, setIsQuestionFetching] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validationSchema = Yup.object({
    29: Yup.string().required("A radio option is required").nullable(),
    30: Yup.string()
      .when("29", {
        is: (val: string) => val === "272",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    31: Yup.string().required("A radio option is required").nullable(),
    32: Yup.string()
      .when("31", {
        is: (val: string) => val === "283",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    33: Yup.string()
      .when("31", {
        is: (val: string) => val === "283",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    34: Yup.string().required("A radio option is required").nullable(),
    35: Yup.string()
      .when("34", {
        is: (val: string) => val === "295",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    36: Yup.string()
      .when("34", {
        is: (val: string) => val === "295",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    37: Yup.string()
      .when("34", {
        is: (val: string) => val === "295",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    38: Yup.string().required("A radio option is required").nullable(),
    39: Yup.string()
      .when("38", {
        is: (val: string) => val === "310",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    40: Yup.string().required("A radio option is required").nullable(),
    41: Yup.string()
      .when("40", {
        is: (val: string) => val === "320",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    42: Yup.string().required("A radio option is required").nullable(),
    43: Yup.string().required("A radio option is required").nullable(),
    44: Yup.string()
      .when("43", {
        is: (val: string) => val === "332",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    45: Yup.string().required("A radio option is required").nullable(),
    46: Yup.string().required("A radio option is required").nullable(),
    47: Yup.string().required("A radio option is required").nullable(),
    77: Yup.string()
      .when("31", {
        is: (val: string) => val === "284",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    78: Yup.string()
      .when("31", {
        is: (val: string) => val === "284",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    81: Yup.string()
      .when("31", {
        is: (val: string) => val === "285",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    82: Yup.string()
      .when("31", {
        is: (val: string) => val === "285",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    83: Yup.string()
      .when("34", {
        is: (val: string) => val === "296",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    84: Yup.string()
      .when("34", {
        is: (val: string) => val === "296",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    85: Yup.string()
      .when("34", {
        is: (val: string) => val === "296",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    86: Yup.string()
      .when("34", {
        is: (val: string) => val === "297",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    87: Yup.string()
      .when("34", {
        is: (val: string) => val === "297",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    88: Yup.string()
      .when("34", {
        is: (val: string) => val === "297",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    89: Yup.string()
      .when("38", {
        is: (val: string) => val === "311",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    90: Yup.string()
      .when("38", {
        is: (val: string) => val === "312",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    91: Yup.string()
      .when("38", {
        is: (val: string) => val === "313",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    92: Yup.string()
      .when("40", {
        is: (val: string) => val === "321",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    93: Yup.string()
      .when("40", {
        is: (val: string) => val === "322",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    94: Yup.string()
      .when("40", {
        is: (val: string) => val === "323",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    95: Yup.string()
      .when("43", {
        is: (val: string) => val === "333",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    107: Yup.string()
      .when("29", {
        is: (val: string) => val === "273",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    108: Yup.string()
      .when("29", {
        is: (val: string) => val === "274",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    109: Yup.string()
      .when("29", {
        is: (val: string) => val === "275",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
    110: Yup.string()
      .when("29", {
        is: (val: string) => val === "276",
        then: Yup.string().required("A radio option is required").nullable(),
      })
      .nullable(),
  });

  let actualInitialValues: any = {
    29: null,
    30: null,
    31: null,
    32: null,
    33: null,
    34: null,
    35: null,
    36: null,
    37: null,
    38: null,
    39: null,
    40: null,
    41: null,
    42: null,
    43: null,
    44: null,
    45: null,
    46: null,
    47: null,
    77: null,
    78: null,
    81: null,
    82: null,
    83: null,
    84: null,
    85: null,
    86: null,
    87: null,
    88: null,
    89: null,
    90: null,
    91: null,
    92: null,
    93: null,
    94: null,
    95: null,
    107: null,
    108: null,
    109: null,
    110: null,
  };
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      setIsQuestionFetching(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      axiosPrivate({
        method: "POST",
        url: "/questionaire",
        data: { part: 5 },
      })
        .then((res) => {
          const { isCompleted, data } = res.data;
          if (isCompleted) {
            const keyValueArray = data.map((question: any) => {
              if (question?.question_type === "radio") {
                return [
                  question?.id,
                  question?.isSelectedOption,
                  question?.question_type,
                ];
              } else {
                const convertedSelectedOption = question?.isSelectedOption.map(
                  (option: number) => option.toString()
                );
                return [
                  question?.id,
                  convertedSelectedOption,
                  question?.question_type,
                ];
              }
            });

            keyValueArray.forEach((keyValuePair: any) => {
              const key = keyValuePair[0];
              const value = keyValuePair[1];
              const questionType = keyValuePair[2];
              if (questionType === "radio") {
                actualInitialValues[key] =
                  value === null ? null : value.toString();
              } else {
                actualInitialValues[key] = value === null ? [] : value;
              }
            });

            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          } else {
            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          }
        })
        .catch((err) => {
          return;
        })
        .finally(() => {
          setIsQuestionFetching(false);
          setIsLoading(false);
        });
    }
  }, [isAuthenticated]);

  const submitHandler = (values: Values) => {
    setIsSubmitting(true);
    axiosPrivate({
      method: "POST",
      url: "/submitQuestionaire",
      data: { questions: values, part: 5 },
    })
      .then((res) => {
        if (res.status === 200) {
          activeStepHandler();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      {isLoading && <Spinner />}
      {isSubmitting && <Spinner />}
      <Container>
        <div>
          <div className="my-4 d-flex justify-content-center">
            <div className="w-75">
              <Stepper />
            </div>
          </div>
          <h6 className="my-2">Questionarie 5</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            enableReinitialize={true}
          >
            {(formikProps) => {
              return (
                <Form>
                  {!isQuestionFetching && (
                    <div>
                      {questions.map((question: any) => {
                        if (question.question_type === "radio") {
                          return (
                            <RadioFieldGroup
                              key={question.id}
                              questionId={question.question_id}
                              optionId={question.option_id}
                              question={question.name}
                              questionSequence={question.question_sequence}
                              name={question.id}
                              options={question.options}
                              values={formikProps.values}
                              actualQuestionId={question.id}
                            />
                          );
                        } else if (question.question_type === "checkbox") {
                          return (
                            <CheckBoxFieldGroup
                              key={question.id}
                              questionId={question.question_id}
                              optionId={question.option_id}
                              question={question.name}
                              questionSequence={question.question_sequence}
                              name={question.id}
                              options={question.options}
                              values={formikProps.values}
                              actualQuestionId={question.id}
                            />
                          );
                        }
                      })}

                      <div className="text-center my-3">
                        <button
                          type="submit"
                          className="btn btn-purple rounded-1 px-5"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default QuestionariePart5;
