import React from "react";
// import Nydra_wellness from "../../assets/images/Nydra_Wellness.svg";
import Logo from "../../assets/images/LandingPageLogo.png";
import { NavLink, useNavigate } from "react-router-dom";

const LandingPageHeader = () => {
  const Navigate = useNavigate();
  return (
    <>
      <nav
        className="container-fluid d-flex justify-content-center align-items-center bg-dark p-0 m-0"
        style={{ height: "36px" }}
      >
        <div>
          <span className="text-white">Get 75% OFF When You Sign Up Now</span>
        </div>
      </nav>
      <nav className="navbar navbar-expand-lg navbar-light bg-light px-0 py-3">
        <div className="container-xl">
          {/* <!-- Logo --> */}
          <div className="navbar-brand" onClick={() => Navigate("/")}>
            <img src={Logo} alt="logo" style={{ height: "60px" }} />
          </div>
          {/* <!-- Navbar toggle --> */}
          <button
            className="navbar-toggler dropdown-toggle"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Collapse --> */}
          <div className="collapse navbar-collapse" id="navbarCollapse">
            {/* Nav */}
            <div className="navbar-nav mx-md-auto">
              <div className="my-3  my-md-0 mx-md-0">
                <NavLink to="/" className="nav_items  font-family" end>
                  Home
                </NavLink>
              </div>

              <div className="my-3  my-md-0 mx-md-0">
                <NavLink
                  to="/weight_loss"
                  className="nav_items mx-md-4  font-family"
                >
                  Weight-Loss
                </NavLink>
              </div>

              <div className="my-3  my-md-0 mx-md-0">
                <NavLink to="/work" className="nav_items  font-family">
                  Work
                </NavLink>
              </div>
              <div className="my-3  my-md-0 mx-md-0">
                <NavLink
                  to="/health"
                  className="nav_items mx-md-4  font-family"
                >
                  Health
                </NavLink>
              </div>
              <div className="my-3  my-md-0 mx-md-0">
                <NavLink to="/our_solution" className="nav_items  font-family">
                  Our Solution
                </NavLink>
              </div>
              <div className="my-3  my-md-0 mx-md-0">
                <NavLink to="/blogs" className="nav_items mx-md-4  font-family">
                  Blogs
                </NavLink>
              </div>
            </div>

            <div className="navbar-nav me-md-4">
              <button
                className="btn rounded-pill fw-500"
                onClick={() => Navigate("/login")}
                style={{
                  outline: "none",
                  border: "none",
                  borderColor: "none",
                }}
              >
                Login
              </button>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-3 mt-lg-0">
              <button
                className="btn rounded-pill fw-500"
                onClick={() => Navigate("/signup")}
                style={{
                  background: "#4BC9FF",
                  outline: "none",
                  border: "none",
                  borderColor: "none",
                }}
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LandingPageHeader;
