import React from "react";
import Music from "../../assets/images/music.svg";
import exc from "../../assets/images/exer.svg";
import yoga from "../../assets/images/yoga.svg";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Vector from "../../assets/images/SleepNydra.svg";
import sleep from "../../assets/images/NydraConst.svg";
import mobileSLeep from "../../assets/images/mobileSleepNYdra.svg";
import vectorMObile from "../../assets/images/mobileVectorSleep.svg";

const NydraSleepPlatform = () => {
  const WeightData = [
    {
      id: 1,
      Image: exc,
      text: "logo",
      heading: "Gentle stretches",
      content: "Release tension and prepare your body for slumber.",
    },
    {
      id: 2,
      Image: yoga,
      text: "logo",
      heading: "Guided meditations",
      content: "Quiet your mind and melt away stress before bed.",
    },
    {
      id: 3,
      Image: Music,
      text: "logo",
      heading: "Calming music",
      content: "Drift off to soothing melodies designed to lull you to sleep.",
    },
  ];
  const WeightCard = ({ id, Image, content, heading, text, link }: any) => {
    return (
      <div className={` pb-3 ${id}`}>
        <div className="card_weight">
          <div className="text-center">
            {id === 8 ? (
              <div className="mt-md-5 pt-md-3">
                <img src={Image} alt={text} />
              </div>
            ) : (
              <img src={Image} alt={text} />
            )}
          </div>
          <div className="pt-md-3 pt-2">
            <h5 className="card-title text-center">{heading}</h5>
            <p className="card-text text-center"> {content}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className=" d-none d-md-block my-md-5 my-3">
        <Container>
          <div className="p-2 text-center">
            <div className="getmore my-md-4">
              Nydra isn't just a sleep platform
            </div>
            <div className="relationship ">
              it's your well-being companion. We equip you with personalized
              tools and expert guidance to unlock the secrets of a good night's
              sleep. Ready to wind down? Our members will have access to our
              library of on-demand videos:
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            {WeightData.map((data: any) => {
              return (
                <div key={data.id} className="col-md-3 mx-md-4">
                  <WeightCard
                    Image={data.Image}
                    heading={data.heading}
                    content={data.content}
                    onClick={data.link}
                    id={data.id}
                  />
                </div>
              );
            })}
          </div>
        </Container>
        <div className="how_it_works py-md-5">
          <Container className="py-md-5">
            <Row>
              <Col style={{ position: "relative" }}>
                <img
                  src={sleep}
                  alt="yogo_vector"
                  style={{
                    position: "absolute",
                    top: "-110px",
                    left: "-140px",
                  }}
                />
                <img
                  src={Vector}
                  alt="yogo"
                  style={{ position: "absolute", top: 0, left: 0 }}
                />
              </Col>
              <Col>
                <div className="py-md-4">
                  <h2 className="nydreSleepTitle text-white">
                    Nydra is constantly evolving, just like your sleep needs.
                  </h2>
                </div>
                <div>
                  <h5 className="nydreSleeptext text-white fw-500 mt-2">
                    We're always adding new resources to help you. Coming soon
                  </h5>
                </div>
                <div>
                  <h5 className="nydreSleepsubHeading text-white mt-2 fw-600">
                    Discover the science of sleep:
                  </h5>
                  <h5 className="nydreSleeptext text-white fw-500 mt-2">
                    Learn how light, sound, temperature, and even the colors in
                    your room can impact your rest.
                  </h5>
                </div>
                <div className="my-md-4">
                  <h5 className="nydreSleepsubHeading text-white mt-2 fw-600">
                    Unwind with expert tips:
                  </h5>
                  <h5 className="nydreSleeptext text-white fw-500 mt-2">
                    Explore practical strategies for creating a sleep-conducive
                    haven and managing stress levels.
                  </h5>
                </div>
                <div>
                  <h5 className="nydreSleepsubHeading text-white mt-2 fw-600">
                    Breathe your way to better sleep:
                  </h5>
                  <h5 className="nydreSleeptext text-white fw-500 mt-2">
                    Master simple breathwork techniques to calm your mind and
                    body for deeper, more peaceful slumber.st.
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="d-block d-md-none">
        <Container className="p-0 m-0 text-center my-5">
          <div className="">
            <div
              className="initial_heading my-md-4"
              style={{ textAlign: "center" }}
            >
              Nydra isn't just a sleep platform
            </div>
            <div className="initial_text" style={{ textAlign: "center" }}>
              it's your well-being companion. We equip you with personalized
              tools and expert guidance to unlock the secrets of a good night's
              sleep. Ready to wind down? Our members will have access to our
              library of on-demand videos:
            </div>
          </div>
          <div className="d-flex justify-content-center my-5">
            <Carousel>
              {WeightData.map((data: any) => {
                return (
                  <Carousel.Item
                    className="carousel_item"
                    style={{ height: "22rem", width: "18rem" }}
                    key={data.id}
                  >
                    <div className="text-center">
                      <img src={data.Image} width="140px" height="140px" />
                    </div>

                    <Carousel.Caption className="p-0 m-0">
                      <h5 className="mt-4">{data.heading}</h5>
                      <p className="mt-2 text-black p-0">{data.content}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </Container>
        <div className="how_it_works py-3">
          <Container className="py-5">
            <div style={{ position: "relative" }}>
              <img
                src={vectorMObile}
                alt="yogo_vector"
                style={{ position: "absolute", top: 0, left: "-20px" }}
              />
              <img
                src={mobileSLeep}
                alt="yogo"
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            </div>
            <div style={{ marginTop: "460px" }}>
              <div className="pt-4">
                <h2 className="initial_heading text-white">
                  Nydra is constantly evolving, just like your sleep needs.
                </h2>
              </div>
              <div>
                <h5 className="initial_text text-white fw-500">
                  We're always adding new resources to help you. Coming soon
                </h5>
              </div>
              <div className="my-4">
                <h5
                  className="initial_text text-white mt-2"
                  style={{ fontWeight: 600 }}
                >
                  Discover the science of sleep:
                </h5>
                <h5 className="initial_text text-white fw-500 mt-2">
                  Learn how light, sound, temperature, and even the colors in
                  your room can impact your rest.
                </h5>
              </div>
              <div className="my-md-4">
                <h5
                  className="initial_text text-white mt-2 "
                  style={{ fontWeight: 600 }}
                >
                  Unwind with expert tips:
                </h5>
                <h5 className="initial_text text-white fw-500 mt-2">
                  Explore practical strategies for creating a sleep-conducive
                  haven and managing stress levels.
                </h5>
              </div>
              <div>
                <h5
                  className="initial_text text-white mt-2 "
                  style={{ fontWeight: 600 }}
                >
                  Breathe your way to better sleep:
                </h5>
                <h5 className="initial_text text-white fw-500 mt-2">
                  Master simple breathwork techniques to calm your mind and body
                  for deeper, more peaceful slumber.st.
                </h5>
              </div>
            </div>
            {/* <Row>
              <Col className="col-12 ">
                <div style={{ position: "relative" }}>
                  <img
                    src={vectorMObile}
                    alt="yogo_vector"
                    style={{ position: "absolute", top: 0, left: "-20px" }}
                  />
                  <img
                    src={mobileSLeep}
                    alt="yogo"
                    style={{ position: "absolute", top: 0, left: 0 }}
                  />
                </div>
              </Col>
              <Col className="col-12">
                <div className="pt-4">
                  <h2 className="initial_heading text-white">
                    Nydra is constantly evolving, just like your sleep needs.
                  </h2>
                </div>
                <div>
                  <h5 className="initial_text text-white fw-500">
                    We're always adding new resources to help you. Coming soon
                  </h5>
                </div>
                <div className="my-4">
                  <h5
                    className="initial_text text-white mt-2"
                    style={{ fontWeight: 600 }}
                  >
                    Discover the science of sleep:
                  </h5>
                  <h5 className="initial_text text-white fw-500 mt-2">
                    Learn how light, sound, temperature, and even the colors in
                    your room can impact your rest.
                  </h5>
                </div>
                <div className="my-md-4">
                  <h5
                    className="initial_text text-white mt-2 "
                    style={{ fontWeight: 600 }}
                  >
                    Unwind with expert tips:
                  </h5>
                  <h5 className="initial_text text-white fw-500 mt-2">
                    Explore practical strategies for creating a sleep-conducive
                    haven and managing stress levels.
                  </h5>
                </div>
                <div>
                  <h5
                    className="initial_text text-white mt-2 "
                    style={{ fontWeight: 600 }}
                  >
                    Breathe your way to better sleep:
                  </h5>
                  <h5 className="initial_text text-white fw-500 mt-2">
                    Master simple breathwork techniques to calm your mind and
                    body for deeper, more peaceful slumber.st.
                  </h5>
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </div>
    </>
  );
};

export default NydraSleepPlatform;
