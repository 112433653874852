import React, { useRef, useState } from "react";
import { EditNotificationModalProps } from "../../types/EditNotificationModalType";
import { Modal } from "react-bootstrap";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import { toast } from "react-toastify";

const EditNotificationModal: React.FC<EditNotificationModalProps> = ({
  isOpenNotificationCategoryEditModal,
  closeNotificationCategoryEditModalHandler,
  apiSuccessHandler,
  notificationId,
  notificationCategory,
  smsnotificationCategory,
  patientId,
}) => {
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const [notificationCategoryInput, setNotificationCategoryInput] =
    useState(notificationCategory);
  const [isSMS, setIsSMS] = useState(smsnotificationCategory);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const notificationChangeHandler = (e: any) => {
    if (e.target.value.trim().length === 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    setNotificationCategoryInput(e.target.value.trim());
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/updateNotification",
      data: {
        id: notificationId,
        notificationName: notificationCategoryInput,
        smsNotificationName: isSMS,
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          closeNotificationCategoryEditModalHandler();
          apiSuccessHandler();
          toast.update(toastId.current, {
            render: "Notification Updated successfully",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err: any) => {
        return;
      });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    setIsSMS(value);
  };
  return (
    <Modal
      show={isOpenNotificationCategoryEditModal}
      onHide={closeNotificationCategoryEditModalHandler}
      backdrop="static"
      centered
      fullscreen="md-down"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className="py-2 bg-purple" closeButton>
          <Modal.Title className="text-white">
            Edit Notification Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="mb-2">Notifcation Category:</label>
            <textarea
              rows={6}
              style={{ outline: "none" }}
              className={`w-100 ${
                notificationCategoryInput.length === 0
                  ? "border-danger"
                  : "border-purple"
              } border border-purple rounded-2`}
              defaultValue={notificationCategory}
              onChange={notificationChangeHandler}
            />
          </div>
          <div className="mt-md-2">
            <label className="mb-2">SMS Notifcation:</label>
            <input
              type="text"
              className={`form-control ${
                isFormValid ? "border-primary" : "border-danger"
              } border-0 border-bottom rounded-0`}
              onChange={handleChange}
              defaultValue={smsnotificationCategory}
              name="isSMS"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2 border border-0">
          <button
            type="submit"
            className="btn btn-purple text-white rounded-1 px-3 py-1"
            // disabled={notificationCategoryInput.length === 0}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-purple text-white rounded-1 px-3 py-1"
            onClick={closeNotificationCategoryEditModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditNotificationModal;
