import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import Header from "../components/Header";
import Spinner from "../utils/Spinner";

const WhoopData = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    const url = window.location.href;

    if (url.includes("https://nydrawell.com/")) {
      const wearableToken = url.split("=")[1].split("&")[0];
      axios
        .post(
          "/wearableToken",
          {
            userId: userId,
            code: wearableToken,
            device: "whoop",
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status) {
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  }, []);
  return (
    <>
      <Header />
      <div>{isLoading && <Spinner />}</div>
    </>
  );
};

export default WhoopData;
