import { useContext } from "react";
import QuestionarieContext from "../../../context/questionaireContext";
import QuestionariePart1 from "../questionnaire/QuestionarieStep1";
import QuestionariePart2 from "../questionnaire/QuestionarieStep2";
import QuestionariePart3 from "../questionnaire/QuestionarieStep3";
import QuestionariePart4 from "../questionnaire/QuestionarieStep4";
import QuestionariePart5 from "../questionnaire/QuestionarieStep5";
import QuestionariePart6 from "../questionnaire/QuestionarieStep6";

const Step: React.FC = () => {
  const { activeStepIndex } = useContext(QuestionarieContext);
  let stepContent;

  switch (activeStepIndex) {
    case 1:
      stepContent = <QuestionariePart1 />;
      break;
    case 2:
      stepContent = <QuestionariePart2 />;
      break;
    case 3:
      stepContent = <QuestionariePart3 />;
      break;
    case 4:
      stepContent = <QuestionariePart4 />;
      break;
    case 5:
      stepContent = <QuestionariePart5 />;
      break;
    case 6:
      stepContent = <QuestionariePart6 />;
      break;
    default:
      break;
  }

  return (
    <div className="px-3 px-xl-5 py-md-4 border-light_purple">
      <div style={{ marginTop: "85px" }} className="desktop_header_height">
        <div>{stepContent}</div>
      </div>
    </div>
  );
};

export default Step;
