import React, { useContext, useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { ModalProps, MyProfileType } from "../types/MyProfileType";
import AuthContext from "../context/authContext1";
import {
  TextArea,
  TextField,
  TextSelect,
  TimeZoneSelect,
} from "../utils/Fromcontrol";
import ProfileContext from "../context/profileContext";
import Avatar from "../assets/images/avathar.png";
import Spinner from "../utils/Spinner";
import { toast } from "react-toastify";
import useAxiosPrivate from "../utils/useAxiosPrivate";

const MyProfileModal = ({ show, handleClose }: ModalProps) => {
  const axiosPrivate = useAxiosPrivate();
  const {
    isAuthenticated,
    userId,
    token,
    userRole,
    selectedTimezoneId,
    timezoneUpdateHandler,
  } = useContext(AuthContext);
  const toastId = useRef<any>(null);
  const { availableTimezones, toggleIsProfileEditted } =
    useContext(ProfileContext);
  const [isLoading, setisLoading] = useState(false);
  const [transformedBlob, setTransformedBlob] = useState<string>("");
  const [previewImg, setPreviewImg] = useState("");
  const [userData, setUserData] = useState<any>([]);
  const [showFetchProfileLoading, setShowFetchProfileLoading] =
    useState<boolean>(false);
  const [showUpdateProfileLoading, setShowUpdateProfileLoading] =
    useState<boolean>(false);

  const validationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    image: Yup.mixed().nullable(),
    address_line_1: Yup.string().nullable(),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    zipcode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    state: Yup.string().nullable(),
    mobileno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number is not valid")
      .min(10, "Minimum of 10 numeric characters of length")
      .max(10, "Maximum of 10 numeric characters of length")
      .nullable(),
    description: Yup.string().nullable(),
    timezone: Yup.string().nullable(),
    gender: Yup.string().nullable(),
  });
  const initialValues = {
    firstname: userData?.firstname,
    lastname: userData?.lastname,
    email: userData?.email,
    gender: userData?.gender,
    mobileno: userData?.mobileno === "null" ? "" : userData?.mobileno,
    image: userData?.profile_image,
    address_line_1:
      userData?.address_line_1 === "null" ? "" : userData?.address_line_1,
    address_line_2:
      userData?.address_line_2 === "null" ? "" : userData?.address_line_2,
    city: userData?.city === "null" ? "" : userData?.city,
    zipcode: userData?.zipcode === "null" ? "" : userData?.zipcode,
    state: userData?.state === "null" ? "" : userData?.state,
    description: userData?.description === "null" ? "" : userData?.description,
    timezone: userData?.timezone,
  };

  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (!event?.target?.result) return;
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  const ImageToBlob = (file: any) => {
    if (!file) {
      return;
    }

    fileToDataUri(file).then((blob) => {
      const stringBlob = blob as string;
      setTransformedBlob(stringBlob);
    });
  };

  const fetchProfileData = () => {
    if (isAuthenticated && userRole !== "superadmin") {
      setShowFetchProfileLoading(true);
      const bodyFormData = new FormData();
      bodyFormData.append("user_id", `${userId}`);
      axiosPrivate({
        method: "POST",
        url: "/viewprofile",
        data: {
          user_id: `${userId}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data[0];
            setisLoading(true);
            setUserData(data);
            if (data.profile_image !== null && data.profile_image !== "") {
              setPreviewImg(data?.profile_image);
            } else {
              setPreviewImg(Avatar);
            }
          }
        })
        .catch((err) => {
          return;
        })
        .finally(() => setShowFetchProfileLoading(false));
    }
  };

  useEffect(() => {
    if (isAuthenticated && userRole !== "superadmin" && show) {
      fetchProfileData();
    }
  }, [isAuthenticated, show, userRole, token]);

  const submitHandler = (values: MyProfileType) => {
    setShowUpdateProfileLoading(true);
    toastId.current = toast.loading("Loading...");
    const {
      firstname,
      lastname,
      email,
      mobileno,
      address_line_1,
      address_line_2,
      city,
      zipcode,
      state,
      description,
      timezone,
      gender,
    } = values;

    const blobimage =
      transformedBlob === "" || null ? previewImg : transformedBlob;
    axiosPrivate({
      method: "POST",
      url: "/updateprofile",
      data: {
        user_id: `${userId}`,
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobileno: mobileno,
        image: blobimage,
        addressline1: address_line_1,
        addressline2: address_line_2,
        city: city,
        zipcode: zipcode,
        state: state,
        description: description,
        timezone: timezone,
        gender: gender,
      },
    })
      .then((response) => {
        let Status = response.data.status === "success";
        if (response.status === 200 && Status) {
          timezoneUpdateHandler(parseInt(timezone));
          toast.update(toastId.current, {
            render: "Your Profile has been updated successfully",
            type: "success",
            isLoading: false,
          });
          handleClose();
          toggleIsProfileEditted();
          fetchProfileData();
          setTimeout(() => {
            toast.dismiss(toastId.current);
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowUpdateProfileLoading(false));
  };

  const Genderdropdown = [
    { key: "", value: "" },
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Others", value: "others" },
    { key: "Prefer not to say", value: "prefernottosay" },
  ];

  return (
    <>
      {/* <ToastContainer /> */}
      {(showUpdateProfileLoading || showFetchProfileLoading) && <Spinner />}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="lg"
        fullscreen="md-down"
      >
        <div className="d-flex align-items-center justify-content-between px-4 py-2 bg-purple">
          <div>
            <h5 className="text-white">Edit Profile</h5>
          </div>
          <div className="d-flex justify-content-end" onClick={handleClose}>
            <i className="bi bi-x-lg text-white"></i>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          enableReinitialize={true}
        >
          {(formikProps) => {
            return (
              <Form className="px-md-4 py-md-3 px-3">
                <Row>
                  <Col className="col-md-6 col-12 my-3 my-md-0">
                    <TextField
                      type="text"
                      name="firstname"
                      label="First Name"
                    />
                  </Col>
                  <Col className="col-md-6 col-12">
                    <TextField type="text" name="lastname" label="Last Name" />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 col-12 my-3 my-md-0">
                    <TextField type="text" name="email" label="Email" />
                  </Col>
                  <Col className="col-md-6 col-12">
                    <TextField type="text" name="mobileno" label="Mobile No" />
                  </Col>
                </Row>
                <Row className="my-3 my-md-0">
                  <Col className="col-md-6 col-6">
                    <TextSelect
                      label="Gender"
                      id="gender"
                      name="gender"
                      type="select"
                      options={Genderdropdown}
                    />
                  </Col>
                  <Col className="col-md-6 col-6">
                    <TextField
                      type="text"
                      name="address_line_1"
                      label="Address Line 1"
                    />
                  </Col>
                </Row>
                <Row className="my-3 my-md-0">
                  <Col className="col-md-6 col-6">
                    <TextField
                      type="text"
                      name="address_line_2"
                      label="Address Line 2"
                    />
                  </Col>
                  <Col className="col-md-6 col-6">
                    <TextField type="text" name="zipcode" label="ZipCode" />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 col-6">
                    <TextField type="text" name="city" label="City" />
                  </Col>
                  <Col className="col-md-6 col-6 my-3 my-md-0">
                    <TextField
                      type="text"
                      name="state"
                      placeholder="State"
                      label="State"
                    />
                  </Col>
                </Row>
                <Row className="">
                  <Col className="col-md-12 col-12">
                    <div className="row">
                      <div className="col-10">
                        <div className="d-flex flex-column">
                          <label className="fs-14">Update Profile Image</label>
                          <input
                            className="mt-1"
                            id="fileUpload"
                            type="file"
                            name="image"
                            accept="image/png, image/gif, image/jpeg, image/jpg"
                            onChange={(event: any) => {
                              formikProps.setFieldValue(
                                "image",
                                ImageToBlob(event.target.files[0])
                              );
                              setPreviewImg(
                                URL.createObjectURL(event.target.files[0])
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-end">
                        <img
                          height={50}
                          width={50}
                          src={
                            previewImg === null || previewImg === ""
                              ? Avatar
                              : previewImg
                          }
                          alt="Profile Pic"
                          className="rounded-circle"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 my-md-0 mt-md-3">
                  <Col className="col-md-6 col-6">
                    <TextArea name="description" label="Description" />
                  </Col>
                  <Col className="col-md-6 col-6">
                    <TimeZoneSelect
                      name="timezone"
                      label="Time Zone"
                      placeholder="Time Zone"
                      availableTimezones={availableTimezones}
                      selectedTimezoneId={selectedTimezoneId}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-md-3">
                  {isLoading && (
                    <button
                      type="submit"
                      className="btn btn-purple text-white rounded-0 px-4 py-1 rounded-1"
                    >
                      Save
                    </button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default MyProfileModal;
