import React, { useContext } from "react";
import deviceplan from "../../../assets/images/deviceplan.svg";
import plan from "../../../assets/images/plan.svg";
import termsandcondition from "../../../assets/images/termsandcondition.svg";
import "./PatientSubscriptionProgress.scss";
import AuthContext from "../../../context/authContext1";

const PatientSubscriptionProgress = () => {
  const { activeSubscriptionFlowStep } = useContext(AuthContext);

  return (
    <div className="PatientSubscriptionProgress">
      <div id="progress">
        <div
          className={`progress  ${
            activeSubscriptionFlowStep === "termsandconditions" && "width-50"
          } ${activeSubscriptionFlowStep === "payment" && "width-100"}`}
        ></div>
        <div
          className={`step non-active step-completed ${
            activeSubscriptionFlowStep === "device" && "active"
          }`}
        >
          <img height={32} width={32} src={deviceplan} alt="Device Plan" />
        </div>
        <div
          className={`step non-active bg-purple ${
            activeSubscriptionFlowStep === "termsandconditions"
              ? "active  step-completed"
              : "step-pending"
          }  ${activeSubscriptionFlowStep === "payment" && " step-completed"}`}
        >
          <img
            height={36}
            width={36}
            src={termsandcondition}
            alt="Terms and Condition"
          />
        </div>
        <div
          className={`step bg-purple ${
            activeSubscriptionFlowStep === "payment" && "active step-completed"
          }`}
        >
          <img height={36} width={36} src={plan} alt="Payment" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PatientSubscriptionProgress);
