import React from "react";
import Health1 from "../../assets/images/health (1).png";
import Health2 from "../../assets/images/health (2).png";
import Health3 from "../../assets/images/health (3).png";
import Health4 from "../../assets/images/health (4).png";
import Health5 from "../../assets/images/health (5).png";
import Health6 from "../../assets/images/health (6).png";
// import Health7 from "../../assets/images/health (7).png";
import Doctor from "../../assets/images/Doctor.png";
import Carousel from "react-bootstrap/Carousel";
import "./healthpage.css";
import { Container } from "react-bootstrap";

const HealthPageComponent = () => {
  const HealthCardData = [
    {
      id: 1,
      Image: Doctor,
      heading: "Personalized Approach",
      text: "logo",
      content:
        " Our program begins with a comprehensive assessment, utilizing wearable trackers and detailed questionnaires. This information helps us understand your unique sleep patterns and challenges.",
    },
    {
      id: 2,
      Image: Health6,
      heading: "Expert Guidance",
      text: "logo",
      content:
        " In Session 1, we analyze your sleep data and discuss the questionnaire results with you. Together, we identify areas for improvement and co-create strategies to enhance your sleep quality.",
    },
    {
      id: 3,
      Image: Health5,
      heading: "Gradual Implementation",
      text: "logo",
      content:
        " We believe in giving you the time to incorporate new habits into your routine. By allowing four-week intervals between sessions, we provide the space needed for these changes to take root, ensuring they become lasting habits.",
    },
    {
      id: 4,
      Image: Health4,
      heading: "Ongoing Support",
      text: "logo",
      content:
        " Sessions 2 and 3 serve as checkpoints to monitor your progress. We celebrate your successes, address any challenges, and refine your strategies as needed. This continuous feedback loop ensures that you stay on track and motivated.",
    },
    {
      id: 5,
      Image: Health3,
      heading: "Empowering Interaction",
      text: "logo",
      content:
        " Nydra Well empowers you to actively participate in your journey. You're encouraged to ask questions, share concerns, and engage in one-on-one discussions with your coach. This personalized interaction enhances your learning experience and ensures that your specific needs are met.",
    },
    {
      id: 6,
      Image: Health2,
      heading: "Hybrid Approach",
      text: "logo",
      content:
        " Unlike generic sleep apps or programs, Nydra Well combines the power of digital therapeutics with face-to-face interactions. Our hybrid strategy leverages technology for data-driven insights while preserving the invaluable human connection, which is essential for your success.",
    },
    {
      id: 7,
      Image: Health1,
      heading: "Sustainable Progress",
      text: "logo",
      content:
        " Our program is designed to avoid overwhelming you with constant data. Instead, we focus on specific milestones and setpoints, allowing you to make steady progress without feeling pressured. This approach encourages you to continue improving your sleep habits over time, fostering long-term health benefits.",
    },
  ];
  const HealthCardComponent = ({ Image, text, heading, content }: any) => {
    return (
      <div className={`px-md-3 pb-3`}>
        <div className="healthCard px-md-2">
          <div className="text-center p-md-4">
            <img src={Image} alt={text} width="250px" height="250px" />
          </div>
          <div className="pt-md-3 pt-2">
            <h5 className="fs-24 ff-Sora text-center">{heading}</h5>
            <p className="fs-18 ff-inter text-center"> {content}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="d-none d-md-block p-0 m-0">
        <div className=" main_top_bg">
          <div className="container">
            <div className="getmore text-center pt-md-5">
              Your Path to Lasting Sleep Health and Wellness Transformation
            </div>
            <div className="relationship">
              The Nydra Well program offers a tailored approach to transforming
              your sleep habits and enhancing your overall health and
              well-being. Through a series of three structured sessions spaced
              four weeks apart, our dedicated sleep coaches guide you on a
              personalized journey to better sleep. Here's what sets our program
              apart:
            </div>
          </div>
        </div>
        <div className="container-xxl mt-md-5">
          <div className="row d-flex justify-content-center">
            {HealthCardData.map((data: any) => {
              return (
                <div key={data.id} className="col-md-3">
                  <HealthCardComponent
                    Image={data.Image}
                    heading={data.heading}
                    content={data.content}
                    text={data.text}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Container fluid className="d-block d-md-none p-0 m-0">
        <div className=" main_top_bg">
          <div>
            <div className="getmore pt-md-5">
              Your Path to Lasting Sleep Health and Wellness Transformation
            </div>
            <div className="relationship">
              The Nydra Well program offers a tailored approach to transforming
              your sleep habits and enhancing your overall health and
              well-being. Through a series of three structured sessions spaced
              four weeks apart, our dedicated sleep coaches guide you on a
              personalized journey to better sleep. Here's what sets our program
              apart:
            </div>
          </div>
        </div>
        <div>
          <Carousel>
            {HealthCardData.map((data: any) => {
              return (
                <Carousel.Item
                  className="carousel_item"
                  style={{ height: "30rem" }}
                  key={data.id}
                >
                  <div className="text-center">
                    <img src={data.Image} width="140px" height="140px" />
                  </div>

                  <Carousel.Caption className="p-0 m-0">
                    <h5 className="mt-4">{data.heading}</h5>
                    <p className="mt-2 text-black p-0">{data.content}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </Container>
      {/* <div>
        <div className="d-none d-md-block main_top_bg">
          <div  >
            <div className="getmore text-center pt-md-5">
              Your Path to Lasting Sleep Health and Wellness Transformation
            </div>
            <div className="relationship">
              The Nydra Well program offers a tailored approach to transforming
              your sleep habits and enhancing your overall health and
              well-being. Through a series of three structured sessions spaced
              four weeks apart, our dedicated sleep coaches guide you on a
              personalized journey to better sleep. Here's what sets our program
              apart:
            </div>
          </div>
        </div>
        <Container>
          <div>
            <div className="d-block d-md-none">
              <div className="getmore">
                Your Path to Lasting Sleep Health and Wellness Transformation
              </div>
              <div className="relationship">
                The Nydra Well program offers a tailored approach to
                transforming your sleep habits and enhancing your overall health
                and well-being. Through a series of three structured sessions
                spaced four weeks apart, our dedicated sleep coaches guide you
                on a personalized journey to better sleep. Here's what sets our
                program apart:
              </div>
            </div>
          </div>
          <div className=" d-none d-md-block">
            <div className="row d-flex justify-content-between align-items-center py-md-3">
              <div className="col-md-3">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health7} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Personalized Approach</h5>
                    <p className="card-text px-md-2">
                      Our program begins with a comprehensive assessment,
                      utilizing wearable trackers and detailed questionnaires.
                      This information helps us understand your unique sleep
                      patterns and challenges.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health6} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Expert Guidance</h5>
                    <p className="card-text px-md-2">
                      In Session 1, we analyze your sleep data and discuss the
                      questionnaire results with you. Together, we identify
                      areas for improvement and co-create strategies to enhance
                      your sleep quality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health5} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Gradual Implementation</h5>
                    <p className="card-text px-md-2">
                      We believe in giving you the time to incorporate new
                      habits into your routine. By allowing four-week intervals
                      between sessions, we provide the space needed for these
                      changes to take root, ensuring they become lasting habits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health4} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Ongoing Support</h5>
                    <p className="card-text px-md-2">
                      Sessions 2 and 3 serve as checkpoints to monitor your
                      progress. We celebrate your successes, address any
                      challenges, and refine your strategies as needed. This
                      continuous feedback loop ensures that you stay on track
                      and motivated.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-md-3 d-flex justify-content-between">
              <div className="col-md-4 ">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health3} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Empowering Interaction</h5>
                    <p className="card-text px-md-2">
                      Nydra Well empowers you to actively participate in your
                      journey. You're encouraged to ask questions, share
                      concerns, and engage in one-on-one discussions with your
                      coach. This personalized interaction enhances your
                      learning experience and ensures that your specific needs
                      are met.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health2} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Hybrid Approach</h5>
                    <p className="card-text px-md-2">
                      Unlike generic sleep apps or programs, Nydra Well combines
                      the power of digital therapeutics with face-to-face
                      interactions. Our hybrid strategy leverages technology for
                      data-driven insights while preserving the invaluable human
                      connection, which is essential for your success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="healthCard ">
                  <div style={{ marginLeft: "-12px", marginTop: "20px" }}>
                    <img src={Health1} className="card-img-top" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">Increased Obesity Risk</h5>
                    <p className="card-text px-md-2">
                      Our program is designed to avoid overwhelming you with
                      constant data. Instead, we focus on specific milestones
                      and setpoints, allowing you to make steady progress
                      without feeling pressured. This approach encourages you to
                      continue improving your sleep habits over time, fostering
                      long-term health benefits.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
      {/* <div className="d-flex justify-content-evenly evenly p-2 my-md-4 ">
              <div className="mt-2 me-2">
                <i className=" fa fa-exclamation-circle text-primary"></i>
              </div>
              <div className="my-md-3">
                <div className="the_relation text-start">
                  Sustainable Progress
                </div>
                <p>
                  Our program is designed to avoid overwhelming you with
                  constant data. Instead, we focus on specific milestones and
                  setpoints, allowing you to make steady progress without
                  feeling pressured. This approach encourages you to continue
                  improving your sleep habits over time, fostering long-term
                  health benefits.
                </p>
              </div>
            </div> */}
      {/* </div> */}
      {/* </Container> */}
      {/* </div> */}
      {/* <Carousel className="d-block d-sm-none p-3 active">
        <Carousel.Item interval={1000} className="carousel_item ">
          <img src={Health1} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={500} className="carousel_item">
          <img src={Health2} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel_item">
          <img src={Health3} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel_item">
          <img src={Health4} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel_item">
          <img src={Health5} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel_item">
          <img src={Health6} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel_item">
          <img src={Health7} className="card_img" />
          <Carousel.Caption>
            <h5 className="card-title">Increased Obesity Risk</h5>
            <p className="card-text card-text-p mt-3">
              Consistent short sleep disrupts appetite and metabolism,
              increasing obesity risk.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>  */}
    </>
  );
};

export default HealthPageComponent;
