import React, { useContext, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import AuthContext from "../../context/authContext1";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import Spinner from "../../utils/Spinner";

const TermAndCondition = ({ setShowLoading }: any) => {
  const { updateSubscriptionFlowStep } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const submitHandler = () => {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/submitTermsConditions",
      data: {
        termsConditions: 1,
      },
    })
      .then((response) => {
        if (response.data.status) {
          updateSubscriptionFlowStep("payment");
        }
      })
      .catch((error) => {
        return;
      })
      .finally(() => setShowLoading(false));
  };
  return (
    <>
      <Container fluid className="px-3 px-xl-5 pb-md-5">
        <Card className="px-md-3 py-md-3">
          <div className=" terms_scroll pt-3 px-3 px-md-0">
            <h5 className="d-flex justify-content-center">Terms Of Service</h5>
            <p>
              These Terms of Service (“Terms”) are an agreement between you
              (“you”) and __Nydra Well,LLC________ (including without limitation
              its brands and affiliates, such as Nydrahealth (“Company”, , “we”,
              “us” or “our”) that allows you to use our applicable website(s),
              application(s) (“app(s)”), tools, software, subscriptions,
              memberships, content, API(s), widgets, and/or other products and
              services (collectively, as applicable, the "Services"), as long as
              you follow these Terms. By accessing or using any of our Services,
              including without limitation by downloading, installing, or using
              any associated Software (defined below), you signify your
              agreement to all of the following, which are all expressly
              incorporated herein and must also be observed and followed
              (collectively, the “Agreement”):
            </p>
            <p className="px-5">(1) these Terms;</p>
            <p className="px-5">
              (2) our privacy policy as posted on our website (“Privacy
              Policy”);
            </p>
            <p className="px-5">
              (3) any other standard policies or community guidelines (such as
              Acceptable Use Policies), if any, posted in any applicable parts
              of our Services; and
            </p>
            <p>
              We do not provide medical advice. The Company is not engaged in
              the practice of medicine and provides the Service solely for
              informational and educational purposes. You should not use the
              Service to diagnose, treat, or mitigate any disease, disorder or
              abnormal physical state or prescribe any medication or treatment
              for yourself or any other person. For greater certainty, the
              Service itself is not to be used in the diagnosis, treatment,
              mitigation, or prevention of a medical condition. Consult with
              your healthcare professional before relying on any information or
              acting on any recommendations provided in the Service.
            </p>
            <p>To Use Our Services (defined below), You: </p>
            <p>
              <ul>
                <li>
                  May need to register with us to access and use some of our
                  Services
                </li>
                <li>
                  Must not initiate or participate in any activities on our
                  Services that are illegal, harmful, or interfere with anyone's
                  use of our services, including the sending of e-mail or
                  instant messaging spam
                </li>
              </ul>
            </p>
            <p>If You Post Content On our Services, You: </p>
            <p>
              <ul>
                <li>
                  May post content that you create or have been given permission
                  to post by the owner, is legal, and doesn't violate this
                  Agreement
                </li>
                <li>
                  Are responsible for content, data and information that you
                  post to our Services and assume all risks of posting personal
                  information online
                </li>
                <li>
                  Continue to own the content but grant us a license to use and
                  distribute your content, subject to and in accordance with
                  this Agreement.
                </li>
              </ul>
            </p>
            <p>1. DESCRIPTION OF SERVICES </p>
            <p>
              1.1. As used herein, “Software” means any software (including
              without limitation APIs or apps) supplied by or on behalf of the
              Company, including without limitation any for which the purpose is
              to enable you to use certain portions of the Services. For
              clarity, the Software is a part of the Services.
            </p>
            <p>
              1.2. Any reference to “www.nydrahealth.com”, “nydrawell.com”, the
              “website(s)”, the “web site(s)”, the “site”, or other similar
              references, shall include any and all pages, subdomains,
              affiliated domains, brands, products or other areas of our
              website, or any other affiliated sites or domains owned or
              operated by or on behalf of us, plus any of the online content,
              information and services as made available in or through the
              website.
            </p>
            <p>
              1.3. The Services include without limitation all aspects of the
              website, or of any app or other product or service, including but
              not limited to all products, Software and other applications,
              features, channels and services offered therein.
            </p>
            <p>
              1.4. Any reference to “content” shall include all content in all
              forms or mediums, such as (without limitation) text, software,
              scripts, graphics, photos, sounds, music, videos, audiovisual
              combinations, interactive features and other materials you may
              view on, access through, or contribute to the Services.
            </p>
            <p>
              1.5. You must be at least 18 years of age, and, where law requires
              an older legal age, of legal age for contractual consent or older
              to use this website and/or the other Services. Due to the age
              restrictions, no content or information from the Services falls
              within the Child Online Privacy Protection Act (“COPA”) and is not
              monitored as doing so.
            </p>
            <p>
              1.6. All information and services are exchanged electronically,
              via the internet. You are responsible for maintaining your own
              access to the internet and for obtaining and maintaining any
              equipment and ancillary services needed to connect to, access or
              otherwise use the Services, including, without limitation, modems,
              hardware, servers, software, operating systems, networking, web
              servers and the like (collectively, “Equipment”). You shall also
              be responsible for maintaining the security of the Equipment. You
              consent to receiving communications electronically.
            </p>
            <p>
              1.7. Company is a privately owned and operated company and does
              not represent or speak for any governmental office or authority.
            </p>
            <p>
              1.8. You may acquire additional products, services and/or content
              of ours from our websites or Services. We reserve the right to
              require that you agree to separate agreements as a condition of
              your use and/or purchase of such additional products, services
              and/or content, which terms will apply in addition to these Terms.
            </p>
            <p>2. PASSWORDS AND ACCESS </p>
            <p>
              2.1. In order to access some features of the Services, you may
              have to register or create an account. You may never use another's
              account without permission. When creating your account, you must
              provide accurate and complete information. Registration or
              subscription to the Services and payment of any applicable fee,
              authorizes a single individual to use the Services unless
              otherwise expressly stated. You are solely responsible for the
              activity that occurs on your account, and you must keep your
              account password secure. You must notify Company immediately of
              any breach of security or unauthorized use of your account.
              Although Company will not be liable for your losses caused by any
              unauthorized use of your account, you may be liable for the losses
              of Company or others due to such unauthorized use. It is a
              condition of your use of the Services that all the information you
              provide will be correct, current, and complete; If we believe the
              information you provide is not correct, current, or complete, we
              have the right to refuse you access to the Services or any of its
              resources, and to terminate or suspend your access at any time,
              without notice.
            </p>
            <p>
              2.2. You shall not copy, reproduce, distribute, transmit,
              broadcast, display, sell, license, or otherwise exploit any
              content for any other purposes without the prior written consent
              of Company or the respective licensors of the content. Company and
              its licensors reserve all rights not expressly granted in and to
              the Services and their content.
            </p>
            <p>
              2.3. You agree not to circumvent, disable or otherwise interfere
              with security-related features of the Services or features that
              prevent or restrict use or copying of any content or enforce
              limitations on use of the Services or the content therein.
            </p>
            <p>3. YOUR OBLIGATIONS</p>
            <p>
              3.1. You represent and warrant that all information that you
              provide to us will be true, accurate, complete and current, and
              that you have the right to provide such information to us in
              connection with your use of the Services. You must comply with the
              terms of any applicable policies posted in our Services, including
              any Acceptable Use Policy.
            </p>
            <p>You may not:</p>
            <ol>
              <li>
                {" "}
                restrict or inhibit any other user from using and enjoying the
                Services;
              </li>
              <li>
                {" "}
                post or transmit any unlawful, fraudulent, libelous, defamatory,
                obscene, pornographic, vulgar, sexually-orientated, profane,
                threatening, abusive, hateful, offensive, false, misleading,
                derogatory, or otherwise objectionable information of any kind,
                including without limitation any transmissions constituting or
                encouraging conduct that would constitute a criminal offense,
                give rise to civil liability, or otherwise violate any local,
                state, national or foreign law, including without limitation the
                U.S. export control laws and regulations;
              </li>
              <li>
                {" "}
                post or transmit any advertisements, solicitations, chain
                letters, pyramid schemes, investment opportunities or schemes or
                other unsolicited commercial communication (except as otherwise
                expressly permitted by Company) or engage in spamming or
                flooding;
              </li>
              <li>
                {" "}
                post or transmit any information or software which contains a
                virus, Trojan horse, worm or other harmful component;
              </li>
              <li>
                {" "}
                upload, post, publish, reproduce, transmit or distribute in any
                way any component of the Services itself or derivative works
                with respect thereto;
              </li>
              <li>
                {" "}
                resell or otherwise exploit for commercial purposes, directly or
                indirectly, any portion of the Services, or access to them;
              </li>
              <li>
                {" "}
                use email addresses obtained from the Services for solicitation
                purposes of any kind, directly or indirectly;
              </li>
              <li>
                {" "}
                use data mining, robots or other similar data gathering and
                extraction tools;
              </li>
              <li>
                {" "}
                access (or attempt to access) the Services or any content
                therein through any automated means (including use of any script
                or web crawler, page-scrape, spider, robot, index, Internet
                agent or other automatic device, program, algorithm or
                technology which does the same things) including without
                limitation, to use, access, copy, acquire information, generate
                impressions, input information, store information, search,
                generate searches or monitor any portion of this website;
              </li>
              <li>
                {" "}
                make any derivative works based, in whole or in part, on any
                portion or all of the Services;
              </li>
              <li>
                {" "}
                use i-frames, webpage frames, or any similar framing, to
                enclose, capture or distribute any part of the Services;
              </li>
              <li>
                {" "}
                mirror or cache or store any pages or portions of the Service;
              </li>
              <li>
                {" "}
                use a false email address, impersonate any person or entity,
                forge e-mail headers or otherwise disguise the origin of any
                communication or mislead as to the source of the information you
                provide to the Services;
              </li>
              <li>
                {" "}
                portray Company or any company affiliated with it in a negative
                manner or otherwise portray its Services in a false, misleading,
                derogatory or offensive manner;
              </li>
              <li>
                {" "}
                use the Services in any manner that could damage, disable,
                overburden, or impair our servers or interfere with any other
                party's use and enjoyment of the Services;
              </li>
              <li>
                {" "}
                attempt to gain unauthorized access to any services or
                information to which you have not been granted access through
                password mining or any other process;
              </li>
              <li>
                {" "}
                post or transmit any photograph or likeness of another person
                without that person's consent, if and to the extent necessary
                under applicable laws;
              </li>
              <li>
                {" "}
                post, publish, transmit, reproduce, distribute or in any way
                exploit any information, software or other material obtained
                through the Services for commercial purposes (other than as
                expressly permitted by the Services and by the provider of such
                information, software or other material); or
              </li>
              <li>
                {" "}
                upload, post, publish, transmit, reproduce, or distribute in any
                way, information, software or other material obtained through
                the Services which is protected by copyright, or other
                proprietary right, or derivative works with respect thereto,
                without obtaining permission of the copyright owner or
                right-holder, or which otherwise violates or infringes the
                rights of others, including without limitation, patent,
                trademark, trade secret, copyright, publicity, or other
                proprietary rights.
              </li>
            </ol>
            <p></p>

            <p className="px-3">
              3.2. Company has no obligation to monitor the Services. However,
              you acknowledge and agree that Company has the right to monitor
              the Services electronically from time to time, and to disclose any
              information as necessary or appropriate to satisfy any law,
              regulation or other governmental request, to operate the Services
              properly, or to protect itself or its customers. Company reserves
              the right to refuse to post or to remove any information or
              materials, in whole or in part, that, in its sole discretion, are
              unacceptable, undesirable, inappropriate or in violation of this
              Agreement.
              <p></p>
              3.3. With respect to any Software, including without limitation
              subscription software as a service, subject to the terms and
              conditions of this Agreement, we hereby grant to you a limited,
              nonexclusive, non-transferable license to access and use such
              Software during the term of this Agreement, solely by the number
              of authorized users, as set forth on the applicable ordering
              document or as otherwise mutually agreed, and solely for internal
              and non-commercial purposes.
              <p></p>
              3.4. You shall not, and shall not attempt to (and shall not
              authorize or allow any third party to or attempt to): (a) download
              or otherwise obtain a copy of the Service (as applicable as such
              term is used herein, including any portion thereof) in any form;
              (b) reverse engineer, reverse compile, decompile, disassemble, or
              translate, exploit, or otherwise derive the source code of the
              Service or otherwise modify, the Service, or create any derivative
              works thereof; or (c) use the Service on behalf of any third party
              or for any purpose other than as described in this Agreement; (d)
              sell, resell, lease, license, sublicense, distribute, reproduce,
              copy, duplicate, or otherwise transfer or exploit the Service or
              use it as a service bureau; (e) post, send, process or store
              infringing, obscene, threatening, libelous, or otherwise unlawful
              or tortuous material, including material violating of third party
              rights; (f) post, send, process or store material containing
              software viruses, worms, Trojan horses or other harmful or
              malicious computer code, files, scripts, agents or programs; (g)
              interfere with or disrupt the integrity or performance of the
              Service or attempt to gain unauthorized access to the Service or
              related systems or networks; (h) remove, alter or obscure any
              titles, product logo or brand name, trademarks, copyright notices,
              proprietary notices or other indications of the intellectual
              property rights and/or our rights and ownership thereof, whether
              such notice or indications are affixed on, contained in or
              otherwise connected to the software or on any copies made in
              accordance with this Agreement; (i) remove, alter or obscure any
              titles, product logo or brand name, trademarks, copyright notices,
              proprietary notices or other indications of the intellectual
              property rights and/or our rights and ownership thereof, whether
              such notice or indications are affixed on, contained in or
              otherwise connected to the Service, or documentation, or on any
              copies made in accordance with this Agreement; (j) use, or
              authorize or permit the use of, the Service except as expressly
              permitted herein; (k) use the Service to perform any activity
              which is or may be, directly or indirectly, unlawful, harmful,
              threatening, abusive, harassing, tortuous, or defamatory, nor to
              perform any activity which breaches the rights of any third party.
              The Service may be used only by you (i) for your internal business
              purposes and only for your direct benefit; (ii) only by the number
              of persons for whom a license fee has been paid, and all such use
              may only be by those persons using the Service for the benefit of
              you in the course and scope of their employment, subject to the
              terms hereof; (iii) only in its original form without alteration
              or combination with other products, services or software except as
              expressly authorized in any applicable documentation; and (iv) in
              compliance with all applicable laws and in compliance with all
              documentation and instructions provided by us. You agree not to
              copy, duplicate or imitate, in whole or in part, any concept,
              idea, business model, business process, product, service or other
              intellectual property or other ideas or content embodied in the
              Services or learned by you from your use of or access to the
              Services. You agree not to use the Services to violate any local,
              state, national or international law or to impersonate any person
              or entity, or otherwise misrepresent your identity or your
              affiliation with a person or entity. Materials you post to the
              Services may not contain URLs or links to websites that compete
              with the Services nor other competitive content or references.
              <p></p>
              3.5. We grant to you a limited, revocable, and nonexclusive right
              to create a hyperlink to our publicly available website(s),
              subject to the terms hereof, provided that the link does not
              portray us or our products or services in a false, misleading,
              derogatory, or offensive matter, and provided you do not i-frame
              them or alter the content therein or in any way imply a
              relationship therewith. You may not use any logo, trademark, or
              tradename that may be displayed on this site or other proprietary
              graphic image in the link without our prior written consent.
              <p></p>
              3.6. You will keep and protect any of our Confidential Information
              as confidential, using at least the same efforts you use to
              protect your own confidential information and in no event less
              than reasonable and industry standard efforts. Our “Confidential
              Information” includes the Services, documentation and information
              about the Services and their operation, and any other information
              you obtain from or about us or from or about the Services, or any
              other information which a reasonable person would or should
              understand to be confidential or proprietary in nature. You agree
              to return or destroy our Confidential Information when this
              Agreement is over. You acknowledge and agree we shall be entitled
              to seek equitable relief in any court of competent jurisdiction
              without the necessity of posting bond and in addition to such
              other remedies as may be available under law or in equity. Your
              confidentiality obligations shall survive termination or
              expiration of this Agreement.
              <p></p>
              3.7. While this Section highlights some of your key obligations,
              headers and section titles are for convenience only, and you are
              bound by all the terms of this Agreement.
            </p>
            <p className="px-3">4. PUBLIC POSTINGS AND LICENSED MATERIALS</p>
            <p className="px-3">
              4.1. We will use reasonable efforts to protect the confidentiality
              of certain personally identifiable information you submit to us
              (e.g., your address and credit card information submitted by you
              initially for the purpose subscribing to the Service) (“Personally
              Identifiable Information”), in accordance with the Privacy Policy
              posted on our website.
            </p>
            <p className="px-3">
              4.2. Certain material you may post on our Services is or may be
              available to the public, including without limitation any public
              profile data, feedback, questions, comments, suggestions, uploads,
              blog entries, ratings, reviews, images, videos, poll answers,
              etc., in any form or media, that you post via the Services or
              otherwise (collectively, "Public Postings"). These Public Postings
              will be treated as non-confidential and nonproprietary. You are
              responsible for any Public Postings and the consequences of
              sharing or publishing such content with others or the general
              public. This includes, for example, any personal information, such
              as your address, the address of others, or your current location.
              WE ARE NOT RESPONSIBLE FOR THE CONSEQUENCES OF PUBLICLY SHARING OR
              POSTING ANY PERSONAL OR OTHER INFORMATION ON THE SERVICES.
            </p>
            <p className="px-3">
              4.3. Other content or communications you transmit to us, including
              without limitation any feedback, data, questions, comments,
              suggestions, in any form or media, that you submit to us via
              e-mail, the Services or otherwise (to the extent excluding any
              Personally Identifiable Information, collectively, "Submissions"),
              will be treated as non-confidential and nonproprietary.
            </p>
            <p className="px-3">
              4.4. By providing any Public Posting or Submission, you (i) grant
              to Company a royalty-free, non-exclusive, perpetual, irrevocable,
              sub-licensable right to use, reproduce, modify, adapt, publish,
              translate, create derivative works (including products) from,
              distribute, and display such content throughout the world in all
              media and you license to us all patent, trademark, trade secret,
              copyright or other proprietary rights in and to such content for
              publication on the Services pursuant to this Agreement; (ii) agree
              that we shall be free to use any ideas, concepts or techniques
              embodied therein for any purpose whatsoever, including, but not
              limited to, developing and marketing products or services
              incorporating such ideas, concepts, or techniques, without
              attribution, without any liability or obligation to you; (iii)
              grant to Company the right to use the name that you submit in
              connection with such content. In addition, you hereby waive all
              moral rights you may have in any Public Posting or Submissions.
            </p>
            <p className="px-3">
              4.5. You shall be solely responsible for your own content and any
              Public Postings and Submissions. You affirm, represent, and
              warrant that you own or have the necessary licenses, rights,
              consents, and permissions to publish content you post or submit.
              You further agree that content you submit via Public Postings or
              Submissions will not contain third party copyrighted material, or
              material that is subject to other third party proprietary rights,
              unless you have permission from the rightful owner of the material
              or you are otherwise legally entitled to post the material and to
              grant us all of the license rights granted herein. You further
              agree that you will not submit to the Services any content or
              other material that is contrary to any posted “community
              guidelines” or similarly titled document, if any, which may be
              updated from time to time, or contrary to applicable local,
              national, and international laws and regulations.
            </p>
            <p className="px-3">
              4.6. We do not endorse any content submitted to the Services by
              any user or other licensor, or any opinion, recommendation, or
              advice expressed therein, and we expressly disclaim any and all
              liability in connection with content. We do not permit copyright
              infringing activities and infringement of intellectual property
              rights on the Services, and we will remove all content if properly
              notified that such content infringes on another's intellectual
              property rights as set forth herein below. We reserve the right to
              remove content without prior notice. We reserve the right to
              decide whether your content violates this Agreement for reasons
              other than copyright infringement, such as, but not limited to,
              pornography, obscenity, or excessive length. We may at any time,
              without prior notice and in our sole discretion, remove such
              content and/or terminate a user's account or otherwise block
              access for submitting such material in violation of this
              Agreement.
            </p>
            <p className="px-3">
              4.7. We are not responsible for end user error or errors in inputs
              or for errors in any user supplied data. We do not independently
              verify the truthfulness or accuracy of any data or content input
              into the Services and are not responsible for the fraud,
              misrepresentation, negligence or misconduct of any end user or
              other third party.
            </p>
            <p className="px-3">5. FEES AND PAYMENTS</p>
            <p className="px-3">
              5.1. If and to the extent any portion of the Services may require
              a fee payment or incremental payment or subscription, you agree to
              pay Company any applicable fees posted for the Services. By
              completing and submitting any credit card or other payment
              authorization through the Services, you are authorizing Company to
              charge the fees to the account you identify. You must keep all
              billing information, including payment method, up to date. You
              agree to pay us for all charges incurred under your account,
              including all applicable taxes, fees, and surcharges. You
              authorize and direct us to charge your designated payment method
              for these charges or, if your designated payment method fails, to
              charge any other payment method you have on file with us. Further,
              you authorize and direct us to retain information about the
              payment method(s) associated with your account. If we do not
              receive payment from your designated payment method or any other
              payment method on file, you agree to pay all amounts due upon
              demand by us. You will be responsible for accrued but unpaid
              charges, even if your account is canceled by you or terminated by
              us. During any free trial or other promotion, if any, you will
              still be responsible for any purchases and surcharges incurred
              using your account.
            </p>
            <p className="px-3">
              5.2. After 30 days from the date of any unpaid charges, your
              fee-based Services will be deemed delinquent and we may terminate
              or suspend your account and Services for nonpayment. We reserve
              the right to assess an additional 1.5 percent late charge (or the
              highest amount allowed by law, whichever is lower) per month if
              your payment is more than 30 days past due and to use any lawful
              means to collect any unpaid charges. You are liable for any fees,
              including attorney and collection fees, incurred by us in our
              efforts to collect any remaining balances from you. Except as
              otherwise mutually agreed in writing, we reserve the right to
              change our fees with 30 days’ notice.
            </p>
            <p className="px-3">6. WARRANTIES AND LIMITATIONS OF WARRANTIES.</p>
            <p className="px-3">
              6.1. If you are not completely satisfied with the Services, your
              sole remedy is to cease using the Services. With respect to any
              fee-based Services, if you signed up for designated term or
              timeframe, you will still be responsible for payment for the full
              term. If you did not subscribe for any minimum period, then you
              may cancel at any time on 30 days advanced notice, and cease to
              use the Services, then you will not be charged any additional
              amounts after the effective date of such termination. In any case,
              you will be responsible for any and all charges and activity
              accrued prior to your Services termination date, and those
              obligations will survive your termination of the Services. Company
              undertakes commercially reasonable efforts to ensure that the
              information it provides is current and accurate, however, Company
              does not warrant the accuracy of information. Company also
              undertakes commercially reasonable efforts to protect the
              confidentiality of any confidential information you provide, in
              accordance with the Privacy Policy, however, Company does not
              guaranty the confidentiality of such information against
              unauthorized third party access or system failure.
            </p>
            <p className="px-3">
              6.2. THE SERVICES, THE WEBSITE, APPS, AND ALL INFORMATION,
              CONTENT, AND MATERIALS RELATED TO THE FOREGOING, ARE PROVIDED "AS
              IS." EXCEPT AS EXPRESSLY STATED IN THIS AGREEMENT, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, NON-INFRINGEMENT, NON-INTERFERENCE, SYSTEM INTEGRATION AND
              ACCURACY OF DATA. WE DO NOT WARRANT THAT USE OF THE SERVICES WILL
              BE UNINTERRUPTED, ERROR-FREE OR VIRUS FREE. ALTHOUGH INFORMATION
              THAT YOU SUBMIT MAY BE PASSWORD PROTECTED, WE DO NOT GUARANTEE THE
              SECURITY OF ANY INFORMATION TRANSMITTED TO OR FROM THE SERVICES
              AND YOU AGREE TO ASSUME THE SECURITY RISK FOR ANY INFORMATION YOU
              PROVIDE THROUGH THE SERVICES.
            </p>
            <p className="px-3">7. LIMITATIONS OF LIABILITY.</p>
            <p className="px-3">
              7.1. IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE FOR ANY
              INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, OR FOR
              LOSS OF PROFITS OR DAMAGES ARISING DUE TO BUSINESS INTERRUPTION OR
              FROM LOSS OR INACCURACY OF INFORMATION, INCLUDING IF AND TO THE
              EXTENT ANY OF THE FOREGOING ARISES IN CONNECTION WITH THIS
              AGREEMENT OR YOUR USE OR INABILITY TO USE THE SERVICES, WHETHER OR
              NOT SUCH DAMAGES WERE FORESEEABLE AND EVEN IF WE WERE ADVISED THAT
              SUCH DAMAGES WERE LIKELY OR POSSIBLE. IN NO EVENT WILL THE
              AGGREGATE LIABILITY OF US TO YOU FOR ANY AND ALL CLAIMS ARISING IN
              CONNECTION WITH THIS AGREEMENT OR THE SERVICES, EXCEED THE TOTAL
              FEES PAID TO US BY YOU, IF ANY, DURING THE SIX-MONTH PERIOD
              PRECEDING THE DATE OF ANY CLAIM (OR $10 IF THE SERVICES ARE FREE).
              YOU ACKNOWLEDGE THAT THIS LIMITATION OF LIABILITY IS AN ESSENTIAL
              TERM BETWEEN YOU AND US RELATING TO THE PROVISION OF THE SERVICE
              TO YOU AND WE WOULD NOT PROVIDE THE SERVICE TO YOU WITHOUT THIS
              LIMITATION.
            </p>
            <p className="px-3">
              7.2. YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS US AND OUR
              AFFILIATED COMPANIES, AND EACH OF OUR AND THEIR RESPECTIVE
              OFFICERS, DIRECTORS, MEMBERS, AGENTS, AND EMPLOYEES FROM AND
              AGAINST ALL LOSSES, EXPENSES, DAMAGES, CLAIMS, JUDGMENTS, COSTS,
              EXPENSES AND LIABILITIES, INCLUDING REASONABLE ATTORNEYS' FEES,
              INCURRED BY US OR SUCH PARTIES AND/OR ARISING OUT OF OR RESULTING
              FROM (1) ANY ACTUAL OR ALLEGED VIOLATION BY YOU OF THIS AGREEMENT
              (INCLUDING ANY REPRESENTATION OR WARRANTY HEREIN); (2) ANY
              ACTIVITY RELATED TO YOUR ACCOUNT BY YOU OR ANY OTHER PERSON
              ACCESSING THE SERVICE WITH YOUR PASSWORD; (3) YOUR USE OF AND
              ACCESS TO THE SERVICES; (4) YOUR ACTUAL OR ALLEGED VIOLATION OF
              ANY THIRD PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY COPYRIGHT,
              PROPERTY OR PRIVACY RIGHT; (5) YOUR ACTUAL OR ALLEGED VIOLATION OF
              ANY LAW, RULE OR REGULATION; AND/OR (6) YOUR CONTENT OR DATA,
              INCLUDING IF IT CAUSES ANY DAMAGE TO A THIRD PARTY. YOUR DEFENSE,
              INDEMNIFICATION AND HOLD HARMLESS OBLIGATIONS IN THIS AGREEMENT
              WILL SURVIVE THIS AGREEMENT AND YOUR USE OF THE SERVICES.
            </p>
            <p className="px-3">
              7.3. You acknowledge that the information on the Website and other
              Services is provided 'as is' for general information only. You may
              use the Services for informational purposes only, as an aid, but
              only as one information source among many, and not as the sole
              basis for making any decisions; you must conduct proper due
              diligence and use your own judgment when making any decisions
              based on any information, analytics or reports derived from the
              Services.
            </p>
            <p className="px-3">
              7.4. We shall not be liable for any circumstances arising out of
              causes beyond our reasonable control or without our fault or
              negligence, including, but not limited to, Acts of God, acts of
              civil or military authority, fires, riots, wars, embargoes,
              Internet disruptions, hacker attacks, or communications failures,
              or other force majeure.
            </p>
            <p className="px-3">
              7.5. If we breach this Agreement, you agree that your exclusive
              remedy is to recover, from us or any affiliates, direct damages up
              to an amount equal to your Services fee for one month (or up to
              USD $10.00 if the Services are free). YOU CAN'T RECOVER ANY OTHER
              DAMAGES OR LOSSES, INCLUDING, WITHOUT LIMITATION, DIRECT,
              CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT, INCIDENTAL, OR
              PUNITIVE. These limitations and exclusions apply if this remedy
              doesn't fully compensate you for any losses or fails of its
              essential purpose or if we knew or should have known about the
              possibility of the damages. To the maximum extent permitted by
              law, these limitations and exclusions apply to anything related to
              this Agreement such as, without limitation, loss of content; any
              virus affecting your use of the Services; delays or failures in
              starting or completing transmissions or transactions; claims for
              breach of contract, warranty, guarantee, or condition; strict
              liability, negligence, misrepresentation, or omission; trespass,
              or other tort; violation of statute or regulation; or unjust
              enrichment.
              <b>
                Some or all of these limitations or exclusions may not apply to
                you if your state, province, or country doesn't allow the
                exclusion or limitation of incidental, consequential, or other
                damages.
              </b>
            </p>
            <p className="px-3">8. DURATION OF TERMS</p>
            <p className="px-3">
              8.1. Once in effect, this Agreement will continue in operation
              until terminated by either you or us. However, even after
              termination, the provisions of sections III through XVI of this
              Agreement will remain in effect in relation to any prior use of
              the Services by you. You may terminate this Agreement at any time
              and for any reason by providing notice to Company in the manner
              specified in this Agreement or by choosing to cancel your access
              to the Services using the tools provided for that purpose within
              the Services. We may terminate this Agreement without notice or,
              at our option, temporarily suspend your access to the Services, in
              the event that you breach this Agreement. Notwithstanding the
              foregoing, Company also reserves the right to terminate this
              Agreement at any time and for any reason by providing notice to
              you either through email or other reasonable means. After
              termination of this Agreement for any reason, you understand and
              acknowledge that Company will have no further obligation to
              provide the Services or access thereto. Upon termination, all
              licenses and other rights granted to you by this Agreement, if
              any, will immediately cease, but your licenses to us shall
              survive, and certain of your obligations (including payment
              obligations, if any) will survive in accordance with the terms
              hereof.
            </p>
            <p className="px-3">9. MODIFICATION OF TERMS</p>
            <p className="px-3">
              9.1. Company may change the terms of this Agreement from time to
              time. You will be notified of any such changes via e-mail (if you
              have provided a valid email address) and/or by our posting notice
              of the changes on the Services (which may consist of publishing
              the changes on our website). Any such changes will become
              effective when notice is received or when posted on the Services,
              whichever first occurs. If you object to any such changes, your
              sole recourse will be to terminate this Agreement. Continued use
              of the Services following such notice of any such changes will
              indicate your acknowledgement of such changes and agreement to be
              bound by such changes.
            </p>
            <p className="px-3">10. MODIFICATIONS TO SERVICES</p>
            <p className="px-3">
              10.1. We reserve the right to modify or discontinue the Services
              at any time with or without notice to you, including without
              limitation by adding or subtracting features and functionality,
              third party content, etc. In the event of such modification or
              discontinuation of the Services, your sole remedy shall be to
              terminate this Agreement as set forth herein. Continued use of the
              Services following notice of any such changes will indicate your
              acknowledgement and acceptance of such changes and satisfaction
              with the Services as so modified.
            </p>
            <p className="px-3">11. OWNERSHIP</p>
            <p className="px-3">
              11.1. We and/or our vendors and suppliers, as applicable, retain
              all right, title and interest in and to the Services, the website
              and all information, content, Software, and other software and
              materials provided by or on behalf of us, including but not
              limited to all text, images, videos, logos, button icons, audio
              clips, and the look and feel of the website and our brands and
              logos, and any data compilations, including without limitation any
              data input by or on behalf of us or our third party providers, and
              any data to the extent processed by, or resulting as an output of,
              the Services, and all Services usage data, statistical data or
              aggregated data collected or reported with respect to the any part
              or all of the Services, including without limitation any
              aggregated and anonymized data extracted or derived from the
              Service, including all aggregated and anonymized usage data,
              statistical data, transactional data, metadata, market data and
              other aggregated and anonymized data collected from user data and
              files. We own the rights to any metadata we collect from or about
              your use of the Services. Without limiting the generality of the
              foregoing, we reserve the right to create and market public
              indexes, analysis or insights created from such data. You agree
              that you will not copy, reproduce, distribute or create derivative
              works from any information, content, software or materials
              provided by us, or remove any copyright or other proprietary
              rights notices contained in any such information, content,
              software or materials without the copyright owner's prior written
              consent.
            </p>
            <p className="px-3">
              11.2. Unless otherwise stated, all content in our websites or
              other Services, is our property or the property of third
              parties. These contents are protected by copyright as a collective
              work and/or compilation, pursuant to U.S. copyright laws,
              international conventions and other copyright laws.
            </p>
            <p className="px-3">
              11.3. Your feedback is welcome and encouraged. You agree, however,
              that (i) by submitting unsolicited ideas to us, you automatically
              forfeit your right to any intellectual property rights in such
              ideas; and (ii) unsolicited ideas submitted to us or any of our
              employees or representatives automatically become our property.
            </p>
            <p className="px-3">12. INTERNATIONAL USERS</p>
            <p className="px-3">
              12.1. The Services are controlled, operated and administered by us
              from within the United States. We make no representation that this
              site is available for access or use at other locations outside the
              U.S. However, any access or use from outside the U.S. is still
              subject to this Agreement. Access to the Services is expressly
              prohibited from territories where this site or any portion thereof
              is illegal. You agree not to access or use any information or
              materials on the Services in violation of U.S. export laws and
              regulations, or in violation of any laws or regulations in the
              country from which you are accessing the Services. Any personal
              information which we may collect via the Services may be stored
              and processed in our servers located in the United States or in
              any other country in which we, or our affiliates, subsidiaries, or
              agents maintain facilities. You consent to any such transfer of
              personal information outside your country of residence to any such
              location.
            </p>
            <p className="px-3">13. THIRD PARTY CONTENT AND SERVICES</p>
            <p className="px-3">
              13.1. Certain content (including without limitation
              advertisements) on the Services may be supplied by third parties.
              Company does not have editorial control over such content. Any
              opinions, advice, statements, services, offers, or other
              information that constitutes part of the content expressed or made
              available by third parties, including without limitation,
              suppliers and vendors, advertisers, or any customer or user of the
              Services, are those of the respective authors or distributors and
              not of Company or its affiliates or any of its officers,
              directors, employees, or agents. In many instances, the content
              available on the Services represents the opinions and judgments of
              the respective third parties, whether or not under contract with
              Company. You may enter into correspondence with or participate in
              promotions of such third parties, such as advertisers promoting
              their products, services or content on this site. Any such
              correspondence or participation, including the delivery of and the
              payment for products, services or content, are solely between you
              and each such third party. Company neither endorses nor is
              responsible for the accuracy or reliability of any opinion,
              advice, submission, posting, or statement made on the Services.
              Under no circumstances shall Company, or its affiliates, or any of
              their respective officers, directors, employees, or agents, be
              liable for any loss or damage caused by your reliance on any
              content or other information obtained through the Services.
            </p>
            <p className="px-3">
              13.2. The Services may integrate and/or interact with third party
              services, such as via APIs or browser extensions. For example, the
              Services may leverage APIs from third parties, and/or rely on
              third party browser extensions, and Company has no affiliation,
              association, endorsement, or sponsorship by any other third party
              services with which it integrates or interacts from time to time
              (collectively, “Third Party Services”). Company makes no claim,
              representation or warranty of any kind, type or nature concerning
              any Third Party Services, nor Company’s or any User’s compliance
              with any third party terms of service for any such Third Party
              Services (collectively, “Third Party Terms”). It shall be each
              User’s sole responsibility to analyze and interpret any applicable
              Third Party Terms and comply therewith. Each User is solely
              responsible for their interpretation of Third Party Terms and
              their actions relevant to compliance thereof. By using the
              Services, you hereby release Company and waive any and all claims
              or claim rights that you may have against Company, and release and
              indemnify Company against any claims that any third party may have
              against you, including with respect to your use of any Third Party
              Services, including if accessed or used via our Services, and with
              respect to Third Party Terms, applicable privacy policies or any
              other rules or regulations of such third parties.
            </p>
            <p className="px-3">
              13.3. Without limiting the generality of the foregoing, we may
              elect, in our discretion, to utilize social logins, allowing you
              to login to the Services via other third party authentication
              services, such as (without limitation) your Facebook, Twitter,
              LinkedIn, Google, or other account credentials. You understand
              that these are Third Party Services, and this in no way creates
              and endorsement of, by or from us to them or vice versa, that we
              are not responsible for their logins, systems or data, and that by
              using such third party logins, you may be subject to their
              respective privacy policies and other terms of use.
            </p>
            <p className="px-3">
              13.4. You understand that when using the Services, you will be
              exposed to content from a variety of sources, and that we are not
              responsible for the accuracy, usefulness, safety, or intellectual
              property rights of or relating to such content. You further
              understand and acknowledge that you may be exposed to content that
              is inaccurate, offensive, indecent, or objectionable, and you
              agree to waive, and hereby do waive, any legal or equitable rights
              or remedies you have or may have against us with respect thereto,
              and, to the extent permitted by applicable law, agree to indemnify
              and hold harmless Company, its owners, operators, affiliates,
              licensors, and licensees to the fullest extent allowed by law
              regarding all matters related to your use of the Services.
            </p>
            <p className="px-3">
              13.5. As a convenience to you, Company may provide, in or through
              the Services, one or more links to third party web sites or
              services and/or provide email contacts respecting third parties.
              Company makes no endorsement of such third parties, nor any
              representation or warranty regarding anything that takes place
              between you and any such third parties, including, without
              limitation, visits to third party web sites, services, email
              correspondence with third parties, and business or other
              transactions with third parties found through the Services. Please
              understand that such third parties are independent from and not
              controlled by Company, even if, for example, a Company link or
              logo appears on a website linked from this website or our other
              Services. It is up to you to read those third party sites’
              applicable terms of use, privacy, and other applicable policies.
              For example, without limitation, we may outsource operation of
              certain aspects of our Services to one or more third parties, and
              they may have access to certain data by virtue of operating such
              Services, subject to their own policies.
            </p>
            <p className="px-3">
              14. CONSENT TO RECEIVE ELECTRONIC COMMUNICATIONS FROM COMPANY
            </p>
            <p className="px-3">
              14.1. Legal Communications. We may send the following to you by
              email or posting them on the Website and/or the Mobile App: these
              Terms & Conditions, including legal disclosures; future changes to
              these Terms & Conditions, Privacy Policy, and other notices, legal
              communications or disclosures and information related to the
              Service. Such communications are part of the Services which you
              cannot opt out of receiving.
            </p>
            <p className="px-3">
              14.2. Service Announcements. In using the Services, you may
              receive periodic electronic communications from Company regarding
              the Services, such as new product offers and other information
              regarding the Website and/or the Mobile App, which are part of the
              Services and which you cannot opt out of receiving.
            </p>
            <p className="px-3">
              14.3. Promotional Communications. You may also receive periodic
              promotions and other offers or materials Company believes might be
              of interest to you. You can opt-out of receiving these promotional
              messages at any time by (a) following the unsubscribe instructions
              contained in each message; or (b) changing the messaging
              preferences in your account.
            </p>
            <p className="px-3">
              electronically, please send an email to Contact@nydrawell.com;
              Attention: [Kishor Gudimetla]. Your withdrawal of consent shall be
              effective within a reasonable time after we receive your
              withdrawal notice described above. Your withdrawal of consent will
              not affect the legal validity or enforceability of the Terms
              provided to, and electronically signed by, you prior to the
              effective date of your withdrawal. If you withdraw your consent to
              receive Communications electronically, we may terminate your
              account and/or access to the Service (or any part thereof).
            </p>
            <p className="px-3">15. Digital Millennium Copyright Act </p>
            <p className="px-3">
              15.1. If you are a copyright owner or an agent thereof and believe
              that any content in our Services infringes upon your copyrights,
              you may submit a notification pursuant to the Digital Millennium
              Copyright Act ("DMCA") by providing our Copyright Agent with the
              following information in writing (see 17 U.S.C 512(c)(3) for
              further detail):
            </p>
            <p className="px-3">
              <ul>
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works at a single
                  online site are covered by a single notification, a
                  representative list of such works at that site;
                </li>
                <li>
                  {" "}
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled and
                  information reasonably sufficient to permit the service
                  provider to locate the material;
                </li>
                <li>
                  Information reasonably sufficient to permit the service
                  provider to contact you, such as an address, telephone number,
                  and, if available, an electronic mail;
                </li>
                <li>
                  A statement that you have a good faith belief that use of the
                  material in the manner complained of is not authorized by the
                  copyright owner, its agent, or the law; and
                </li>
                <li>
                  {" "}
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </li>
              </ul>
            </p>
            <p className="px-3">
              15.2. Company's designated method to receive notifications of
              claimed infringement is by emailing the Copyright Agent at
              contact@nydrawell.com.You acknowledge that if you fail to comply
              with all of the requirements of this Section your DMCA notice may
              not be valid.
            </p>
            <p className="px-3">
              15.3. If you believe that your content that was removed (or to
              which access was disabled) is not infringing, or that you have the
              authorization from the copyright owner, the copyright owner's
              agent, or pursuant to the law, to post and use the material in
              your content, you may send a counter-notice containing the
              following information to the Copyright Agent:
            </p>
            <p className="px-3">
              <ul>
                <li> Your physical or electronic signature;</li>
                <li>
                  Identification of the content that has been removed or to
                  which access has been disabled and the location at which the
                  content appeared before it was removed or disabled;
                </li>
                <li>
                  A statement that you have a good faith belief that the content
                  was removed or disabled as a result of mistake or a
                  misidentification of the content; and
                </li>
                <li>
                  Your name, address, telephone number, and e-mail address, a
                  statement that you consent to the jurisdiction of the federal
                  court in New York, and a statement that you will accept
                  service of process from the person who provided notification
                  of the alleged infringement.
                </li>
              </ul>
            </p>
            <p className="px-3">
              15.4. If a counter-notice is received by the Copyright Agent,
              Company may send a copy of the counter-notice to the original
              complaining party informing that person that it may replace the
              removed content or cease disabling it in 10 business days. Unless
              the copyright owner files an action seeking a court order against
              the content provider, member or user, the removed content may be
              replaced, or access to it restored, in 10 or more business days
              after receipt of the counter-notice, at Company's sole discretion.
            </p>
            <p></p>
            <p className="px-3"> 16. CLASS ACTION WAIVER AND ARBITRATION</p>
            <p className="px-3">
              <b>
                {" "}
                THIS SECTION CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS
                ACTION WAIVER. IT AFFECTS YOUR RIGHTS ABOUT HOW TO RESOLVE ANY
                DISPUTE WITH US.
              </b>
            </p>
            <p className="px-3">
              16.1. Except if you opt-out as expressly permitted below, or
              except to the extent contrary to applicable law ("Excluded
              Disputes"), you hereby agree that all disputes between you and us
              (whether or not such dispute involves a third party) with regard
              to your relationship with us, including without limitation
              disputes related to these Terms, your use of the Service, and/or
              rights of privacy and/or publicity, may, in our discretion, be
              resolved by binding, individual arbitration under the American
              Arbitration Association's rules for arbitration of
              consumer-related disputes and you hereby expressly waive trial by
              jury. As an alternative, you may bring your claim in your local
              "small claims" court, if permitted by that small claims court's
              rules. You may bring claims only on your own behalf. You agree
              that you shall not participate in any class action or class-wide
              arbitration for any claims covered by this Agreement. You also
              agree not to participate in claims brought in a private attorney
              general or representative capacity, or consolidated claims
              involving another person's account, if we are a party to the
              proceeding. This dispute resolution provision will be governed by
              the US Federal Arbitration Act, to the extent permissible. In the
              event the American Arbitration Association is unwilling or unable
              to set a hearing date within one hundred and sixty (160) days of
              filing the case, then either we or you can elect to have the
              arbitration administered instead by the Judicial Arbitration and
              Mediation Services. Judgment on the award rendered by the
              arbitrator may be entered in any court having competent
              jurisdiction. Any provision of applicable law notwithstanding, the
              arbitrator will not have authority to award damages, remedies or
              awards that conflict with these Terms. You may opt out of this
              agreement to arbitrate. If you do so, neither you nor we can
              require the other to participate in an arbitration proceeding. To
              opt out, you must notify us in writing within 30 days of the date
              that you first became subject to this arbitration provision. You
              must use this address to opt out: 53 Saratoga Lane Monroe NJ-08831
            </p>
            <p className="px-3">
              16.2. You must include your name and residence address, the email
              address you use for your account with us, and a clear statement
              that you want to opt out of this arbitration agreement. If and to
              the extent the prohibition against class actions and other claims
              brought on behalf of third parties contained above is found to be
              unenforceable, then such preceding language in this Arbitration
              section will be null and void. This arbitration agreement will
              survive the termination of your relationship with us.
            </p>
            <p className="px-3">16. HEALTH AND MEDICAL SERVICE DISCLAIMER</p>
            <p className="px-3">
              WE DO NOT PROVIDE PROFESSIONAL MEDICAL SERVICES OR ADVICE. THE
              SERVICES PROVIDED BY THE WEBSITE AND/OR MOBILE APP DO NOT CONTAIN
              OR CONSTITUTE, AND SHOULD NOT BE INTERPRETED AS, MEDICAL ADVICE OR
              OPINION. NO DOCTOR-PATIENT RELATIONSHIP IS CREATED. USE OF THE
              SERVICES IS NOT FOR MEDICAL EMERGENCIES. IF YOU THINK YOU HAVE A
              MEDICAL EMERGENCY, CALL 911.
            </p>
            <p className="px-3">
              16.1. No Doctor-Patient Relationship. Any and all services
              provided by, in and/or through the Services are for informational
              purposes only. Company is not a medical professional, and Company
              does not provide medical services or render medical advice.
              Nothing contained in the Services should be construed as such
              advice or diagnosis. The information and reports generated by us
              should not be interpreted as a substitute for physician
              consultation, evaluation, or treatment, and the information made
              available on or through the Services should not be relied upon
              when making medical decisions, or to diagnose or treat a medical
              or health condition. YOUR USE OF THE SERVICES DOES NOT CREATE A
              DOCTOR-PATIENT RELATIONSHIP BETWEEN YOU AND ANY OF THE COMPANY
              PARTIES (INCLUDING, WITHOUT LIMITATION, COACHES) OR ANY OF COMPANY
              SERVICE USERS).
            </p>
            <p className="px-3">
              You are urged and advised to seek the advice of a physician or a
              medical professional with any questions you may have regarding
              your health before beginning any sleep wellness regimen, physical
              activities or any other plans that may be referenced, discussed or
              offered under the Services. If you are being treated for an
              illness, taking prescription medication, or undergoing any
              treatment for a disease, it is especially urged to consult with
              your physician before using the Services. You represent to us
              (which representation shall be deemed to be made each time you use
              the Services), that you are not using the Services or
              participating in any of the activities offered by the Services for
              purpose of seeking medical attention. You further agree that,
              before using the services, you shall consult your physician. If
              any information you receive or obtain from using the Services that
              is inconsistent with the medical advice from your physician, you
              should follow the advice of your physician.
            </p>
            <p className="px-3">
              16.2. Virtual Sleep Coaching Service Disclaimer. If you
              participate in the personal sleep coaching services (“Virtual
              Coaching Service”) you will be able to interact with a Coach. A
              Coach will assist his/her users in developing skills to help them
              achieve their sleep wellness goals. Company may, in its sole
              discretion, engage or replace any Coach with another without
              notice to the Virtual Coaching Service users.
            </p>
            <p className="px-3">
              The services provided by our Virtual Coaching Service are not
              medical, mental health or any other type of health service.
              Virtual Coaching Service is not medical, psychotherapy or mental
              health counseling. No diagnosis or treatment of, or advice
              regarding, any medical condition or illness will be offered. The
              Virtual Coaching Service cannot substitute for, and is not an
              alternative to, medical or other healthcare diagnosis and
              treatment when a medical condition or illness is present. You are
              advised to seek diagnosis, treatment, and advice regarding medical
              conditions or illnesses from physicians practicing medicine and/or
              other licensed healthcare professionals.
            </p>
            <p className="px-3">
              IF COMPANY BECOMES AWARE OF OR SUSPECTS ANY MEDICAL OR MENTAL
              CONDITIONS OR ILLNESS, COMPANY MAY, AT ITS SOLE DISCRETION,
              TERMINATE ALL OR ANY PORTION OF THE SERVICES.
            </p>
            <p className="px-3">
              YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH COACHES WHEN
              USING THE SERVICES.
            </p>
            <p className="px-3">17. MISCELLANEOUS.</p>
            <p className="px-3">
              17.1. You shall comply with all laws, rules and regulations now or
              hereafter promulgated by any government authority or agency that
              are applicable to your use of the Services, or the transactions
              contemplated in this Agreement. You may not assign your rights or
              obligations hereunder, and any attempt by you to sublicense,
              assign or transfer any of the rights, duties or obligations
              hereunder or to exceed the scope of this Agreement is void. In the
              event that Company is sold to a third party, such a sale will not
              be deemed a transfer of personal information so long as that third
              party agrees to assume Company's obligations as to these Terms of
              Services and any associated Privacy Policy. This Agreement, the
              Services, and the rights and obligations of the parties with
              respect to the Services will be subject to and construed in
              accordance with the laws of the State of New York, excluding
              conflict of law principles. By accessing or using any Services you
              agree that the statutes and laws of the State of New York, without
              regard to conflicts of law principles thereof, will apply to all
              matters arising from or relating to the use thereof. You also
              agree and hereby submit to the exclusive legal jurisdiction and
              venues of the Courts of New York, New York with respect to such
              matters. This is the entire agreement between you and Company with
              regard to the matters described herein and govern your use of the
              Services, superseding any prior agreements between you and Company
              with respect thereto. The failure of Company to exercise or
              enforce any right or provision of this Agreement shall not
              constitute a waiver of such right or provision. If any provision
              of this Agreement is found by a court of competent jurisdiction to
              be invalid, the parties nevertheless agree that the court should
              endeavor to give effect to the parties' intentions as reflected in
              the provision, and the other provisions hereof shall remain in
              full force and effect. You agree that regardless of any statute or
              law to the contrary, any claim or cause of action arising out of
              this Agreement or related to use of the Services must be filed
              within three (3) months after such claim or cause of action arose
              or be forever barred.
            </p>
            <p className="px-3">Last Updated: June, 2023</p>
          </div>
        </Card>
        <Card className="mt-md-3 px-md-3 py-md-3 mt-3 mt-md-0">
          <div>
            <div className=" terms_scroll pt-3 px-3 px-md-0">
              <h3 className="d-flex justify-content-center">Privacy Policy</h3>
              <p>
                This privacy policy sets out how
                <b>(“Company”, “we”, “us” or “our”)</b> collects, uses and
                protects any information that you give us when you use our
                websites, mobile apps, social media pages, or any other products
                or services offered by us
                <b>(“Services”)</b>.  We are committed to ensuring that your
                privacy is protected. If we ask you to provide certain
                information by which you can be identified when using this
                website, then you can be assured that it will be used only in
                accordance with this privacy statement, as updated from time to
                time.
              </p>

              <p>
                This Privacy Policy covers how the Company collects, receives,
                uses, retains, and discloses Personally Identifiable Information
                (“PII”) and Protected Health Information <b>(“PHI”)</b>via the
                Services. PII includes information about you that is personally
                identifying such as your name, email address, and phone number
                and which is not otherwise publicly available. PHI includes
                information relating to your health, for example medical
                history, health conditions, test and laboratory results,
                physician referrals, insurance information and other data that a
                healthcare professional may collect to identify an individual
                and determine appropriate care. PII and PHI may include other
                types of information depending on the legal definition that
                applies in your physical location. Only the definitions of PII
                and PHI that apply in your location will apply to you under this
                Privacy Policy. PII and PHI are referred to collectively in this
                Privacy Policy as “Personal Data.”
                <b>
                  By using our website and/or Services and by providing Personal
                  Data to us, you accept and hereby expressly consent to our
                  collection, use, retention, and disclosure of your Personal
                  Data in accordance with the terms of this Privacy Policy. If
                  you choose not to provide the requested information you will
                  not be able to access the Services. Individuals must be
                  registered on the Website and have an active account in order
                  to use the Services{" "}
                </b>
                . We will collect Personal Data when you register for the
                Services through the Website.
              </p>
              <p>
                As used herein, <b>“information”</b> may include Personal Data,
                if and as applicable.
              </p>
              <p>
                <b>Links to Other Websites, Apps and Services</b>
              </p>

              <p>
                Our websites and other Services may contain links to other
                third-party websites, apps and services of interest. However,
                once you have used these links to leave our site, you should
                note that we have no control over such third-party services.
                Therefore, we cannot be responsible for the protection and
                privacy of any information that you provide while visiting such
                sites and services, and they are not governed by this privacy
                statement. You should exercise caution and should review the
                privacy statement(s) applicable to the third-party sites and
                services in question.
              </p>
              <p>
                <b> Collection of Your Information </b>
              </p>
              <p>
                We collect or receive information about your interactions with
                us. Depending on how you use our Services, your information may
                include, without limitation:
              </p>
              <ul>
                <li>
                  registration-related information (such as name, addresses,
                  email addresses, telephone numbers, occupation and information
                  imported from social log in permissions granted to us);
                </li>
                <li>
                  information about the Services that you use, how often you use
                  them, and your responses to the offerings and advertisements
                  presented or made available by us;
                </li>
                <li>
                  information about the searches you perform on our websites or
                  in our other Services and how you use the results of those
                  searches;
                </li>
                <li>
                  transaction-related information (such as credit card or other
                  preferred means of payment, billing information, credit card
                  number, expiration date, billing address and/or a history of
                  purchases through our Services);
                </li>
                <li>
                  customer service information about you as a user of our
                  Services;
                </li>
                <li>location data;</li>
                <li>
                  information about any devices, connections and methods used to
                  access and interact with us;
                </li>
                <li>
                  other information specifically related to your use of
                  Services, including information that you publicly post using
                  tools made available by us;
                </li>
                <li>
                  information based on your usage of our Services{" "}
                  <b>(“Usage Data”)</b>, which may be collected automatically by
                  us or the services (or third-party services within our
                  Services), which can include the IP addresses or domain names
                  of the computers utilized by the users who use the Services,
                  the URI addresses (Uniform Resource Identifier), the time of
                  the request, the method utilized to submit the request to the
                  server, the size of the file received in response, the
                  numerical code indicating the status of the server's answer
                  (such as successful outcome and error), the country of origin,
                  the features of the browser and the operating system utilized
                  by the user, the various time details per visit (such as the
                  time spent on each page within the Services) and the details
                  about the path followed within the Services with special
                  reference to the sequence of pages visited, other parameters
                  about the device operating system and/or the user's IT
                  environment, and data, conversion rates, marketing and
                  conversion data and statistics, reports, analytics data,
                  reviews and surveys. Usage Data may sometimes be essentially
                  anonymous when collected, but could be used indirectly to
                  identify a person;
                </li>
                <li>
                  we collect personal information from all communications with
                  Service end users including, without limitation, text
                  messages, faxes, telephone calls, and regular "snail mail," as
                  well as from third-party outside sources including database
                  vendors.
                </li>
              </ul>
              <p>
                In some countries, including in the European Economic Area, some
                of this information may be considered “personal data” under
                applicable data protection laws.
              </p>
              <p>
                Your information may be supplemented with additional information
                from other companies such as publicly available information,
                information about households and other information that we may
                append or match to your information.
              </p>
              <p>
                We may also receive or collect certain technical information
                when you use our Services. This may include your browser or
                operating system, your manner of connecting to the Internet and
                the name of your Internet service provider or wireless carrier;
                your Internet protocol (IP) address; information about referring
                websites or services (websites or services you used immediately
                prior to using our websites or other Services; exiting websites
                or services (immediately after using our website or other
                Services); and data relating to malfunctions or problems
                occurring when you use our Services. Additionally, we may
                collect information about other software on your device for the
                limited purpose of protecting your security or improving your
                online experience.
              </p>
              <p>How Your Information may be used</p>
              <p>
                Your information may be used for purposes that include, without
                limitation:
              </p>
              <ul>
                <li>
                  to operate and improve the Services available through us;
                </li>
                <li>
                  to personalize the content and advertisements provided to you
                  (including to present offers to you on behalf of business
                  partners and advertisers);
                </li>
                <li>
                  to fulfill your requests for tools, software, functionality,
                  features and other products, and services;
                </li>
                <li>to communicate with you and respond to your inquiries;</li>
                <li>to conduct research about your use of our products; and</li>
                <li>
                  to help offer you other products, features or services that
                  may be of interest;
                </li>
                <li>to provide customer support and technical assistance;</li>
                <li>for administration of your account;</li>
                <li>to create user profiles;</li>
                <li>to create de-identified analytical information.</li>
              </ul>
              <p>
                We reserve the right to make full use of Usage Data. For
                example, we may use Usage Data to provide better service to
                Service visitors or end users, customize the Services based on
                your preferences, compile and analyze statistics and trends
                about the use of our Services and otherwise administer and
                improve our Services.
              </p>
              <p>
                <b>Cookies</b>
              </p>
              <p>
                We may use cookies, flash cookies, local shared objects, web
                beacons, pixels, single pixel GIFs, clear GIFs, and/or other
                technologies along with your information to enhance and
                personalize your experience and gather information that helps us
                optimize our Services. You can choose to accept or decline some
                cookies.
              </p>
              <p>
                Most web browsers automatically accept cookies, but you can
                usually modify your browser setting to decline cookies if you
                prefer. However, it is important to remember that many of our
                Services may not function properly if your cookies are disabled.
              </p>
              <p>
                Our Third Party Vendors or Third Party Services (as defined
                below) may use their own cookies and/or other third-party
                cookies together (subject to their own privacy policies) to (a)
                inform, optimize, and serve ads across the web based on your
                past visits to our Services and others and (b) report to us how
                your ad impressions, other uses of ad services, and interactions
                with these ad impressions and ad services are related to visits
                to our site.  If and to the extent from time to time we use
                Double Click or another Google brand for ad serving,
                analytics, remarketing, retargeting, etc., you can set certain
                preferences and opt-outs using Google Ads Settings, and Google
                Analytics’ currently available opt-outs, found
                <a href="https://www.google.com/ads/preferences/">here</a> and
                <a href="https://adssettings.google.com/">here</a>.
              </p>
              <p>
                <b>Sharing of Your Information</b>
              </p>
              <p>
                We do not rent or sell your personally identifiable information
                (such as name, address, telephone number and credit card
                information) to unaffiliated third parties for their marketing
                purposes. We may share your information with third parties to
                provide products and services you have requested, when we have
                your consent, or as described in this Privacy Policy.
              </p>
              <p>
                We may share aggregated, non-personally identifiable
                information, publicly and with our partners like publishers,
                advertisers or connected sites. For example, we may share
                information publicly to show trends about the general use of our
                websites and/or other products or services.
              </p>
              <p>
                We may share your information with health care providers: (i) to
                schedule and fulfill appointments and provide health care
                services as part of the Services, (ii) to whom you send messages
                through our Services, and (iii) for other treatment, payment or
                health care operations purposes, including pharmacy, laboratory,
                radiology or other ancillary services, upon your request. These
                third parties are contractually obligated to maintain the
                confidentiality of your Personal Data consistent with the terms
                of this Policy and to comply with the applicable data protection
                laws.
              </p>
              <p>
                The contents of your online communications, as well as other
                information about you as a user of our Services, may be accessed
                and disclosed under the following circumstances: in response to
                lawful governmental requests or legal process (for example, a
                court order, search warrant or subpoena), in other circumstances
                in which we have a good-faith belief that a crime has been or is
                being committed by a user of our Services, that an emergency
                exists that poses a threat to the safety of you or another
                person, when necessary to protect either our rights or our
                property or for us to render the service you have requested.
              </p>
              <p>
                Many of our Services let you share information with others.
                Remember that when you share information publicly, others
                besides us have access to it, and it may be indexable by search
                engines, or copied, forwarded, saved or archived by others.
              </p>
              <p>
                Our offerings may include features or functionalities provided
                by third parties <b>(“Third Party Vendors”)</b> or may integrate
                or interact with third-party products and services (including,
                without limitation, via APIs, plug ins, links, frames,
                embedding, or other interactions)
                <b>(“Third Party Services”)</b>. For example, without
                limitation, we may use a third party for ad serving,
                retargeting, remarketing and/or for analytics, obtaining data
                and information about your fitness or sleep patterns, in which
                case such third party may have access to your data, subject to
                its policies. In the process of providing such functionalities
                within our services, your browser or other tools or technology
                may send certain information to the Third Party Provider and/or
                Third Party Services. The use of these third-party-provided
                features or functionalities is subject to their own privacy
                policies.
              </p>
              <p>
                Business partners or other third parties may receive data about
                groups of our users, but, except as otherwise permitted herein,
                do not receive information that personally identifies you. We
                may use agents and contractors in order to help operate our
                Services. Their use of information is limited to these purposes,
                except as otherwise permitted herein.
              </p>
              <p>
                In the event that ownership of us was to change as a result of a
                merger, acquisition, or transfer to another company, your
                information may be transferred. If such a transfer results in a
                material change in the use of your information, you will be
                provided notice (which may be via updates to this page) about
                your ability to decline to permit such a transfer.
              </p>
              <p>
                Any personal information which we may collect via the Services
                may be stored and processed in our servers located in the United
                States or in any other country in which we, or our affiliates,
                subsidiaries or agents, maintain facilities. You consent to any
                such transfer of personal information outside your country of
                residence to any such location.
              </p>
              <p>
                General Disclosure Policy. We reserve the right to disclose your
                personal information as described below. We reserve the right to
                disclose Usage Data without restriction.
              </p>
              <p>
                Affiliated Entities. We reserve the right to provide your
                personal information and Usage Data to any affiliated entities
                we may have, including our subsidiaries. Affiliated entities are
                entities that we control or that control us.
              </p>
              <p>
                Service Providers. We reserve the right to provide access to
                your personal information and Usage Data to our trusted service
                providers that assist us with the operation and maintenance of
                our sites and services. For example, we may contract with third
                parties to process payments, host our servers, provide security
                and provide production, fulfillment, optimization, analytics and
                reporting services. We will use commercially reasonable efforts
                to ensure that our service providers will be given access to
                your information only as is reasonably necessary to provide the
                services for which they are contracted.
              </p>
              <p>
                Successors. If we sell or otherwise transfer part or all of our
                business or assets to another organization, such as in the
                course of an acquisition, merger, bankruptcy or liquidation, we
                may transfer your personal information and Usage Data.
              </p>
              <p>
                Legal Process, Enforcement and Security Notice. We reserve the
                right to disclose your personal information and Usage Data if we
                have a good-faith belief that access, use, preservation or
                disclosure of such information is reasonably necessary (i) to
                satisfy any applicable law, regulation, legal process or
                enforceable governmental request (such as for example, to comply
                with a subpoena or court order), (ii) to detect, prevent, and
                address fraud or other illegal activity and (iii) to
                investigate, respond to, or enforce violations of our rights or
                the security of our sites and services.
              </p>
              <p>
                We may also use your data in other ways described herein, and as
                otherwise permitted or required by applicable laws.
              </p>
              <p>
                <b>Display of Advertising</b>
              </p>
              <p>
                Your information may be used for the presentation of
                advertisements. We may use ad networks to customize and display
                advertising on our Services. We may share certain information
                about you as a user (such as age, zip code or other information
                we have collected or received) with certain ad network and
                service providers to help them deliver more relevant content and
                advertisements through their networks. We may from time to time
                work with other companies for certain services such as analytics
                or advertising, and you may be subject to their privacy policies
                as well, though you may opt out through them directly or contact
                us with questions.
              </p>
              <p>
                <b> Your Choices about Your Information</b>
              </p>
              <p>
                You may choose to restrict the collection or use of your
                personal information in the following ways:
              </p>
              <ul>
                <li>
                  Whenever you are asked to fill in a form on our website or our
                  other Services, consider what information to include and
                  exclude; in addition, sometimes there may be a box that you
                  can click to indicate that you do not want the information to
                  be used by anybody for direct marketing purposes
                </li>
                <li>
                  We may provide you with access to your registration
                  information and the ability to edit this information in your
                  account settings dashboard and profile pages. Please be aware
                  that even after you delete or update information within our
                  Services, we may not immediately delete residual copies from
                  our active servers and may not remove information from our
                  backup systems. Similarly, if and to the extent any
                  information is indexable by search engines (including, without
                  limitation, public profile information), it may not be updated
                  by such search engines when we update it, and old versions may
                  be archived by them or by third parties outside our control.
                </li>
                <li>
                  Some of our Services may provide you with additional
                  information and choices about your privacy, which you should
                  review.
                </li>
                <li>
                  If you have previously agreed to our using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by writing to us using the contact
                  information below.
                </li>
              </ul>
              <p>
                <b>Our Commitment to Security</b>
              </p>
              <p>
                We have established safeguards to help prevent unauthorized
                access to or misuse of your information, but cannot guarantee
                that your information will never be disclosed in a manner
                inconsistent with this Privacy Policy (for example, as a result
                of unauthorized acts by third parties that violate applicable
                law or our and our affiliated providers policies). To protect
                your privacy and security, we may use passwords or other
                technologies to register or authenticate you and enable you to
                take advantage of our Services, and before granting access or
                making corrections to your information.
              </p>
              <p>
                <b>
                  YOUR CALIFORNIA PRIVACY RIGHTS – California Consumer Privacy
                  Act
                </b>
              </p>
              <p>
                For California residents: We may share your personal information
                with third parties and affiliated third parties (such as local,
                state and regional affiliates and affiliate alliances), but they
                do not share your name for their direct marketing purposes. As
                these third parties and this category of affiliated third
                parties are considered an unaffiliated party under California
                law, you may opt-out of our disclosure of personal information
                to third parties for their direct marketing purposes. To opt
                out, please contact us as described in the “How to Contact Us”
                Section below.
              </p>
              <p>
                We will continue to provide your information to local, state and
                regional affiliates and affiliate alliances identified in your
                membership application or account for the purpose of processing
                your membership in such affiliate and affiliate alliance
                programs.
              </p>
              <p>
                The California Consumer Privacy Act (CCPA), effective January 1,
                2020, gives California consumers enhanced rights with respect to
                their personal information that is collected by businesses.
                First, California consumers may opt out of having their personal
                information sold to other persons or parties. Second, they have
                a right to know:
              </p>
              <ol>
                <li>
                  What specific pieces of information a business has about the
                  consumer;
                </li>
                <li>
                  Categories of personal information it has collected about the
                  consumer;
                </li>
                <li>
                  Categories of sources from which the personal information is
                  collected;
                </li>
                <li>
                  Categories of personal information that the business sold or
                  disclosed for a business purpose about the consumer;
                </li>
                <li>
                  Categories of third parties to whom the personal information
                  was sold or disclosed for a business purpose; and
                </li>
                <li>
                  The business or commercial purpose for collecting or selling
                  personal information.
                </li>
              </ol>
              <p>
                In addition, California consumers can request that the personal
                information a business has collected about them be deleted from
                the business’s systems and records.
              </p>
              <p>
                Company may be considered a covered business under the CCPA as
                it collects and processes the personal information of California
                consumers. This Privacy Policy provides the required notices to
                California consumers. The CCPA also prohibits covered businesses
                from providing discriminatory treatment to California consumers
                if they exercise their rights under the Act.
              </p>
              <p>
                We do not rent or sell your personally identifiable information
                (such as name, address, telephone number and credit card
                information) to unaffiliated third parties for their marketing
                purposes. We may share your information with third parties to
                provide products and services you have requested, when we have
                your consent, or as described in this Privacy Policy.
              </p>
              <p>
                To make a “request to know” or request to delete your personal
                information, send us an e-mail at contact@nydrawell.com (Please
                put either <b>“Request to Know”</b> or{" "}
                <b>“Request to Delete”</b> in the subject heading of your
                email.) We will use commercially reasonable efforts to honor
                these requests whether or not you would qualify as a California
                consumer under the CCPA.
              </p>
              <p>
                If and to the extent we are considered a covered business under
                the CCPA: We will confirm receipt of your request within 10 days
                along with a description of what steps we will take to verify
                and respond. We must provide the requested information or delete
                your personal information within 45 days of receipt of your
                request but can use an additional 45 days, but we must let you
                know the additional time is needed.
              </p>
              <p>
                When contacting us, we may ask you to provide certain, limited
                personal information, such as your name, email address and/or
                account login ID and/or password, to verify your request and to
                match with our records and systems. This is also to protect
                against fraud. We will not retain this personal information or
                use it for any other purpose. Also please be advised that we
                need to search our records and systems only for the preceding 12
                months.
              </p>
              <p>
                Residents of other states may also have similar rights to
                request information about or delete their personal information.
                To inquire about exercising these rights, please contact us at
                contact@nydrawell.com.
              </p>
              <p>
                Pursuant to California’s <b>“Shine The Light law”</b>{" "}
                (California Civil Code § 1798.983), California residents are
                entitled, once a year and free of charge, to request the
                disclosure of certain categories of personal information to
                third parties for their own direct marketing purposes in the
                preceding calendar year, if any. Under the law, a business
                should either provide California customers certain information
                upon request or permit California customers to opt out of this
                type of sharing. You may request this information by contacting
                us at contact@nydrawell.com and indicate in the email subject
                line,
                <b>“California Shine The Light Request.”</b> Please include your
                mailing address, state of residence and email address with your
                request.
              </p>
              <p>
                How to Contact Us If you have any questions or concerns about
                this Privacy Policy or its implementation, you may contact us at
                contact@nydrawell.com .
              </p>
              <p>
                If you believe your information is wrong, we strive to give you
                ways to update it quickly or to delete it unless we have to keep
                that information for legitimate business or legal purposes. When
                updating your personal information, we may ask you to verify
                your identity before we can act on your request. We may reject
                requests that are unreasonably repetitive, require
                disproportionate technical effort (for example, developing a new
                system or fundamentally changing an existing practice), risk the
                privacy of others, or would be extremely impractical (for
                instance, requests for information residing on backup tapes).
              </p>
              <p>
                <b> Changes to this Privacy Policy </b>
              </p>
              <p>
                We may update this Privacy Policy from time to time, and so you
                should review this Policy periodically. Your continued use of
                any Services constitutes your acceptance of any such changes.
              </p>
              <p>Last Updated: June, 2023</p>
            </div>
          </div>

          <div className="d-flex d-none d-md-block justify-content-between align-items-center mt-md-3">
            <div>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  className="border-purple"
                  label="I Accept T&C and Privacy Policy"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                />
              </Form.Group>
            </div>
            <div>
              <button
                disabled={!acceptedTerms}
                className="btn btn-purple px-5 mt-3"
                onClick={submitHandler}
              >
                Accept
              </button>
            </div>
          </div>
          <div className="d-flex d-block d-md-none flex-column my-3 px-3">
            <div>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  className="border-purple"
                  label="I Accept T&C and Privacy Policy"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-center">
              <button
                disabled={!acceptedTerms}
                className="btn btn-purple px-5 mt-3"
                onClick={submitHandler}
              >
                Accept
              </button>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default TermAndCondition;
