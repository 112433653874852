import React from "react";
import SnapShot from "../../assets/images/snapShoot4.png";
import Streching from "../../assets/images/stretching.png";
import { Col, Row } from "react-bootstrap";
const GentleStretchingComponent = () => {
  return (
    <>
      <div className="mt-md-5 text-center d-none d-md-block">
        <Row>
          <Col>
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Yoga+based+Simple+Stretches.mp4"
              poster={SnapShot}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="col-md-6">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Top+Right.mp4"
              poster={Streching}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="mt-md-5 col-md-6">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Bottom+Right.mp4"
              poster={Streching}
              width="475"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
      <div className="mt-5 d-block d-md-none">
        <Row>
          <Col className="col-12">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Yoga+based+Simple+Stretches.mp4"
              poster={SnapShot}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className="col-12 my-3">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Top+Right.mp4"
              poster={Streching}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
          <Col className=" col-12">
            <video
              controls
              src="https://nydrawell.s3.amazonaws.com/Bottom+Right.mp4"
              poster={Streching}
              width="100%"
              controlsList="nodownload"
            ></video>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GentleStretchingComponent;
