import SpinnerImg from "../assets/images/nydrahealth_spinner.gif";

const Spinner = () => {
  return (
    <div
      className="position-fixed semi-transparent screen-transparent
       vh-100 vw-100 "
      style={{ zIndex: "10000" }}
    >
      <div className="postion-relative">
        <img
          className="position-absolute top-50 start-50 translate-middle"
          src={SpinnerImg}
          height={350}
          alt="Nydrahealth Spinner"
        />
      </div>
    </div>
  );
};

export default Spinner;
