import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import CheckMark from "../../assets/images/checkmark.png";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const ViewPatientForm = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { token, isAuthenticated } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let { patientId } = useParams();
  const [assementData, setAssementData] = useState<any>([]);
  const [ismessage, setIsMessage] = useState<string>();
  const [isStatus, setIsStatue] = useState<boolean>();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);

      axiosPrivate({
        method: "POST",
        url: "/viewSleepProfile",
        data: {
          id: patientId,
        },
      })
        // axios
        //   .post(
        //     "/viewSleepProfile",
        //     {
        //       id: patientId,
        //     },
        //     {
        //       headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //       },
        //     }
        //   )
        .then((response: any) => {
          const { data, status } = response.data;
          setIsStatue(response.data.status);
          if (status) {
            setAssementData(data);
          } else {
            setIsMessage(response.data.message);
          }
        })
        .catch((err: any) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated]);
  return (
    <>
      {isLoading && <Spinner />}
      <Container className="px-md-5 pb-4">
        <div style={{ marginTop: "85px" }} className="desktop_header_height">
          <div className="d-flex align-items-center justify-content-between my-md-3">
            <div className="mt-md-2">
              <h5>View Patient Form</h5>
            </div>
            <div
              className="btn btn-purple border-0 me-2"
              onClick={() => {
                Navigate(-1);
              }}
            >
              <i className="bi bi-arrow-left"></i>
            </div>
          </div>
          {isStatus ? (
            <div className="border px-md-3 py-md-3">
              {assementData.map((data: any) => {
                return (
                  <div>
                    <div className="px-1">
                      <div className="text-center fw-bold">
                        <h4 className="text-uppercase fw-bolder text-purple">
                          {data.title}
                        </h4>
                      </div>
                      <div className="mt-md-4">
                        {data.sleepResponse.map(
                          (response: any, index: number) => {
                            return (
                              <div key={index} className="my-md-4 my-2 px-1 ">
                                <h6 className="mb-1 fw-bold">
                                  {response.name}
                                </h6>
                                <div className=" fw-bold my-md-3">
                                  <div className="ms-md-4">
                                    <h6 className="text-success">
                                      {response.option}
                                      <img
                                        height={16}
                                        width={16}
                                        src={CheckMark}
                                        alt="selectedOption"
                                        className="ms-md-2"
                                      />
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <p>{ismessage}</p>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default ViewPatientForm;
