import React from "react";
import LandingPageHeader from "../components/landing/LandingPageHeader";
import BannerComponent from "../components/landing/BannerComponent";
import PillerComponent from "../components/landing/PillerComponent";
import PowerOfGoodSleepComponent from "../components/landing/PowerOfGoodSleepComponent";
import OurSolutionComponent from "../components/landing/OurSolutionComponent";
import HowItWorkComponent from "../components/landing/HowItWorkComponent";
import RealPeopleComponent from "../components/landing/RealPeopleComponent";
// import IntialProgramComponent from "../components/landing/InitalProgramComponent";
// import SleepExpertCoponent from "../components/landing/SleepExpertCoponent";
import LandingPageFooter from "../components/landing/LandingPageFooter";
import FrequentlyAskedQuestions from "../components/landing/FrequentlyAskedQuestions";
// import WhenYouSignUpNow from "../components/landing/WhenYouSignUpNow";
import InitialProgram from "../components/landing/InitialProgram";

const LandingPage = () => {
  return (
    <>
      <LandingPageHeader />
      <BannerComponent />
      <PillerComponent />
      <PowerOfGoodSleepComponent />
      <OurSolutionComponent />
      <HowItWorkComponent />
      <RealPeopleComponent />
      <InitialProgram />
      {/* <IntialProgramComponent /> */}
      {/* <WhenYouSignUpNow /> */}
      {/* <SleepExpertCoponent /> */}
      <FrequentlyAskedQuestions />
      <LandingPageFooter />
    </>
  );
};

export default LandingPage;
