import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import styles from "./Faqs.module.scss";

const FrequentlyAskedQuestions = () => {
  return (
    <>
      <Container className="mt-5">
        <h2 className="fw-bold text-center faq">Frequently Asked Questions</h2>
        <Row className="mt-5">
          <Col md={6} sm={12}>
            <Accordion
              defaultActiveKey="0"
              className={`${styles.faqscustomAccordian}`}
            >
              <Accordion.Item eventKey="0" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  What is Nydra Well program?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  Our comprehensive program combines state-of-the-art science,
                  wearable technology and real humans to create a program to
                  improve your sleep duration and quality through real-time
                  continuous monitoring of your sleep and one-on-one sleep
                  coaching
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  What kind of results can I expect?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  The goal of the Nydra Well program is to improve your sleep
                  quality by working on sleep timing, duration, and reducing
                  factors that might disrupt your sleep. When your sleep
                  improves, you will feel more rested and have more energy to
                  function optimally during your wake period. Good sleep over a
                  long duration improves your overall health, including
                  improving cognition/thinking capacity, mood disorders,
                  cardiovascular health, and immune function.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  Who is Nydra Well right for?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  Many people can benefit from the Nydra Well program, including
                  people who feel that their sleep is not great and would like
                  to improve as well as those who want to ‘biohack’ their sleep
                  to optimize their daytime performance.
                  <br />
                   Nydra Well is not right for those with clinical sleep
                  disorders such as clinically diagnosed insomnia and other
                  sleep disorders including obstructive sleep apnea, periodic
                  limb movement disorder, restless legs syndrome, narcolepsy,
                  etc.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={6} sm={12}>
            <Accordion className={`${styles.faqscustomAccordian}`}>
              <Accordion.Item eventKey="3" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  How long and what is the cost of the program?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  The initial program is 12 weeks at a cost of $300. This
                  includes 3 video visits at monthly intervals and text
                  messaging between video visits.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  What is your refund policy?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  We are dedicated to you being satisfied with the results of
                  the Nydra Well program. Our program is designed to bring about
                  behavioral changes without relying on medication, and we
                  strongly encourage you to remain committed to the program for
                  its entire duration for the desired results.
                  <br />
                  However, if for any reason you are dissatisfied with our
                  program within the first 30 days of purchase, we will promptly
                  issue a full refund without requiring any explanation. To
                  initiate the cancellation process, please send an email to
                  info@nydrawell.com.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  What is different about Nydra from other programs?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  Nydra Well is the only web- and app-based program that
                  utilizes real human interaction in the form of video visits
                  with a live sleep coach once per month. In addition, text
                  messages between video visits are answered by your sleep coach
                  and not an automated program. Other apps use chatbots or other
                  means of communicating with users which often leads to a lack
                  of accountability on the part of the user and discontinuing
                  the program before real benefits can be seen.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" bsPrefix={`${styles.accordianItem}`}>
                <Accordion.Header bsPrefix={`${styles.accordianHeader}`}>
                  What devices is Nydra Well compatible with?
                </Accordion.Header>
                <Accordion.Body bsPrefix={`${styles.accordianBody}`}>
                  Nydra connects seamlessly with any Fitbit wearable device,
                  Whoop and Apple watch.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FrequentlyAskedQuestions;
