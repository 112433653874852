import React from "react";
import HealthPageComponent from "../components/landing/HealthPageComponent";
import SleepExpertCoponent from "../components/landing/SleepExpertCoponent";
import FrequentlyAskedQuestions from "../components/landing/FrequentlyAskedQuestions";
import InitialProgram from "../components/landing/InitialProgram";
import NydraSleepPlatform from "../components/landing/NydraSleepPlatform";

const HealthPage = () => {
  return (
    <>
      <HealthPageComponent />
      <NydraSleepPlatform />
      <SleepExpertCoponent />
      <FrequentlyAskedQuestions />
      <InitialProgram />
    </>
  );
};

export default HealthPage;
