import React, { useState } from "react";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import moment from "moment";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../utils/Spinner";
import { Formik, Form as Formdata, ErrorMessage } from "formik";
import { Col, Form, Row } from "react-bootstrap";

const WindDownJournalComponent = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setShowLoading] = useState(false);
  const [goals, setGoals] = useState([
    {
      id: Math.floor(Math.random() * Date.now()).toString(6),
      schedule_time: "",
      goal: "",
    },
  ]);
  const [gratitudes, setGratitudes] = useState([
    { id: Math.floor(Math.random() * Date.now()).toString(6), grade: "" },
  ]);

  const initialValues = {
    overall_feeling: "",
    lessons_learned: "",
    joyful_moments: "",
    upsetting_events: "",
    datetime: "",
  };

  const validationSchema = Yup.object({
    overall_feeling: Yup.string(),
    lessons_learned: Yup.string(),
    joyful_moments: Yup.string(),
    upsetting_events: Yup.string(),
    datetime: Yup.string(),
  });

  const handleSubmit = (values: any) => {
    const goalsField = goals.filter(
      (field) => field.schedule_time !== "" || field.goal !== ""
    );
    const gratitudeField = gratitudes.filter((field) => field.grade !== "");

    const postData = {
      date: moment(new Date()).format("YYYY-MM-DD"),
      overallFeeling: values.overall_feeling,
      lessonsLearned: values.lessons_learned,
      joyfulMoments: values.joyful_moments,
      upsettingEvents: values.upsetting_events,
      goals: goalsField,
      gratitudes: gratitudeField,
    };

    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/winddownDay",
      data: postData,
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status) {
          toast.success(`${message}`);
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  };

  const handleAddField = () => {
    const newId = Math.floor(Math.random() * Date.now()).toString(6);
    setGoals([...goals, { id: newId, schedule_time: "", goal: "" }]);
  };
  const handleAddGratitudeField = () => {
    const newId = Math.floor(Math.random() * Date.now()).toString(6);
    setGratitudes([...gratitudes, { id: newId, grade: "" }]);
  };

  // Function to remove a field
  const handleRemoveField = (id: any) => {
    const updatedFields = goals.filter((field) => field.id !== id);
    setGoals(updatedFields);
  };

  const handleRemoveGratitudeField = (id: any) => {
    const updatedGratitudeFields = gratitudes.filter(
      (field) => field.id !== id
    );
    setGratitudes(updatedGratitudeFields);
  };

  // Function to handle changes in text input
  const handleTextChange = (id: any, goal: any) => {
    const updatedFields = goals.map((field) =>
      field.id === id ? { ...field, goal } : field
    );
    setGoals(updatedFields);
  };

  const handleTextGratitudeChange = (id: any, grade: any) => {
    const updatedGratitudeFields = gratitudes.map((field) =>
      field.id === id ? { ...field, grade } : field
    );
    setGratitudes(updatedGratitudeFields);
  };

  const handleDateChange = (id: any, schedule_time: any) => {
    const updatedFields = goals.map((field) =>
      field.id === id ? { ...field, schedule_time } : field
    );
    setGoals(updatedFields);
  };

  // const handleDateGratitudeChange = (id: any) => {
  //   const updatedGratitudeFields = fields.map((field) =>
  //     field.id === id ? { ...field } : field
  //   );
  //   setGratitudes(updatedGratitudeFields);
  // };
  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <div className="mt-md-4 mt-4">
                <h5>How was your day</h5>
                <Formdata>
                  {/* How was your day starting */}
                  <div>
                    <Form.Group className="my-md-4 my-2">
                      <Row>
                        <Col className="col-md-3 col-12">
                          <Form.Label className="fw-500">
                            Overall Feeling
                          </Form.Label>
                        </Col>
                        <Col className="col-md-4 col-12">
                          <Form.Control
                            type="text"
                            name="overall_feeling"
                            value={formik.values.overall_feeling}
                            onChange={formik.handleChange("overall_feeling")}
                          />
                        </Col>
                      </Row>

                      <ErrorMessage name="overall_feeling" component="div" />
                    </Form.Group>
                    <Form.Group className="my-md-4 my-2">
                      <Row>
                        <Col className="col-md-3 col-12">
                          <Form.Label className="fw-500">
                            Lessons Learned
                          </Form.Label>
                        </Col>
                        <Col className="col-md-4 col-12">
                          <Form.Control
                            type="text"
                            name="lessons_learned"
                            value={formik.values.lessons_learned}
                            onChange={formik.handleChange("lessons_learned")}
                          />
                        </Col>
                      </Row>

                      <ErrorMessage name="lessons_learned" component="div" />
                    </Form.Group>
                    <Form.Group className="my-md-4 my-2">
                      <Row>
                        <Col className="col-md-3 col-12">
                          <Form.Label className="fw-500">
                            Joyful Moments
                          </Form.Label>
                        </Col>
                        <Col className="col-md-4 col-12">
                          <Form.Control
                            type="text"
                            name="joyful_moments"
                            value={formik.values.joyful_moments}
                            onChange={formik.handleChange("joyful_moments")}
                          />
                        </Col>
                      </Row>

                      <ErrorMessage name="joyful_moments" component="div" />
                    </Form.Group>
                    <Form.Group className="my-md-4 my-2">
                      <Row>
                        <Col className="col-md-3 col-12">
                          <Form.Label className="fw-500">
                            Upsetting Events
                          </Form.Label>
                        </Col>
                        <Col className="col-md-4 col-12">
                          <Form.Control
                            type="text"
                            name="upsetting_events"
                            value={formik.values.upsetting_events}
                            onChange={formik.handleChange("upsetting_events")}
                          />
                        </Col>
                      </Row>

                      <ErrorMessage name="upsetting_events" component="div" />
                    </Form.Group>

                    {/* <Form.Group className="my-md-4 my-2">
                      <Row>
                        <Col className="col-md-3 col-12">
                          <Form.Label className="fw-500">Date</Form.Label>
                        </Col>
                        <Col className="col-md-4 col-12">
                          <Form.Control
                            type="date"
                            name="datetime"
                            value={formik.values.datetime}
                            onChange={formik.handleChange("datetime")}
                          />
                        </Col>
                      </Row>

                      <ErrorMessage name="datetime" component="div" />
                    </Form.Group> */}
                  </div>
                  {/* How was your day ending */}

                  {/* Goals for Tommorow start */}
                  <div className="my-md-3 my-3">
                    <div>
                      <div>
                        <h5>Goals for Tommorow </h5>
                      </div>
                      <div className="col-md-8 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-purple text-white  rounded-0 px-4 py-1 rounded-1"
                          onClick={handleAddField}
                        >
                          Add Field
                        </button>
                      </div>
                    </div>
                    {goals.map((field) => (
                      <div key={field.id} className="mb-3">
                        <Row className="d-flex justify-content-center align-items-center">
                          <Col className="col-12 col-md-0">
                            <Form.Group controlId={`date-${field.id}`}>
                              <Form.Label>Time</Form.Label>
                              <Form.Control
                                type="time"
                                // step="1"
                                value={field.schedule_time}
                                onChange={(e) =>
                                  handleDateChange(field.id, e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col className="col-10 col-md-0">
                            <Form.Group controlId={`text-${field.id}`}>
                              <Form.Label>Text</Form.Label>
                              <Form.Control
                                type="text"
                                value={field.goal}
                                onChange={(e) =>
                                  handleTextChange(field.id, e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col className="col-2 col-md-0 d-flex align-items-end">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveField(field.id)}
                              className="mt-4 pt-2"
                            >
                              <i className="bi bi-trash text-danger"></i>
                            </div>
                            {/* <button >
                              Remove 
                            </button> */}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                  {/* Goals for Tommorow End */}

                  {/* Gratitude starting*/}
                  <div>
                    <div>
                      <h5>Gratitudes </h5>
                    </div>
                    <div className="col-md-8 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-purple text-white  rounded-0 px-4 py-1 rounded-1"
                        onClick={handleAddGratitudeField}
                      >
                        Add Field
                      </button>
                    </div>
                    <div>
                      {gratitudes.map((field) => (
                        <div key={field.id} className="mb-3">
                          <Row className="d-flex justify-content-center align-items-center">
                            <Col className="col-10 col-md-0">
                              <Form.Group controlId={`text-${field.id}`}>
                                <Form.Label>Text</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={field.grade}
                                  onChange={(e) =>
                                    handleTextGratitudeChange(
                                      field.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col className=" col-2 col-md-0 d-flex align-items-end">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleRemoveGratitudeField(field.id)
                                }
                                className="mt-4 pt-2"
                              >
                                <i className="bi bi-trash text-danger"></i>
                              </div>
                              {/* <button >
                              Remove 
                            </button> */}
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Gratitude ending */}

                  <div className="d-none d-md-block mb-4">
                    <button
                      className="btn btn-purple text-white  rounded-0 px-4 py-1 rounded-1"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="d-block d-md-none mb-4">
                    <button
                      className="btn btn-purple text-white  rounded-0 px-4 py-1 rounded-1 w-100"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </Formdata>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default WindDownJournalComponent;
