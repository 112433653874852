import React, { useState, useRef } from "react";
import { DoctorType, DoctorModalProps } from "../../types/DoctorType";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { PasswordField, TextField, TextSelect } from "../../utils/Fromcontrol";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import { toast } from "react-toastify";

const CreateDoctorModal = ({
  closeCreateDoctorHandler,
  isOpenCreateDoctorModal,
  apiSuccessHandler,
}: DoctorModalProps) => {
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const initialValues = {
    fName: "",
    lName: "",
    email: "",
    password: "",
    confirmPassword: "",
    status: 0,
    mobile: "",
  };

  const validationSchema = Yup.object({
    fName: Yup.string().required("First Name is required"),
    lName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
    status: Yup.number().required("Status is required"),
  });

  const StatusDropDown = [
    { key: "", value: "" },
    { key: "InActive", value: "1" },
    { key: "Active", value: "0" },
  ];

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const submitHandler = (values: DoctorType) => {
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/createDoctor",
      data: {
        fName: values.fName,
        lName: values.lName,
        email: values.email,
        password: values.password,
        phone: values.mobile,
        doctor_id: null,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          closeCreateDoctorHandler();
          toast.update(toastId.current, {
            render: "Doctor Created successfully",
            type: "success",
            isLoading: false,
          });
          apiSuccessHandler();
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err) => {
        return;
      });
  };
  return (
    <Modal
      show={isOpenCreateDoctorModal}
      onHide={closeCreateDoctorHandler}
      fullscreen="md-down"
    >
      <Modal.Header className="py-2 bg-purple" closeButton>
        <Modal.Title className="text-white">Create Doctor</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        <Form>
          <Modal.Body className="px-md-5">
            <div className="mb-4 mt-3">
              <TextField
                name="fName"
                type="text"
                label="First Name"
                placeholder="First Name"
              />
            </div>
            <div className="my-4">
              <TextField
                name="lName"
                type="text"
                label="Last Name"
                placeholder="Last Name"
              />
            </div>
            <div className="my-4">
              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="Email"
              />
            </div>
            <div className="my-4">
              <TextField
                name="mobile"
                type="text"
                label="Mobile"
                placeholder="mobile"
              />
            </div>
            <div className="my-4">
              <PasswordField
                name="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                placeholder="Password"
                showPassword={showPassword}
                togglePasswordVisibility={showPasswordHandler}
              />
            </div>
            <div className="my-4">
              <PasswordField
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                placeholder="confirm Password"
                showPassword={showConfirmPassword}
                togglePasswordVisibility={showConfirmPasswordHandler}
              />
            </div>
            <TextSelect
              name="status"
              label="Status"
              options={StatusDropDown}
              placeholder="status"
            />
          </Modal.Body>
          <Modal.Footer className="py-2 border border-0 px-md-5 pb-3">
            <button
              type="submit"
              className="btn btn-purple rounded-1 px-3 py-1"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closeCreateDoctorHandler}
              className="btn btn-purple rounded-1 px-3 py-1"
            >
              Close
            </button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateDoctorModal;
