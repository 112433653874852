import React, { useState, useRef } from "react";
import { CreateNotificationModalProps } from "../../types/CreateNotificationType";
import { Modal } from "react-bootstrap";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import { toast } from "react-toastify";

const CreateNotificationCategoryModal: React.FC<
  CreateNotificationModalProps
> = ({
  isOpenNotificationCategoryModal,
  closeNotificationCategoryModalHandler,
  apiSuccessHandler,
  patientId,
}) => {
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const [notificationCategoryInput, setNotificationCategoryInput] =
    useState("");

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isSMS, setIsSMS] = useState();
  const notificationChangeHandler = (e: any) => {
    if (e.target.value.trim().length === 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    setNotificationCategoryInput(e.target.value.trim());
  };
  const blurHandler = (e: any) => {
    if (notificationCategoryInput.length === 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };
  const focusHandler = (e: any) => {
    if (notificationCategoryInput.length === 0) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/createNotification",
      data: {
        notificationName: notificationCategoryInput,
        patientId: patientId,
        smsNotificationName: isSMS,
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          closeNotificationCategoryModalHandler();
          apiSuccessHandler();
          toast.update(toastId.current, {
            render: "Notification Created successfully",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 1000);
        }
      })
      .catch((err: any) => {
        return;
      });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    setIsSMS(value);
  };
  return (
    <Modal
      show={isOpenNotificationCategoryModal}
      onHide={closeNotificationCategoryModalHandler}
      backdrop="static"
      centered
      fullscreen="md-down"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className="py-2 bg-purple" closeButton>
          <Modal.Title className="text-white">
            Create Notification Category
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="px-md-3">
          <div>
            <label className="mb-2">Notifcation Category:</label>
            <textarea
              rows={4}
              style={{ outline: "none" }}
              className="w-100 border border-purple rounded-2"
              onChange={notificationChangeHandler}
              onBlur={blurHandler}
              onFocus={focusHandler}
            />
          </div>
          <div className="mt-md-2">
            <label className="mb-2">SMS Notifcation:</label>
            <input
              type="text"
              className={`form-control ${
                isFormValid ? "border-primary" : "border-danger"
              } border-0 border-bottom rounded-0`}
              onChange={handleChange}
              name="isSMS"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2 border border-0">
          <button
            type="submit"
            className="btn btn-purple rounded-1 px-3 py-1"
            disabled={!isFormValid}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-purple rounded-1 px-3 py-1"
            onClick={closeNotificationCategoryModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateNotificationCategoryModal;
