import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import AuthContext from "../../context/authContext1";
import Spinner from "../../utils/Spinner";
import Header from "../../components/Header";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const ViewData = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const url = window.location.href;

    if (url.includes("https://nydrawell.com/")) {
      const wearableToken = url.includes("whoop")
        ? url.split("=")[1].split("&")[0]
        : url.split("=")[1].split("#")[0];
      const parameterToSend =
        localStorage.getItem("selectedDevice") === "whoop" ? "whoop" : "fitbit";
      axios
        .post(
          "/wearableToken",
          {
            userId: userId,
            code: wearableToken,
            device: parameterToSend,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        // axiosPrivate({
        //   method: "POST",
        //   url: "/wearableToken",
        //   data: {
        // userId: userId,
        // code: wearableToken,
        // device: parameterToSend,
        //   },
        // })
        .then((res) => {
          const { data } = res;

          if (data.status) {
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  }, []);
  return (
    <>
      <Header />
      <div>{isLoading && <Spinner />}</div>
    </>
  );
};

export default ViewData;
