import { useState } from "react";
import { Container, Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import CalmingMusicComponent from "../../components/patient/CalmingMusicComponent";
import GentleStretchingComponent from "../../components/patient/GentleStretchingComponent";
import BreathWorkSleep from "../../components/patient/BreathWorkSleep";
import GuidedMeditationComponent from "../../components/patient/GuidedMeditationComponent";
import WindDownJournalComponent from "../../components/patient/WindDownJournalComponent";
import PastFiveDaysData from "../../components/patient/PastFiveDaysData";
const ResourcePage = () => {
  return (
    <>
      <Container fluid className="px-5 px-xl-5 d-none d-md-block">
        <div style={{ marginTop: "85px" }} className=" desktop_header_height">
          <div className="mt-md-4 pt-md-4">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="calmingMusic"
            >
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="calmingMusic">Calming Music</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="bedtimeYoga">
                        Gentle Stretching / Bedtime Yoga
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="breathWorksleep">
                        Breath Work for sleep
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="guidedMeditation">
                        Guided Meditation
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="WindDownJournalComponent">
                        Wind Down Journal
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="PastFiveDaysData">
                        Past Five Days Data
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="calmingMusic">
                      <CalmingMusicComponent />
                    </Tab.Pane>
                    <Tab.Pane eventKey="bedtimeYoga">
                      <GentleStretchingComponent />
                    </Tab.Pane>
                    <Tab.Pane eventKey="breathWorksleep">
                      <BreathWorkSleep />
                    </Tab.Pane>
                    <Tab.Pane eventKey="guidedMeditation">
                      <GuidedMeditationComponent />
                    </Tab.Pane>
                    <Tab.Pane eventKey="WindDownJournalComponent">
                      <WindDownJournalComponent />
                    </Tab.Pane>
                    <Tab.Pane eventKey="PastFiveDaysData">
                      <PastFiveDaysData />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </Container>
      <Container fluid className=" d-block d-md-none">
        <div style={{ marginTop: "85px" }} className=" desktop_header_height">
          <div className="mt-3">
            <Tabs
              defaultActiveKey={"calmingMusic"}
              id="event-tabs"
              className="event-nav-tabs border-bottom"
            >
              <Tab eventKey="calmingMusic" title="Calming Music">
                <CalmingMusicComponent />
              </Tab>
              <Tab
                eventKey="bedtimeYoga"
                title=" Gentle Stretching / Bedtime Yoga"
              >
                <GentleStretchingComponent />
              </Tab>
              <Tab eventKey="breathWorksleep" title="Breath Work for sleep">
                <BreathWorkSleep />
              </Tab>
              <Tab eventKey="guidedMeditation" title="Guided Meditation">
                <GuidedMeditationComponent />
              </Tab>
              <Tab
                eventKey="WindDownJournalComponent"
                title="Wind Down Journal"
              >
                <WindDownJournalComponent />
              </Tab>
              <Tab eventKey="PastFiveDaysData" title="Past Five Days Data">
                <PastFiveDaysData />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ResourcePage;
