import React, { useContext, useState } from "react";
import AuthContext from "../../context/authContext1";
import DevicePlan from "./DevicePlan";
import TermAndCondition from "./TermAndCondition";
import SubscriptionPlans from "./SubscriptionPlans";
import PatientSubscriptionProgress from "./patientsubscriptionprogress/PatientSubscriptionProgress";
import Spinner from "../../utils/Spinner";

const PatientSubscriptionFlow: React.FC = () => {
  const { activeSubscriptionFlowStep } = useContext(AuthContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  let stepContent;

  switch (activeSubscriptionFlowStep) {
    case "device":
      stepContent = <DevicePlan setShowLoading={setShowLoading} />;
      break;
    case "termsandconditions":
      stepContent = <TermAndCondition setShowLoading={setShowLoading} />;
      break;
    case "payment":
      stepContent = <SubscriptionPlans setShowLoading={setShowLoading} />;
      break;

    default:
      break;
  }
  return (
    <>
      {showLoading && <Spinner />}
      <div style={{ marginTop: "85px" }}>
        <PatientSubscriptionProgress />
      </div>
      <div>{stepContent}</div>
    </>
  );
};

export default PatientSubscriptionFlow;
