import React, { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../utils/Spinner";
import AuthContext from "../../context/authContext1";
import { Container } from "react-bootstrap";

const SleepPatternWearable = () => {
  const axiosPrivate = useAxiosPrivate();
  const Navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const { patientId }: any = useParams();
  const [isStatus, setIsStatus] = useState<boolean>();
  const [isImage, setIsImage] = useState<any>("");
  const [isMessage, setIsMessage] = useState();
  const [isShowLoading, setShowLoading] = useState<boolean>(false);
  function SleepGraph() {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/sleepLevelsGraph",
      data: {
        user_id: patientId,
        formDate: "",
        toDate: "",
      },
    })
      .then((response: any) => {
        setIsStatus(response.data.status);
        if (response.data.status) {
          setIsImage(response.data.data);
        } else {
          setIsMessage(response.data.message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  }
  useEffect(() => {
    SleepGraph();
  }, [isAuthenticated]);
  return (
    <>
      {isShowLoading && <Spinner />}
      <Container fluid className="px-md-3 ">
        <div className="desktop_header_height mt-md-3">
          <div style={{ marginTop: "85px" }}>
            {isStatus ? (
              <div>
                <div className="d-flex justify-content-between align-items-center my-md-3">
                  <div>
                    <h5>Sleep Pattern Wearable Graph</h5>
                  </div>

                  <div>
                    <div
                      className="btn btn-purple me-2"
                      onClick={() => Navigate(-1)}
                    >
                      <i className="bi bi-arrow-left"></i>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <img src={isImage} alt="Patient_data" />
                </div>
              </div>
            ) : (
              <div>
                <h3>{isMessage}</h3>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default SleepPatternWearable;
