import React from "react";
import "./workPage.css";
import Frame1 from "../../assets/images/Frame_1.png";
import Group1 from "../../assets/images/Group_1.png";
import Group2 from "../../assets/images/Group_2.png";
import Group3 from "../../assets/images/Group_3.png";
import Group4 from "../../assets/images/Group_4.png";
import Group5 from "../../assets/images/Group_5.png";
import Group6 from "../../assets/images/Group_6.png";
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";
import { Container } from "react-bootstrap";
import Error from "../../assets/images/error.svg";

const WorkPageComponent = ({ Image, text, heading, content }: any) => {
  const WorkCardData = [
    {
      id: 1,
      Image: Group1,
      heading: "Absenteeism Impact",
      content:
        "Employees sleeping less than 6 hours are 27% more likely to miss work compared to those with 7-8 hours of sleep.",
    },
    {
      id: 2,
      Image: Group2,
      heading: "Error Rates Surge",
      content:
        "Sleep-deprived workers demonstrate a 66% increase in job-related mistakes, impacting overall work quality.",
    },
    {
      id: 3,
      Image: Group3,
      heading: "Accident Risks",
      content:
        "Inadequate sleep increases the likelihood of workplace accidents by 2.5 times, posing risks to employees and",
    },
    {
      id: 4,
      Image: Group4,
      heading: "Productivity Loss",
      content:
        "Sleep-deprived workers cost U.S. companies a staggering $411 billion yearly in lost productivity, according to Rand Corporation.",
    },
    {
      id: 5,
      Image: Group6,
      heading: "Presenteeism Impact ",
      content:
        "Employees are physically present at work but are not fully productive due to health issues, including lack of sleep, costing employers $150B annually according a study from Mercer",
    },
    {
      id: 6,
      Image: Group5,
      heading: "Healthcare Utilization",
      content:
        "Poor sleep & sleep disorders lead to health issues (obesity, diabetes, heart problems, mental disorders), increasing healthcare utilization & insurance costs for employers.",
    },
  ];

  const WorkCard = ({ Image, text, heading, content }: any) => {
    return (
      <div className={`px-md-3 pb-3`}>
        <div className="WorkPageCard px-md-2">
          <div className="text-center p-md-4">
            <img src={Image} alt={text} width="250px" height="250px" />
          </div>
          <div className="pt-md-3 pt-2">
            <h5 className="work-title">{heading}</h5>
            <p className="work-text"> {content}</p>
          </div>
        </div>
      </div>
    );
  };

  const AccordionComponent = () => {
    return (
      <div className="row ">
        <div className="col-md-6 col-12 mt-4">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Sharper Cognitive Abilities?</Accordion.Header>
              <Accordion.Body>
                When well-rested, leaders have improved focus, attention, and
                memory. They can think more clearly, analyze situations more
                effectively, and make better decisions. This is crucial for
                problem-solving, strategic planning, and navigating complex
                situations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Empathy</Accordion.Header>
              <Accordion.Body>
                Sleep deprivation can impair emotional intelligence and make it
                harder to connect with others. Conversely, good sleep promotes
                emotional sensitivity and the ability to understand and share
                the feelings of others. This is crucial for effective
                leadership, as it allows leaders to build trust, collaborate
                effectively, and motivate their teams.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="accordion_header">
                Clearer Judgment
              </Accordion.Header>
              <Accordion.Body>
                Sleep deprivation impairs decision-making abilities, leading to
                impulsive choices and errors in judgement. Conversely, adequate
                sleep allows for critical thinking, risk assessment, and
                weighing of options before making informed decisions. This is
                essential for effective leadership, particularly when dealing
                with sensitive issues or making important calls.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="accordion_header">
                Boosted Creativity
              </Accordion.Header>
              <Accordion.Body>
                Sleep plays a crucial role in the brain's creative processes.
                During sleep, the brain consolidates memories and makes new
                connections, fostering innovative thinking and problem-solving
                approaches. Leaders who prioritize sleep are more likely to come
                up with fresh ideas, inspire their teams, and drive innovation
                within the organization.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="accordion_header">
                Informed Risk Assessment
              </Accordion.Header>
              <Accordion.Body>
                Sleep deprivation can lead to excessive risk-taking behavior due
                to impaired judgment. However, good sleep allows for a more
                balanced assessment of risks and rewards. Leaders who prioritize
                sleep are better equipped to make calculated risks, avoid
                unnecessary pitfalls, and ensure the success of their endeavors.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <img src={Frame1} className="d-none d-md-block" />
          <img src={Frame1} className="d-block d-sm-none work_acc_img" />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="d-none d-md-block">
        <div className="main_top_bg">
          <Container className="pt-md-5">
            <div className="workHeading">
              Restful Nights, Productive Days: Unleashing the Power of Sleep for
              a Thriving Workplace!
            </div>
            <div className="workingPara p-1">
              Discover the critical role of sleep in employee productivity.
              Insufficient rest leads to reduced work output, increased
              absenteeism, and elevated accident risks. Cognitive impairment
              affects decision-making and communication, influencing overall job
              quality. Chronic sleep deprivation also links to health issues,
              escalating employer healthcare costs. Key findings include:
            </div>
          </Container>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center">
            {WorkCardData.map((data: any) => {
              return (
                <div key={data.id} className="col-md-4">
                  <WorkCard
                    Image={data.Image}
                    heading={data.heading}
                    content={data.content}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            background:
              " linear-gradient(0deg, #D7F2FF 0%, rgba(215, 242, 255, 0.00) 100%)",
          }}
        >
          <div className="container py-md-5">
            <div className="workLeader mt-md-3 ">
              Your leadership team is expected to make quality decisions daily.
              Optimal sleep acts as a catalyst for intelligent choices, as
              well-rested individuals exhibit:
            </div>
            <AccordionComponent />
          </div>
        </div>
        <div className="container">
          <div className=" bg-white essenceCard p-md-3 rounded-1 mt-md-4">
            <p className="essence conatiner">
              <img src={Error} alt="err" /> In essence, proper sleep is
              foundational for effective decision-making, ensuring mental
              clarity, emotional stability, and peak cognitive performance in
              leadership roles.
            </p>
          </div>
        </div>

        {/* <div className=" ">
          <div className=" bg-white essenceCard p-md-3 rounded-1 mt-md-4">
            <p className="essence conatiner">
              In essence, proper sleep is foundational for effective
              decision-making, ensuring mental clarity, emotional stability, and
              peak cognitive performance in leadership roles.
            </p>
          </div>
        </div> */}
      </div>
      <div className="d-block d-md-none">
        <div className="main_top_bg">
          <Container fluid className="pt-md-5">
            <div className="workHeading">
              Restful Nights, Productive Days: Unleashing the Power of Sleep for
              a Thriving Workplace!
            </div>
            <div className="workingPara p-1">
              Discover the critical role of sleep in employee productivity.
              Insufficient rest leads to reduced work output, increased
              absenteeism, and elevated accident risks. Cognitive impairment
              affects decision-making and communication, influencing overall job
              quality. Chronic sleep deprivation also links to health issues,
              escalating employer healthcare costs. Key findings include:
            </div>
          </Container>
        </div>
        <div className="container-fluid">
          <Carousel>
            {WorkCardData.map((data: any) => {
              return (
                <Carousel.Item className="carousel_item" key={data.id}>
                  <div className="text-center">
                    <img src={data.Image} width="140px" height="140px" />
                  </div>
                  <Carousel.Caption>
                    <h5 className="ff-Sora fw-600 fs-24">{data.heading}</h5>
                    <p className="text-black ff-inter fw-400 fs-18">
                      {data.content}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div
          style={{
            background:
              " linear-gradient(0deg, #D7F2FF 0%, rgba(215, 242, 255, 0.00) 100%)",
          }}
        >
          <div className="container-fluid">
            <div className="workLeader mt-md-3">
              Your leadership team is expected to make quality decisions daily.
              Optimal sleep acts as a catalyst for intelligent choices, as
              well-rested individuals exhibit:
            </div>
            <AccordionComponent />
          </div>
        </div>
        <div className="container-fluid">
          <div className=" bg-white essenceCard p-3 rounded-1 mt-4">
            <p className="essence conatiner">
              <img src={Error} alt="err" /> In essence, proper sleep is
              foundational for effective decision-making, ensuring mental
              clarity, emotional stability, and peak cognitive performance in
              leadership roles.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkPageComponent;
