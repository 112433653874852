import React, { useRef, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Card, Container } from "react-bootstrap";
import Nydra_well_logo from "../assets/images/nydraWell.png";
import Nydra_well_logo_mobile from "../assets/images/Nydra_Well_logo_Mobile.svg";
import { PasswordField, TextField } from "../utils/Fromcontrol";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";
import { toast } from "react-toastify";
const ResetPassword = () => {
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const toastId = useRef<any>(null);
  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const { id } = useParams();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
  });
  const submitHandler = (values: any) => {
    toastId.current = toast.loading("Loading...");
    axios
      .post(
        "/resetPassword",
        {
          token: id,
          password: values.password,
          password_confirmation: values.confirmPassword,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status) {
          toast.update(toastId.current, {
            render: "Password Has been Updated",
            type: "success",
            isLoading: false,
          });
        } else {
          toast.update(toastId.current, {
            render: "Invalid token",
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(toastId.current, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
        });
      });
  };
  return (
    <div>
      <div>
        <Navbar
          fixed="top"
          variant="white"
          bg="white"
          className="d-flex justify-content-between border-bottom shadow-sm"
          style={{ height: "76px" }}
        >
          <Container fluid className="px-md-5">
            <Navbar.Brand
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <img
                src={Nydra_well_logo}
                alt="Nydra_well_logo"
                width="50%"
                className="d-none d-md-block"
              />
              <img
                src={Nydra_well_logo_mobile}
                alt="Nydra_well_logo"
                className="d-block d-md-none"
              />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </div>
      <div className="d-flex align-items-center justify-content-center desktop_header_height">
        <Card style={{ width: "35rem" }}>
          <Card.Body className="px-md-4">
            <Card.Title className="my-md-4">Reset Password</Card.Title>
            <Card.Text>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                <Form>
                  <div>
                    <TextField
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="mobile"
                    />
                  </div>
                  <div className="my-md-4">
                    <PasswordField
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirm Password"
                      placeholder="confirm Password"
                      showPassword={showConfirmPassword}
                      togglePasswordVisibility={showConfirmPasswordHandler}
                    />
                  </div>

                  <div className="d-flex justify-content-center mb-md-4">
                    <button
                      type="submit"
                      className="btn btn-purple rounded-1 px-3 py-1 w-50"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </Formik>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
