import React from "react";
import UseNetwork from "../utils/useNetwork";
import Card from "react-bootstrap/Card";
import AuthContext from "../context/authContext1";
import net from "../assets/images/No_interNet.svg";

const OfflinePage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="position-relative">
        <Card
          style={{ width: "45rem" }}
          className="position-absolute top-50 start-50 translate-middle p-md-5 "
        >
          <Card.Body>
            <Card.Text>
              <div className="text-center mt-md-4">
                <img src={net} alt="internet_logo" />
              </div>
              <h6 className="text-purple text-center">
                no internet connection found. check your connection or try again
              </h6>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default OfflinePage;
