import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { Col, Row } from "react-bootstrap";

const ErrorToast = ({ content, type, heading }: any) => {
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="bg-dark position-relative"
    >
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
        <Toast className={` bg-${type === "success" ? "success" : "danger"} `}>
          <Toast.Body
            style={{
              fontWeight: 400,
              background: type === "success" ? "#d4edda" : "#f8d7da",
              padding: "18px",
            }}
          >
            <Row>
              <Col>
                <Row>
                  <Col className="col-12">{content}</Col>
                </Row>
              </Col>
            </Row>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default ErrorToast;
